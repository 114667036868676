import React, { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Form,
  Input,
  FormInstance,
  PageHeader,
  Row,
  Spin,
  Empty,
  Image,
  Select,
  Checkbox,
  InputNumber,
  Typography,
  TimePicker,
  DatePicker,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import { StoreProps } from '../../../store/store/types';
import { useAppSelector } from '../../../store/hooks';

import FallbackImg from '../../../assets/images/fallback-img.png';
import { CashbackFormItem } from './addStore';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

interface StoreParamsProps {
  storeid: string;
}

export const StoreView: React.FC = () => {
  const { stores, storeLoading } = useAppSelector((state) => state.store);
  const [store, setStore] = useState<StoreProps | undefined>();
  const [loading, setLoading] = useState(true);
  const formRef = React.createRef<FormInstance>();
  const history = useHistory();
  const params: StoreParamsProps = useParams();

  const getStoreitem = () => {
    if (params.storeid) {
      const item = stores.find((i) => i.storeid === params.storeid);
      setStore(item);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  useEffect(getStoreitem, [stores, params.storeid]);

  useEffect(() => {
    if (store && !loading && !storeLoading && formRef.current) {
      const timeRule = store.rules.time
        ? store.rules.time.map((t) => ({
            range: [moment(t.from, 'HH:mm'), moment(t.to, 'HH:mm')],
            cashback: 10,
          }))
        : [];

      const dateRules = store.rules.dates?.range
        ? store.rules.dates?.range.map((d) => ({
            range: [moment(d.from), moment(d.to)],
            cashback: 10,
          }))
        : [];

      formRef.current.setFieldsValue({
        name: store?.name,
        email: store?.email,
        description: store?.description,
        file: store?.image,
        category: store.category.map((i) => i.name),
        location: [store.location],
        website: store.website,
        isFeatured: store.isFeatured,
        rules: {
          cashback: store.rules.cashback,
        },
        priceRules: store.rules.price || [],
        timeRules: timeRule,
        dateRules: dateRules,
        weekRules: store.rules.dates?.week ? store.rules.dates.week : [],
      });
    }
  }, [store, formRef, storeLoading, loading]);
  return (
    <Row className="store-wrapper">
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={store?.name || ''}
        style={{ padding: 0 }}
      />
      <Col
        span={24}
        style={{
          margin: '24px 0px',
          width: '100%',
        }}
      >
        <Card bordered={false}>
          {storeLoading || loading ? (
            <Col className="category-spin" span={24}>
              <Spin />
            </Col>
          ) : !loading && store ? (
            <Form
              ref={formRef}
              name="basic"
              layout="vertical"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              autoComplete="off"
              size="middle"
            >
              <Form.Item name="file" label="Icon">
                <div style={{ padding: 10 }}>
                  <Image
                    width={70}
                    height={70}
                    src={store.image.link}
                    fallback={FallbackImg}
                  />
                </div>
              </Form.Item>

              <Form.Item label="Email" name="email">
                <Input disabled />
              </Form.Item>

              <Form.Item label="Store Name" name="name">
                <Input disabled />
              </Form.Item>

              <Form.Item label="Description" name="description">
                <Input.TextArea disabled rows={2} />
              </Form.Item>

              <Form.Item label="Category" name="category">
                <Select disabled mode="multiple"></Select>
              </Form.Item>
              <Form.Item label="Locaton" name="location">
                <Select disabled />
              </Form.Item>

              <Form.Item label="Website" name="website">
                <Input disabled />
              </Form.Item>

              <Form.Item
                label="Featured"
                name="isFeatured"
                valuePropName="checked"
              >
                <Checkbox disabled>Is featured Store?</Checkbox>
              </Form.Item>

              <Form.Item label="Cashback" name={['rules', 'cashback']}>
                <InputNumber disabled addonAfter="%" min={1} max={100} />
              </Form.Item>

              {/* {!isEmpty(store.rules.price) ? (
                <>
                  <Typography.Text strong>Minimum Price</Typography.Text>
                  <Form.List name="priceRules">
                    {(fields, { add, remove }) => (
                      <div className="store-price-form-item">
                        {fields.map(({ key, name, ...restField }) => (
                          <Row
                            key={key}
                            gutter={8}
                            className="price-form-item-wrapper"
                            style={{}}
                          >
                            <Col span={5}>
                              <Form.Item
                                {...restField}
                                name={[name, 'min']}
                                wrapperCol={{ span: 24 }}
                              >
                                <InputNumber
                                  addonAfter="€"
                                  min={1}
                                  placeholder="Min Price"
                                  disabled
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <CashbackFormItem
                                name={name}
                                restField={restField}
                                disabled
                              />
                            </Col>
                          </Row>
                        ))}
                      </div>
                    )}
                  </Form.List>
                </>
              ) : null}

              {!isEmpty(store.rules.time) ? (
                <>
                  <Typography.Text strong>Time Range</Typography.Text>
                  <Form.List name="timeRules">
                    {(fields, { add, remove }) => (
                      <div className="store-price-form-item">
                        {fields.map(({ key, name, ...restField }) => (
                          <Row
                            key={key}
                            gutter={8}
                            className="price-form-item-wrapper"
                            style={{}}
                          >
                            <Col span={5}>
                              <Form.Item
                                {...restField}
                                name={[name, 'range']}
                                wrapperCol={{ span: 24 }}
                              >
                                <TimePicker.RangePicker
                                  disabled
                                  style={{ width: '100%' }}
                                  format={'HH:mm'}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <CashbackFormItem
                                name={name}
                                restField={restField}
                                disabled
                              />
                            </Col>
                          </Row>
                        ))}
                      </div>
                    )}
                  </Form.List>
                </>
              ) : null}

              {!isEmpty(store.rules.dates?.range) && (
                <>
                  <Typography.Text strong>Date</Typography.Text>
                  <Form.List name="dateRules">
                    {(fields, { add, remove }) => (
                      <div className="store-price-form-item">
                        {fields.map(({ key, name, ...restField }) => (
                          <Row
                            key={key}
                            gutter={8}
                            className="price-form-item-wrapper"
                          >
                            <Col span={8}>
                              <Form.Item
                                {...restField}
                                name={[name, 'range']}
                                wrapperCol={{ span: 24 }}
                              >
                                <DatePicker.RangePicker
                                  disabled
                                  style={{ width: '100%' }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <CashbackFormItem
                                name={name}
                                restField={restField}
                                disabled
                              />
                            </Col>
                          </Row>
                        ))}
                      </div>
                    )}
                  </Form.List>
                </>
              )}

              {!isEmpty(store.rules.dates?.week) && (
                <>
                  <Typography.Text strong>Week</Typography.Text>
                  <Form.List name="weekRules">
                    {(fields, { add, remove }) => (
                      <div className="store-price-form-item">
                        {fields.map(({ key, name, ...restField }) => (
                          <Row
                            key={key}
                            gutter={8}
                            className="price-form-item-wrapper"
                            style={{}}
                          >
                            <Col span={8}>
                              <Form.Item
                                {...restField}
                                name={[name, 'day']}
                                wrapperCol={{ span: 24 }}
                              >
                                <Select disabled mode="multiple" />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <CashbackFormItem
                                name={name}
                                restField={restField}
                                disabled
                              />
                            </Col>
                          </Row>
                        ))}
                      </div>
                    )}
                  </Form.List>
                </>
              )} */}
            </Form>
          ) : (
            <Empty />
          )}
        </Card>
      </Col>
    </Row>
  );
};
