import React, { useEffect, useState } from 'react';
import {
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  Image,
  Input,
  PageHeader,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  Checkbox,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { useAppSelector } from '../../../store/hooks';
import { UserProps } from '../../../store/user/types';
import IBAN from '../../../assets/images/IBAN.png';
import REVOLUT from '../../../assets/images/REVOLUT.png';
import PAYPAL from '../../../assets/images/PAYPAL.png';
import BOV from '../../../assets/images/BOV.png';

interface UserParamsProps {
  userid: string;
}
type PaymentMethod = 'Revolut' | 'PayPal' | 'Bank' | 'BOV';

interface Payment {
  payment: {
    method: PaymentMethod;
    phone?: string;
    username?: string;
    IBAN?: string;
  };
}

export const RenderPaymentMethod = ({ payment }: Payment) => {
  const [image, setImage] = useState<string>();
  const [inputs, setInputs] = useState<Array<string | undefined>>([]);

  useEffect(() => {
    switch (payment.method) {
      case 'BOV': {
        setImage(BOV);
        if (payment.phone) {
          inputs.push(payment.phone);
        }
        break;
      }
      case 'Bank': {
        setImage(IBAN);
        if (payment.IBAN) {
          inputs.push(payment.IBAN);
        }
        break;
      }
      case 'PayPal': {
        setImage(PAYPAL);
        if (payment.username || payment.phone) {
          inputs.push(payment?.username, payment.phone);
        }
        break;
      }
      case 'Revolut': {
        setImage(REVOLUT);
        if (payment.phone) {
          inputs.push(payment.phone);
        }
        break;
      }
      default:
    }
  }, [payment]);
  return image ? (
    <Space direction="vertical" className="payment-method-wrapper">
      <Typography.Text>Receive Monie </Typography.Text>
      <Image src={image} preview={false} />
      <Input.Group size="default">
        <Row gutter={8}>
          {inputs.map((item, i) => (
            <Col span={inputs.length > 1 ? 12 : 24} key={i}>
              <Input disabled value={item} />
            </Col>
          ))}
        </Row>
      </Input.Group>
    </Space>
  ) : null;
};

export const UserView: React.FC = () => {
  const { users } = useAppSelector((state) => state.user);
  const { categories } = useAppSelector((state) => state.category);
  const [user, setUser] = useState<UserProps | undefined>();
  const [loading, setLoading] = useState(true);
  const params: UserParamsProps = useParams();

  const history = useHistory();

  useEffect(() => {
    if (params.userid && users?.length) {
      const item = users.find((i) => i.id === params.userid);

      if (item === undefined) {
        setLoading(false);
        return;
      }

      setUser({ ...item, dob: moment(item.dob) });
    }
  }, [users, params.userid]);

  useEffect(() => {
    if (user && loading) {
      setLoading(false);
    }
  }, [user, loading]);
  return (
    <Row className="store-wrapper">
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={user?.name || ''}
        style={{ padding: 0 }}
      />
      <Col
        span={24}
        style={{
          margin: '24px 0px',
          width: '100%',
        }}
      >
        <Card bordered={false}>
          {loading ? (
            <Col className="category-spin" span={24}>
              <Spin />
            </Col>
          ) : !loading && users && user ? (
            <Form
              name="basic"
              layout="vertical"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              initialValues={user}
              autoComplete="off"
              size="middle"
            >
              <Form.Item label="Name" name="name">
                <Input disabled />
              </Form.Item>

              <Form.Item label="Email" name="email">
                <Input disabled />
              </Form.Item>

              <Form.Item label="Date of Birth" name="dob">
                <DatePicker disabled />
              </Form.Item>

              <Form.Item label="Address" name="address">
                <Input disabled />
              </Form.Item>

              <Form.Item label="Nationality" name="nationality">
                <Input disabled />
              </Form.Item>

              <Form.Item label="Interests" name="interests">
                <Select mode="multiple" disabled>
                  {categories.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="promotionalSubscription">
                <Checkbox
                  checked={user.promotionalSubscription}
                  onChange={() => {}}
                >
                  <div className="tickbox-text-profile">
                    Tick here if you would like to receive payout email and
                    other promotional material.
                  </div>
                </Checkbox>
              </Form.Item>
              <RenderPaymentMethod payment={user.payment} />
            </Form>
          ) : (
            <Empty />
          )}
        </Card>
      </Col>
    </Row>
  );
};
