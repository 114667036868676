import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';

import {
  setBlogs,
  setBlogError,
  setBlogLoading,
  onGetBlogs,
  onAddBlogs,
  onEditBlogs,
  onDeleteBlogs,
} from './blogSlice';

import { get, post, patch, deleted } from '../../constants/api';
import { getToken } from '../auth/saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { blogState, editBlogState, DeleteProps } from './types';

function* getBlogs(): SagaIterator<void> {
  try {
    yield put(
      setBlogLoading({
        blogsLoading: true,
        blogsMessage: '',
      })
    );

    const token = yield call(getToken);
    const { data, status }: AxiosResponse = yield call(get, `blogs`, token);
    if (status === 200) {
      yield put(setBlogs({ blogs: data.data }));
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setBlogError({
          blogsHasError: true,
          blogsErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setBlogLoading({
        blogsLoading: false,
        blogsMessage: '',
      })
    );

    yield put(
      setBlogError({
        blogsHasError: false,
        blogsErrorMessage: ``,
      })
    );
  }
}

export function* addBlogs({
  payload,
}: PayloadAction<blogState>): SagaIterator<void> {
  try {
    yield put(
      setBlogLoading({
        blogsLoading: true,
        blogsMessage: '',
      })
    );
    const form = new FormData();
    form.append('title', payload.title);
    form.append('description', payload.description);
    form.append('date', payload.date);
    form.append('image', payload.file[0].originFileObj);

    const token = yield call(getToken);
    const { status }: AxiosResponse = yield call(
      post,
      `blogs`,
      form,
      token,
      true
    );
    if (status === 200) {
      yield put(onGetBlogs());
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setBlogError({
          blogsHasError: true,
          blogsErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setBlogLoading({
        blogsLoading: false,
        blogsMessage: '',
      })
    );
    yield put(
      setBlogError({
        blogsHasError: false,
        blogsErrorMessage: ``,
      })
    );
  }
}

export function* editBlogs({
  payload,
}: PayloadAction<editBlogState>): SagaIterator<void> {
  try {
    yield put(
      setBlogLoading({
        blogsLoading: true,
        blogsMessage: '',
      })
    );
    const token = yield call(getToken);
    const { status }: AxiosResponse = yield call(
      patch,
      `blogs/${payload.id}`,
      payload.form,
      token,
      true
    );
    if (status === 200) {
      yield put(onGetBlogs());
      notification['success']({
        message: 'Blogs updated successfully',
        description: '',
      });
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setBlogError({
          blogsHasError: true,
          blogsErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setBlogLoading({
        blogsLoading: false,
        blogsMessage: '',
      })
    );
    yield put(
      setBlogError({
        blogsHasError: false,
        blogsErrorMessage: ``,
      })
    );
  }
}

export function* deleteBlogs({
  payload,
}: PayloadAction<DeleteProps>): SagaIterator<void> {
  try {
    yield put(
      setBlogLoading({
        blogsLoading: true,
        blogsMessage: '',
      })
    );
    const token = yield call(getToken);
    const { status }: AxiosResponse = yield call(
      deleted,
      `blogs/${payload.id}`,
      undefined,
      token
    );

    if (status === 200) {
      yield put(
        setBlogLoading({
          blogsLoading: false,
          blogsMessage: 'Blogs deleted successfully',
        })
      );
      yield put(onGetBlogs());
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setBlogError({
          blogsHasError: true,
          blogsErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setBlogLoading({
        blogsLoading: false,
        blogsMessage: '',
      })
    );

    yield put(
      setBlogError({
        blogsHasError: false,
        blogsErrorMessage: ``,
      })
    );
  }
}

export function* blogsSaga(): SagaIterator<void> {
  yield all([takeLatest(onGetBlogs, getBlogs)]);
  yield all([takeLatest(onAddBlogs, addBlogs)]);
  yield all([takeLatest(onEditBlogs, editBlogs)]);
  yield all([takeLatest(onDeleteBlogs, deleteBlogs)]);
}
