import React, { useState } from 'react';
import { Layout, Menu, Button, Dropdown, Avatar, Image } from 'antd';
import {
  MenuUnfoldOutlined,
  LogoutOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { onLogout } from '../../store/auth/authSlice';
import { colors } from '../../constants/theme';
import logo from '../../assets/images/monie_logo.png';

const { Header } = Layout;

interface HeaderProps {
  collapsed: boolean;
  setCollapsed: any;
}

export const HeaderComponent: React.FC<HeaderProps> = ({
  collapsed,
  setCollapsed,
}) => {
  const { user, auth } = useAppSelector((state) => state.auth);
  const [visible, setVisble] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleVisibleChange = (flag: boolean) => {
    setVisble(flag);
  };

  const logoClass = classNames({
    'menu-collapsed-logo': collapsed,
    'menu-logo': !collapsed,
  });

  const onPressLogout = () => {
    dispatch(onLogout(history));
  };

  const useritem = (
    <Menu>
      <Menu.Item onClick={onPressLogout} key="1" icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="navbar-header" style={{ padding: 0 }}>
      <div className="navbar-header-wrapper">
        <div className={logoClass}>
          <Link to={'/'}>
            <Image src={logo} width={20} preview={false} />
          </Link>
        </div>
        <Button
          type="text"
          icon={
            <MenuUnfoldOutlined
              style={{ color: '#FFFFFF' }}
              onClick={() => setCollapsed(!collapsed)}
            />
          }
        />
        <div className="navbar-header-spacer" />

        {auth && (
          <div className="navbar-header-dropdown">
            <Dropdown
              overlay={useritem}
              onVisibleChange={handleVisibleChange}
              visible={visible}
              placement="bottomRight"
              overlayStyle={{ width: 150 }}
            >
              <div style={{ height: 64 }}>
                <Button type="text" style={{ background: colors.yellow }}>
                  {user.email} <DownOutlined />
                </Button>
              </div>
            </Dropdown>
          </div>
        )}
      </div>
    </Header>
  );
};
