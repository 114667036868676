import { PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from '..';
import { get, patch } from '../../constants/api';
import { getToken } from '../auth/saga';
import {
  onGetSeo,
  onUpdateSeoContent,
  setSeo,
  setSeoError,
  setSeoLoading,
} from './seoSlice';
import { SeoProps } from './types';

function* getSeo(): SagaIterator<void> {
  const seoState = (state: RootState) => state.seo;
  const { seoHasError } = yield select(seoState);
  try {
    yield put(
      setSeoLoading({
        seoLoading: true,
        seoMessage: '',
      })
    );

    const { data, status }: AxiosResponse = yield call(get, `meta`, null);
    if (status === 200) {
      yield put(setSeo({ seo: data.data }));
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setSeoError({
          seoHasError: true,
          seoErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setSeoLoading({
        seoLoading: false,
        seoMessage: '',
      })
    );
    if (seoHasError) {
      yield put(
        setSeoError({
          seoHasError: false,
          seoErrorMessage: '',
        })
      );
    }
  }
}

function* updateSeo({ payload }: PayloadAction<SeoProps>): SagaIterator<void> {
  const seoState = (state: RootState) => state.seo;
  const { seoHasError } = yield select(seoState);
  try {
    yield put(
      setSeoLoading({
        seoLoading: true,
        seoMessage: '',
      })
    );

    const form = new FormData();
    form.append('title', payload.title);
    form.append('description', payload.description);
    form.append('image', payload.image);
    form.append('keywords', payload.keywords);

    if (Array.isArray(payload.icon)) {
      form.append('icon', payload.icon[0].originFileObj);
    }

    const token = yield call(getToken);
    const { status }: AxiosResponse = yield call(
      patch,
      `meta/${payload.id}`,
      form,
      token,
      true
    );

    if (status === 200) {
      yield put(onGetSeo());
      notification['success']({
        message: 'Contents updated successfully',
      });
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setSeoError({
          seoHasError: true,
          seoErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setSeoLoading({
        seoLoading: false,
        seoMessage: '',
      })
    );
    if (seoHasError) {
      yield put(
        setSeoError({
          seoHasError: false,
          seoErrorMessage: '',
        })
      );
    }
  }
}

export function* seoSaga(): SagaIterator<void> {
  yield all([takeLatest(onGetSeo, getSeo)]);
  yield all([takeLatest(onUpdateSeoContent, updateSeo)]);
}
