import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export interface FirebaseConfig {
  apiKey: string;
  authDomain?: string;
  // databaseUrl?: string;
  projectId?: string;
  storageBucket?: string;
  messagingSenderId?: string;
  appId?: string;
  measurementId?: string;
}

const monieFirebaseConfig: FirebaseConfig = {
  apiKey: 'AIzaSyCtSitSE9jIrUeJW_HKj5MCvrjfDGr9PP0',
  authDomain: 'monie-e7a9a.firebaseapp.com',
  projectId: 'monie-e7a9a',
  storageBucket: 'monie-e7a9a.appspot.com',
  messagingSenderId: '290811953554',
  appId: '1:290811953554:web:8b1521753cf0bf6272814b',
  measurementId: 'G-XT04F42375',
};

const monieStagingConfig: FirebaseConfig = {
  apiKey: 'AIzaSyCA5VaC1fMvFFXrBxNjS4RMsEk0VAtIMfY',
  authDomain: 'monie-stage.firebaseapp.com',
  projectId: 'monie-stage',
  storageBucket: 'monie-stage.appspot.com',
  messagingSenderId: '1044939402657',
  appId: '1:1044939402657:web:9c43789ef2c46df20b6fe0',
  measurementId: 'G-XZP478D68Y',
};

const CF_URL_PROD = {
  url: 'https://us-central1-monie-e7a9a.cloudfunctions.net/api/',
};

const CF_URL_STAGING = {
  url: 'https://us-central1-monie-stage.cloudfunctions.net/api/',
};

const BASE_URL = { CF_URL_PROD, CF_URL_STAGING };

export const REST_URL = BASE_URL.CF_URL_PROD; // CHANGE PROD OR STAGING

export const MonieFirebase = firebase.initializeApp(monieFirebaseConfig); // CHANGE PROD OR STAGING

export const firestore = MonieFirebase.firestore();
