import React, { useEffect, useRef, useState } from 'react';
import {
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  PageHeader,
  Row,
  Empty,
  Spin,
  Image,
  Typography,
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import { routes } from '../../../constants/routes';
import { TeamState } from '../../../store/ourstory/types';
import FallbackImg from '../../../assets/images/fallback-img.png';
import { Location } from 'history';
import has from 'lodash/has';

interface LocationStateParams {
  key: number;
}

export const ViewTeam: React.FC = () => {
  const { ourstory } = useAppSelector((state) => state.story);
  const [team, setTeam] = useState<TeamState | undefined>();
  const [loading, setLoading] = useState(true);
  const formRef = React.createRef<FormInstance>();
  const history = useHistory();
  const location: Location<LocationStateParams> = useLocation();

  const getTeamDetail = () => {
    const locationState = location.state;

    if (!has(locationState, 'key')) {
      history.replace(routes.ourStory);
    }
    if (locationState && has(locationState, 'key') && ourstory !== null) {
      const item = ourstory?.team.find((i, ind) => ind === locationState.key);

      if (item === undefined) {
        setLoading(false);
        return;
      }
      setTeam(item);
    }
  };

  useEffect(getTeamDetail, [location.state, ourstory]);

  useEffect(() => {
    if (team && loading) {
      setLoading(false);
    }
  }, [team, loading]);
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.replace(routes.ourStory)}
        title={team?.name}
        style={{ padding: 0 }}
      />
      <Row>
        <Col
          span={24}
          style={{
            margin: '24px 0px',
            width: '100%',
          }}
        >
          <Card bordered={false}>
            {loading ? (
              <Col className="category-spin" span={24}>
                <Spin />
              </Col>
            ) : !loading && ourstory && team ? (
              <Form
                ref={formRef}
                name="basic"
                labelCol={{ flex: '110px' }}
                labelAlign="left"
                labelWrap
                wrapperCol={{ span: 12 }}
                autoComplete="off"
                size="middle"
                initialValues={team}
              >
                <Form.Item label="Name" name="name">
                  <Input disabled />
                </Form.Item>

                <Form.Item label="Description" name="description">
                  <Input.TextArea disabled rows={10} />
                </Form.Item>

                <Form.Item label="Quote" name="quote">
                  <Input disabled />
                </Form.Item>

                <Form.Item name="image" label="Image">
                  <div style={{ padding: 10 }}>
                    <Image
                      height={150}
                      src={team.image.link}
                      fallback={FallbackImg}
                    />
                  </div>
                </Form.Item>

                <Form.Item name="overlay" label="Overlay">
                  {team.overlay?.link ? (
                    <div style={{ padding: 10 }}>
                      <Image
                        height={150}
                        src={team.overlay?.link}
                        fallback={FallbackImg}
                      />
                    </div>
                  ) : (
                    <Typography.Text strong>
                      No overlay image found
                    </Typography.Text>
                  )}
                </Form.Item>
              </Form>
            ) : (
              <Empty />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};
