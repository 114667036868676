import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  EditFormState,
  StoreVisibility,
} from '../../component/store/components/types';
import { LoadingState, ErrorState, StoreState, addStoreState } from './types';

const initialState = {
  stores: [],
  storeLoading: false,
  storeMessage: '',
  storeHasError: false,
  storeErrorMessage: '',
} as StoreState;

export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    onGetStores: () => {},
    onAddStore: (state, action: PayloadAction<addStoreState>) => {},
    onDeleteStore: (state, action: PayloadAction<{ id: string }>) => {},
    onEditStore: (state, action: PayloadAction<EditFormState>) => {},
    onUpdateStoreVisibility: (
      state,
      action: PayloadAction<StoreVisibility>
    ) => {},
    setStore: (state, action: PayloadAction<StoreState>) => {
      state.stores = action.payload.stores;
    },
    setStoreLoading: (state, action: PayloadAction<LoadingState>) => {
      state.storeLoading = action.payload.storeLoading;
      state.storeMessage = action.payload.storeMessage;
    },
    setStoreError: (state, action: PayloadAction<ErrorState>) => {
      state.storeHasError = action.payload.storeHasError;
      state.storeErrorMessage = action.payload.storeErrorMessage;
    },
  },
});

export const {
  onGetStores,
  onAddStore,
  onDeleteStore,
  setStore,
  setStoreLoading,
  setStoreError,
  onEditStore,
  onUpdateStoreVisibility,
} = storeSlice.actions;

export default storeSlice.reducer;
