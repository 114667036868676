import React, { useEffect, useState } from 'react';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Col, Row, Space, Table } from 'antd';
import { DeleteTwoTone, EditTwoTone, PlusOutlined } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { routes } from '../../constants/routes';
import { onGetAdmins, onGetUsers } from '../../store/user/userSlice';
import { AdminProps, UserProps } from '../../store/user/types';
import { colors } from '../../constants/theme';
import { onGetCategories } from '../../store/category/categorySlice';
import { DeleteUserModal } from '../users/components/deleteUser';
import { AdminView } from './components/viewAdmins';
import { AddAdmin } from './components/addAdmin';
import { EditAdmin } from './components/editAdmin';

export const AdminLists: React.FC = () => {
  const { admins, usersLoading } = useAppSelector((state) => state.user);
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    id: '',
    name: '',
  });

  const handleAddBtn = () => {
    history.push(routes.addAdmin);
  };

  const handleRowItemClick = (item: AdminProps) => ({
    onClick: () => history.push(`${routes.viewAdmin}/${item.id}`),
  });

  const handleDeleteButton = (key: AdminProps) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDeleteModal(true);
    setSelectedUser({ id: key.id, name: key.name });
  };

  const handleEditButton = (key: any) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`${routes.editAdmin}/${key.userid}`);
  };

  useEffect(() => {
    if (!showDeleteModal) {
      setSelectedUser({ id: '', name: '' });
    }
  }, [showDeleteModal]);
  return (
    <Row>
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <Col span={24} style={{ float: 'right' }}>
          <Button type="primary" icon={<PlusOutlined />} onClick={handleAddBtn}>
            ADD ADMIN
          </Button>
        </Col>
        <Col span={24}>
          <Table
            rowKey={'userid'}
            loading={usersLoading}
            dataSource={admins}
            pagination={{
              pageSize: 10,
              total: admins ? admins.length : 0,
              hideOnSinglePage: true,
            }}
            scroll={{ x: 'max-content' }}
            onRow={handleRowItemClick}
          >
            <Table.Column
              title="ID"
              dataIndex="id"
              width={'20%'}
              align="left"
              sorter={(a: UserProps, b: UserProps) =>
                a.id.toLowerCase().localeCompare(b.id.toLowerCase())
              }
            />
            <Table.Column
              title="Name"
              dataIndex="name"
              align="left"
              sorter={(a: UserProps, b: UserProps) =>
                a.name.toLowerCase().localeCompare(b.name.toLowerCase())
              }
            />

            <Table.Column
              title="Email"
              dataIndex="email"
              align="left"
              sorter={(a: UserProps, b: UserProps) =>
                a.email.toLowerCase().localeCompare(b.email.toLowerCase())
              }
            />

            <Table.Column
              title="Address"
              dataIndex="address"
              align="left"
              sorter={(a: UserProps, b: UserProps) =>
                a.address.toLowerCase().localeCompare(b.address.toLowerCase())
              }
            />

            <Table.Column
              title="Nationality"
              dataIndex="nationality"
              align="left"
              sorter={(a: UserProps, b: UserProps) =>
                a.nationality
                  .toLowerCase()
                  .localeCompare(b.nationality.toLowerCase())
              }
            />

            <Table.Column
              title="Action"
              key="action"
              width={'15%'}
              align="center"
              render={(text, record: any) => {
                return (
                  <div>
                    <Button
                      type="text"
                      style={{ padding: '0px 10px' }}
                      onClick={handleEditButton(record)}
                    >
                      <EditTwoTone twoToneColor={colors.black} />
                    </Button>
                    <Button
                      type="text"
                      style={{ padding: '0px 10px' }}
                      onClick={handleDeleteButton(record)}
                    >
                      <DeleteTwoTone twoToneColor={colors.red} />
                    </Button>
                  </div>
                );
              }}
            />
          </Table>
        </Col>
      </Space>
      <DeleteUserModal
        user={selectedUser}
        visible={showDeleteModal}
        setVisible={setShowDeleteModal}
        isAdmin={true}
      />
    </Row>
  );
};

export const Admin: React.FC = () => {
  const { auth } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { url } = useRouteMatch();

  useEffect(() => {
    if (auth) {
      dispatch(onGetAdmins());
    }
  }, [dispatch, auth]);
  return (
    <div className="admin-container">
      <Route exact path={routes.admins} component={AdminLists} />
      <Route path={`${url}/view/:id`} component={AdminView} />
      <Route path={`${url}/add`} component={AddAdmin} />
      <Route path={`${url}/edit/:id`} component={EditAdmin} />
    </div>
  );
};
