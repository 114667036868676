import React, { useEffect, useRef, useState } from 'react';
import {
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  PageHeader,
  Row,
  Empty,
  Spin,
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { Location } from 'history';
import has from 'lodash/has';

import { useAppSelector } from '../../../store/hooks';
import { routes } from '../../../constants/routes';
import { HighlightsState } from '../../../store/ourstory/types';

interface LocationStateParams {
  key: number;
}

export const ViewHighlights: React.FC = () => {
  const { ourstory } = useAppSelector((state) => state.story);
  const [highlight, setHighlight] = useState<HighlightsState | undefined>();
  const [loading, setLoading] = useState(true);
  const formRef = React.createRef<FormInstance>();
  const history = useHistory();
  const location: Location<LocationStateParams> = useLocation();

  const getHighlightDetail = () => {
    const locationState = location.state;

    if (!has(locationState, 'key')) {
      history.replace(routes.ourStory);
    }
    if (locationState && has(locationState, 'key') && ourstory !== null) {
      const item = ourstory?.highlights.find(
        (i, ind) => ind === locationState.key
      );
      if (item === undefined) {
        setLoading(false);
        return;
      }
      setHighlight({
        ...item,
        note: item.note || '',
      });
    }
  };

  useEffect(getHighlightDetail, [location.state, ourstory]);

  useEffect(() => {
    if (highlight && loading) {
      setLoading(false);
    }
  }, [highlight, loading]);
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.replace(routes.ourStory)}
        title={highlight?.title}
        style={{ padding: 0 }}
      />
      <Row>
        <Col
          span={24}
          style={{
            margin: '24px 0px',
            width: '100%',
          }}
        >
          <Card bordered={false}>
            {loading ? (
              <Col className="category-spin" span={24}>
                <Spin />
              </Col>
            ) : !loading && ourstory && highlight ? (
              <Form
                ref={formRef}
                name="basic"
                layout="vertical"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
                initialValues={highlight}
                autoComplete="off"
                size="middle"
              >
                <Form.Item label="Title" name="title">
                  <Input disabled />
                </Form.Item>

                <Form.Item label="Description" name="description">
                  <Input.TextArea disabled rows={5} />
                </Form.Item>

                <Form.Item label="Note" name="note">
                  <Input disabled />
                </Form.Item>
              </Form>
            ) : (
              <Empty />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};
