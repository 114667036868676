import React, { useEffect, useRef, useState } from 'react';
import {
  PageHeader,
  Row,
  Col,
  Card,
  Spin,
  Empty,
  Form,
  FormInstance,
  Input,
  Button,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { FaqsProps } from '../../../store/faqs/types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { onEditFaqs } from '../../../store/faqs/faqsSlice';

interface FaqsParamsProps {
  faqsId: string;
}

interface faqsState {
  title: string;
  description: string;
}

const EditFaqs: React.FC = () => {
  const { faqs, faqsLoading, faqsHasError } = useAppSelector(
    (state) => state.faqs
  );
  const [faq, setFaqs] = useState<FaqsProps | undefined>();
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const history = useHistory();
  const formRef = React.createRef<FormInstance>();
  const submitPressed = useRef(false);
  const params: FaqsParamsProps = useParams();
  const dispatch = useAppDispatch();

  const onFinish = (values: faqsState) => {
    submitPressed.current = true;
    dispatch(onEditFaqs({ form: values, id: params.faqsId }));
  };
  const onFinishFailed = () => {};
  const onFormValuesChanges = (changedValues: any, allValues: faqsState) => {
    const { title, description } = allValues;
    if (title === '' || description == '') {
      setDisabled(true);
      return;
    }
    if (title === faq?.title && description === faq.description) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
  };

  const getFaqs = () => {
    if (params.faqsId) {
      const item = faqs.find((i) => i.id === params.faqsId);
      setFaqs(item);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  useEffect(getFaqs, [faqs, params.faqsId]);
  useEffect(() => {
    if (faqsHasError && !faqsLoading && submitPressed.current) {
      submitPressed.current = false;
    } else if (!faqsLoading && submitPressed.current) {
      submitPressed.current = false;
      setDisabled(true);
    }
  }, [submitPressed, faqsLoading]);
  return (
    <Row>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={faq?.title || ''}
        style={{ padding: 0 }}
      />
      <Col
        span={24}
        style={{
          margin: '24px 0px',
          width: '100%',
        }}
      >
        <Card bordered={false}>
          {loading ? (
            <Col className="category-spin" span={24}>
              <Spin />
            </Col>
          ) : !loading && faq ? (
            <Form
              ref={formRef}
              name="basic"
              layout="vertical"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={onFormValuesChanges}
              initialValues={{
                title: faq.title,
                description: faq.description,
              }}
              autoComplete="off"
              size="middle"
            >
              <Form.Item
                label="Faqs title"
                name="title"
                rules={[
                  { required: true, message: 'Please input a faqs title' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Faqs description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Please input a faqs description',
                  },
                ]}
              >
                <Input.TextArea rows={5} />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 6 }}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  disabled={disabled}
                  loading={faqsLoading && submitPressed.current}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <Empty />
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default EditFaqs;
