import React, { useEffect, useRef, useState } from 'react';
import {
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  PageHeader,
  Row,
  Empty,
  Spin,
  Upload,
  Button,
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { routes } from '../../../constants/routes';
import { TeamState } from '../../../store/ourstory/types';
import { Location } from 'history';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import { onEditStoryTeam } from '../../../store/ourstory/storySlice';

interface LocationStateParams {
  key: number;
}

export const EditTeam: React.FC = () => {
  const { ourstory, storyLoading, storyHasError } = useAppSelector(
    (state) => state.story
  );
  const [team, setTeam] = useState<TeamState | undefined>();
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [key, setKey] = useState<number | undefined>();
  const formRef = React.createRef<FormInstance>();
  const history = useHistory();
  const location: Location<LocationStateParams> = useLocation();
  const submitPressed = useRef(false);
  const dispatch = useAppDispatch();

  const onFinish = (values: TeamState) => {
    submitPressed.current = true;
    if (Array.isArray(values.overlay) && values.overlay.length === 0) {
      values.overlay = '';
    }
    dispatch(onEditStoryTeam({ ...values, key }));
  };

  const getTeamDetail = () => {
    const locationState = location.state;

    if (!has(locationState, 'key')) {
      history.replace(routes.ourStory);
    }
    if (locationState && has(locationState, 'key') && ourstory !== null) {
      const item = ourstory?.team.find((i, ind) => ind === locationState.key);
      if (item === undefined) {
        setLoading(false);
        return;
      }
      setKey(locationState.key);
      setTeam(item);
    }
  };

  const onFormValuesChanges = (
    changedValues: unknown,
    allValues: TeamState
  ) => {
    const areValuesEqual = isEqual(team, allValues);
    setDisabled(areValuesEqual);
  };

  const handleCancelFormBtn = () => {
    setDisabled(true);
    formRef.current?.resetFields();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(getTeamDetail, [location.state, ourstory]);

  useEffect(() => {
    if (team && loading) {
      setLoading(false);
    }
  }, [team, loading]);

  useEffect(() => {
    if (storyHasError && !storyLoading && submitPressed.current) {
      submitPressed.current = false;
    } else if (!storyLoading && submitPressed.current) {
      submitPressed.current = false;
      setDisabled(true);
    }
  }, [storyHasError, storyLoading, formRef, submitPressed]);
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.replace(routes.ourStory)}
        title={team?.name}
        style={{ padding: 0 }}
      />
      <Row>
        <Col
          span={24}
          style={{
            margin: '24px 0px',
            width: '100%',
          }}
        >
          <Card bordered={false}>
            {loading ? (
              <Col className="category-spin" span={24}>
                <Spin />
              </Col>
            ) : !loading && ourstory && team ? (
              <Form
                ref={formRef}
                name="basic"
                layout="vertical"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
                onFinish={onFinish}
                onValuesChange={onFormValuesChanges}
                initialValues={team}
                autoComplete="off"
                size="middle"
              >
                <Form.Item label="Name" name="name">
                  <Input />
                </Form.Item>

                <Form.Item label="Description" name="description">
                  <Input.TextArea rows={10} />
                </Form.Item>

                <Form.Item label="Quote" name="quote">
                  <Input />
                </Form.Item>

                <Form.Item
                  name="image"
                  label="Icon"
                  getValueFromEvent={normFile}
                  rules={[
                    { required: true, message: 'Please upload an image' },
                  ]}
                >
                  <Upload
                    name="image"
                    listType="picture"
                    maxCount={1}
                    defaultFileList={[
                      {
                        uid: '-1',
                        name: team.image.filename,
                        url: team.image.link,
                      },
                    ]}
                  >
                    <Button>Upload Image</Button>
                  </Upload>
                </Form.Item>

                <Form.Item
                  name="overlay"
                  label="Overlay"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    name="logo"
                    listType="picture"
                    maxCount={1}
                    defaultFileList={[
                      {
                        uid: '-1',
                        name: team.overlay?.filename || '',
                        url: team.overlay?.link || '',
                      },
                    ]}
                  >
                    <Button>Upload Overlay Image</Button>
                  </Upload>
                </Form.Item>

                <Form.Item wrapperCol={{ span: 6 }}>
                  <Row gutter={16} className="store-action-buttons">
                    <Col span={12}>
                      <Button
                        block
                        type="primary"
                        htmlType="submit"
                        disabled={disabled}
                        loading={storyLoading && submitPressed.current}
                      >
                        Submit
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button danger onClick={handleCancelFormBtn}>
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            ) : (
              <Empty />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};
