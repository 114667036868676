import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import React from 'react';
import { Headers, Data } from 'react-csv/components/CommonPropTypes';

interface ExportProps {
  data: Data;
  headers?: Headers;
  filename: string;
  block?: boolean;
}

const ExportButton: React.FC<ExportProps> = ({
  data,
  headers,
  filename,
  block,
}) => {
  return (
    <CSVLink filename={filename} data={data} headers={headers}>
      <Button
        block={block}
        type="default"
        className=""
        icon={<DownloadOutlined />}
      >
        <span>Export</span>
      </Button>
    </CSVLink>
  );
};

export default ExportButton;
