import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Modal, Button, Typography, notification } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { onDeleteCategory } from '../../../store/category/categorySlice';
import { onDeleteFaqs } from '../../../store/faqs/faqsSlice';
import { onDeleteBlogs } from '../../../store/blogs/blogSlice';

interface ModalProps {
  blog: {
    title: string;
    id: string;
  };
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

export const DeleteFaqsModal: React.FC<ModalProps> = ({
  blog,
  visible,
  setVisible,
}) => {
  const { blogsHasError, blogsErrorMessage, blogsLoading } = useAppSelector(
    (state) => state.blogs
  );
  const dispatch = useAppDispatch();
  const deletePressed = useRef(false);

  const handleOk = () => {};

  const handleCancel = () => {
    setVisible(false);
  };

  const handleBlogDeletion = () => {
    dispatch(onDeleteBlogs({ id: blog.id }));
    deletePressed.current = true;
  };

  useEffect(() => {
    if (blogsHasError && !blogsLoading && deletePressed.current) {
      notification['error']({
        message: 'Error deleting blogs',
        description: `${blogsErrorMessage}`,
      });
      setVisible(false);
    } else if (!blogsLoading && deletePressed.current) {
      notification['success']({
        message: 'Blogs deleted',
        description: '',
      });
      deletePressed.current = false;
      setVisible(false);
    }
  }, [blogsErrorMessage, blogsHasError, blogsLoading, setVisible]);
  return (
    <Modal
      visible={visible}
      centered
      title="Delete Blogs"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          loading={blogsLoading}
          danger
          onClick={handleBlogDeletion}
        >
          {blogsLoading ? 'Deleting...' : 'Delete'}
        </Button>,
      ]}
    >
      Are you sure you want to delete blog
      <Typography.Text strong> {blog.title}</Typography.Text>?
    </Modal>
  );
};
