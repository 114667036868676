import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  PageHeader,
  Row,
  notification,
  Spin,
  Table,
  Image,
  FormInstance,
  Tabs,
} from 'antd';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { onGetStory, onUpdateStory } from '../../store/ourstory/storySlice';
import { PlusOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { HighlightsState, TeamState } from '../../store/ourstory/types';
import { routes } from '../../constants/routes';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { AddHighlights } from './components/AddHighlights';
import { AddTeam } from './components/AddTeam';
import { colors } from '../../constants/theme';
import FallbackImg from '../../assets/images/fallback-img.png';
import { ViewTeam } from './components/ViewTeam';
import { EditTeam } from './components/EditTeam';
import { EditHighlight } from './components/EditHighlights';
import { ViewHighlights } from './components/ViewHighlight';
import { EditSeo } from '../seo/components/EditSeo';
import { onGetSeo } from '../../store/seo/seoSlice';

interface StoryState {
  note: string;
  description: string;
  video: string;
}

export const OurStory: React.FC = () => {
  const { ourstory, storyHasError, storyLoading, storyErrorMessage } =
    useAppSelector((state) => state.story);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [highlights, setHighlights] = useState<Array<HighlightsState>>([]);
  const [team, setTeam] = useState<Array<TeamState>>([]);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const formRef = React.createRef<FormInstance>();
  const submitPressed = useRef(false);

  const handleAddHighlights = () => {
    history.push(routes.addStoryHighlights);
  };

  const handleAddTeam = () => {
    history.push(routes.addStoryTeam);
  };

  const onFinish = (values: StoryState) => {
    submitPressed.current = true;
    const body = {
      ...values,
      highlights: highlights,
      team: team,
    };
    dispatch(
      onUpdateStory({
        form: body,
      })
    );
  };

  const onFinishFailed = (errorInfo: unknown) => {
    // console.log('Failed:', errorInfo);
  };

  const handleDeleteHighlightButton =
    (key: unknown, i: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const removedHighlights = highlights.filter((item, index) => i !== index);
      if (highlights !== removedHighlights) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
      setHighlights(removedHighlights);
    };

  const handleEditButton =
    (index: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      history.push({
        pathname: routes.editStoryTeam,
        state: { key: index },
      });
    };

  const handleHighlightEdit =
    (index: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      history.push({
        pathname: routes.editStoryHighlights,
        state: { key: index },
      });
    };

  const handleDeleteTeamButton =
    (key: unknown, i: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const removedTeam = team.filter((item, index) => i !== index);
      if (team !== removedTeam) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
      setTeam(removedTeam);
    };

  const onFormValuesChanges = (
    changedValues: unknown,
    allValues: StoryState
  ) => {
    const { note, description, video } = allValues;
    if (note === '' || description === '' || video === '') {
      setDisabled(true);
      return;
    }

    if (
      note === ourstory?.note &&
      description === ourstory.description &&
      video === ourstory.video
    ) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
  };

  const onClickCancel = () => {
    formRef.current &&
      formRef?.current.setFieldsValue({
        note: ourstory?.note,
        description: ourstory?.description,
        video: ourstory?.video,
      });
    getHighlights();
    getTeams();
    setDisabled(true);
  };

  const getHighlights = () => {
    const data: Array<HighlightsState> = [];
    ourstory?.highlights.map((val) => {
      const autoId = (Math.random() * 36).toString(36);
      const body = {
        ...val,
        id: autoId,
      };
      data.push(body);
    });
    setHighlights(data);
  };

  const getTeams = () => {
    const data: Array<TeamState> = [];
    ourstory?.team.map((val) => {
      const autoId = (Math.random() * 36).toString(36);
      const body = {
        ...val,
        id: autoId,
      };
      data.push(body);
    });
    setTeam(data);
  };

  const handleRowItemClick =
    (route: string) => (item: unknown, index: number | undefined) => ({
      onClick: () =>
        history.push({
          pathname:
            route === 'team'
              ? routes.viewStoryTeam
              : routes.viewStoryHighlights,
          state: { key: index },
        }),
    });

  useEffect(() => {
    if (loading && ourstory) {
      setLoading(false);
    }
  }, [loading, ourstory]);
  useEffect(getHighlights, [ourstory?.highlights]);
  useEffect(getTeams, [ourstory?.team]);
  useEffect(() => {
    if (storyHasError && !storyLoading && submitPressed.current) {
      notification['error']({
        message: 'Error updating story',
        description: `${storyErrorMessage}`,
      });
      submitPressed.current = false;
    } else if (!storyLoading && submitPressed.current) {
      notification['success']({
        message: 'Our story updated successfully',
        description: '',
      });
      submitPressed.current = false;
      setDisabled(true);
    }
  }, [submitPressed, storyLoading]);
  return (
    <div>
      <PageHeader
        className="site-page-header"
        // onBack={() => history.replace(routes.faqs)}
        title="Update Our Story"
        style={{ padding: 0 }}
      />
      <Row>
        <Col
          span={24}
          style={{
            margin: '24px 0px',
            width: '100%',
          }}
        >
          <Card bordered={false}>
            {loading ? (
              <Col className="category-spin" span={24}>
                <Spin />
              </Col>
            ) : (
              <Form
                ref={formRef}
                name="basic"
                layout="vertical"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onValuesChange={onFormValuesChanges}
                initialValues={{
                  note: ourstory?.note,
                  description: ourstory?.description,
                  video: ourstory?.video,
                }}
                autoComplete="off"
                size="middle"
              >
                <Form.Item label="Note" name="note">
                  <Input />
                </Form.Item>

                <Form.Item label="Description" name="description">
                  <Input.TextArea style={{ height: '200px' }} />
                </Form.Item>
                <Form.Item label="Video YT Url" name="video">
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Highlights"
                  // name="highlights"
                  wrapperCol={{ span: 24 }}
                >
                  <Col span={24} style={{ float: 'right', marginBottom: 10 }}>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={handleAddHighlights}
                    >
                      Add Highlights
                    </Button>
                  </Col>
                  <Table
                    rowKey={'id'}
                    loading={storyLoading}
                    dataSource={highlights}
                    pagination={{
                      pageSize: 10,
                      total: highlights ? highlights.length : 0,
                      hideOnSinglePage: true,
                    }}
                    scroll={{ x: 'max-content' }}
                    onRow={handleRowItemClick('higlights')}
                  >
                    <Table.Column title="Title" dataIndex="title" key="title" />
                    <Table.Column title="Note" dataIndex="note" key="note" />
                    <Table.Column
                      title="Description"
                      dataIndex="description"
                      key="description"
                      render={(text: string) => {
                        return <div className="description-style">{text}</div>;
                      }}
                    />
                    <Table.Column
                      title="Action"
                      key="action"
                      width={'15%'}
                      align="center"
                      render={(text, record: unknown, i: number) => {
                        return (
                          <div>
                            <Button
                              type="text"
                              style={{ padding: '0px 10px' }}
                              onClick={handleHighlightEdit(i)}
                            >
                              <EditTwoTone twoToneColor={colors.black} />
                            </Button>
                            <Button
                              type="text"
                              style={{ padding: '0px 10px' }}
                              onClick={handleDeleteHighlightButton(record, i)}
                            >
                              <DeleteTwoTone twoToneColor={colors.red} />
                            </Button>
                          </div>
                        );
                      }}
                    />
                  </Table>
                </Form.Item>
                <Form.Item label="Team" wrapperCol={{ span: 24 }}>
                  <Col span={24} style={{ float: 'right', marginBottom: 10 }}>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={handleAddTeam}
                    >
                      Add Team
                    </Button>
                  </Col>
                  <Table
                    rowKey={'id'}
                    loading={storyLoading}
                    dataSource={team}
                    pagination={{
                      pageSize: 10,
                      total: team ? team.length : 0,
                      hideOnSinglePage: true,
                    }}
                    scroll={{ x: 'max-content' }}
                    onRow={handleRowItemClick('team')}
                  >
                    <Table.Column title="Name" dataIndex="name" key="name" />
                    <Table.Column
                      title="Description"
                      dataIndex="description"
                      key="description"
                      render={(text: string) => {
                        return <div className="description-style">{text}</div>;
                      }}
                    />
                    <Table.Column
                      title="Quote"
                      dataIndex="quote"
                      key="quote"
                      render={(text: string) => {
                        return <div className="description-style">{text}</div>;
                      }}
                    />
                    <Table.Column
                      title="Image"
                      dataIndex="image"
                      key="image"
                      render={(text: string, record: TeamState) => {
                        return (
                          <Image
                            width={70}
                            height={70}
                            src={record.image.link}
                            fallback={FallbackImg}
                          />
                        );
                      }}
                    />
                    <Table.Column
                      title="Action"
                      key="action"
                      width={'15%'}
                      align="center"
                      render={(text, record: TeamState, i: number) => {
                        return (
                          <div>
                            <Button
                              type="text"
                              style={{ padding: '0px 10px' }}
                              onClick={handleEditButton(i)}
                            >
                              <EditTwoTone twoToneColor={colors.black} />
                            </Button>
                            <Button
                              type="text"
                              style={{ padding: '0px 10px' }}
                              onClick={handleDeleteTeamButton(record, i)}
                            >
                              <DeleteTwoTone twoToneColor={colors.red} />
                            </Button>
                          </div>
                        );
                      }}
                    />
                  </Table>
                </Form.Item>
                <Form.Item wrapperCol={{ span: 12 }}>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      style={{ marginLeft: 110 }}
                      disabled={disabled}
                      loading={storyLoading && submitPressed.current}
                    >
                      Submit
                    </Button>
                    <Button
                      block
                      type="default"
                      style={{ marginLeft: 110 }}
                      disabled={disabled}
                      onClick={onClickCancel}
                    >
                      Discard Changes
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export const OurStoryTab: React.FC = () => {
  return (
    <Tabs defaultActiveKey="1">
      <Tabs.TabPane tab="Our Story" key="1">
        <OurStory />
      </Tabs.TabPane>
      <Tabs.TabPane tab="SEO" key="2">
        <EditSeo />
      </Tabs.TabPane>
    </Tabs>
  );
};

export const OurStoryContent: React.FC = () => {
  const { auth } = useAppSelector((state) => state.auth);
  const { url } = useRouteMatch();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (auth) {
      dispatch(onGetStory());
      dispatch(onGetSeo());
    }
  }, [dispatch, auth]);
  return (
    <div className="category-container">
      <Route exact path={routes.ourStory} component={OurStoryTab} />
      <Route exact path={`${url}/AddHighlights`} component={AddHighlights} />
      <Route exact path={`${url}/highlight/edit`} component={EditHighlight} />
      <Route exact path={`${url}/highlight`} component={ViewHighlights} />
      <Route exact path={`${url}/AddTeam`} component={AddTeam} />
      <Route exact path={`${url}/team`} component={ViewTeam} />
      <Route exact path={`${url}/team/edit`} component={EditTeam} />
    </div>
  );
};
