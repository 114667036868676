import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  FormInstance,
  Input,
  PageHeader,
  Row,
  Spin,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import isEqual from 'lodash/isEqual';
import moment from 'moment';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { AdminProps } from '../../../store/user/types';
import { onEditAdmin } from '../../../store/user/userSlice';

interface UserParamsProps {
  id: string;
}

export const EditAdmin: React.FC = () => {
  const { usersLoading, usersHasError, admins } = useAppSelector(
    (state) => state.user
  );
  const [admin, setAdmin] = useState<AdminProps | undefined>();
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const dispatch = useAppDispatch();
  const formRef = React.createRef<FormInstance>();
  const params: UserParamsProps = useParams();
  const history = useHistory();
  const submitPressed = useRef(false);

  const onFinish = (values: AdminProps) => {
    submitPressed.current = true;
    dispatch(onEditAdmin({ ...values, id: params.id }));
  };

  const onFormValuesChanges = (
    changedValues: unknown,
    allValues: AdminProps
  ) => {
    const adminData = {
      email: admin?.email,
      name: admin?.name,
      dob: admin?.dob,
      address: admin?.address,
      nationality: admin?.nationality,
    };
    const areValuesEqual = isEqual(adminData, allValues);
    setDisabled(areValuesEqual);
  };

  const handleCancelFormBtn = () => {
    setDisabled(true);
    formRef.current?.resetFields();
  };

  useEffect(() => {
    if (params.id && admins?.length) {
      const item = admins?.find((i) => i.id === params.id);

      if (item === undefined) {
        setLoading(false);
        return;
      }

      setAdmin({ ...item, dob: moment(item.dob) });
    }
  }, [admins, params.id]);

  useEffect(() => {
    if (admin && loading) {
      setLoading(false);
    }
  }, [admin, loading]);

  useEffect(() => {
    if (usersHasError && !usersLoading && submitPressed.current) {
      submitPressed.current = false;
    } else if (!usersLoading && submitPressed.current) {
      submitPressed.current = false;
      setDisabled(true);
    }
  }, [usersHasError, usersLoading, formRef, submitPressed]);

  return (
    <Row className="store-wrapper">
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={admin?.name || ''}
        style={{ padding: 0 }}
      />
      <Col
        span={24}
        style={{
          margin: '24px 0px',
          width: '100%',
        }}
      >
        <Card bordered={false}>
          {loading ? (
            <Col className="category-spin" span={24}>
              <Spin />
            </Col>
          ) : !loading && admins && admin ? (
            <Form
              ref={formRef}
              name="basic"
              layout="vertical"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              initialValues={admin}
              autoComplete="off"
              size="middle"
              onValuesChange={onFormValuesChanges}
              onFinish={onFinish}
            >
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: 'Please input an email' },
                  { type: 'email', message: 'Please input a valid' },
                ]}
              >
                <Input disabled prefix={<UserOutlined />} placeholder="Email" />
              </Form.Item>

              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input name' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Date of Birth"
                name="dob"
                rules={[{ required: true, message: 'Please select a date' }]}
              >
                <DatePicker />
              </Form.Item>

              <Form.Item
                label="Address"
                name="address"
                rules={[{ required: true, message: 'Please input an address' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Nationality"
                name="nationality"
                rules={[
                  { required: true, message: 'Please input nationality' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item wrapperCol={{ span: 6 }}>
                <Row gutter={16} className="store-action-buttons">
                  <Col span={12}>
                    <Button
                      block
                      type="primary"
                      className="add-store-submit-btn"
                      htmlType="submit"
                      disabled={disabled}
                      loading={usersLoading && submitPressed.current}
                    >
                      Submit
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button danger onClick={handleCancelFormBtn}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          ) : (
            <Empty />
          )}
        </Card>
      </Col>
    </Row>
  );
};
