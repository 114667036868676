import React, { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Collapse,
  Row,
  Spin,
  Typography,
  Image,
  PageHeader,
} from 'antd';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { EditTwoTone } from '@ant-design/icons';
import { routes } from '../../constants/routes';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { onGetSeo } from '../../store/seo/seoSlice';
import { colors } from '../../constants/theme';
import { EditSeo } from './components/EditSeo';

const SeoList = () => {
  const { seo, seoLoading } = useAppSelector((state) => state.seo);
  const history = useHistory();

  const handleEditButton =
    (id: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      history.push(`${routes.editSeo}/${id}`);
    };

  const genExtra = (id: string) => (
    <EditTwoTone twoToneColor={colors.black} onClick={handleEditButton(id)} />
  );

  const renderSeoPath = () => {
    return seo.map((item, i) => (
      <Collapse.Panel header={item.path} key={i} extra={genExtra(item.id)}>
        <div style={{ paddingLeft: 24 }}>
          <div>
            <Typography.Title level={5}>Title</Typography.Title>
            <p>{item.title}</p>
          </div>
          <div>
            <Typography.Title level={5}>Description</Typography.Title>
            <p>{item.description}</p>
          </div>
          <div>
            <Typography.Title level={5}>Keywords</Typography.Title>
            <p>{item.keywords}</p>
          </div>
          <div>
            <Typography.Title level={5}>URL</Typography.Title>
            <p>{item.url}</p>
          </div>
          <div>
            <Typography.Title level={5}>Image</Typography.Title>
            <Typography.Paragraph copyable>{item.image}</Typography.Paragraph>
            <Image alt={item.title} width={50} src={item.image} />
          </div>
        </div>
      </Collapse.Panel>
    ));
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title={'SEO Content'}
        style={{ padding: 0 }}
      />
      <Row>
        <Col
          span={24}
          style={{
            margin: '24px 0px',
            width: '100%',
          }}
        >
          <Card bordered={false}>
            {seoLoading ? (
              <Col className="category-spin" span={24}>
                <Spin />
              </Col>
            ) : (
              <Collapse accordion>{renderSeoPath()}</Collapse>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export const Seo: React.FC = () => {
  const { auth } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { url } = useRouteMatch();

  useEffect(() => {
    if (auth) {
      dispatch(onGetSeo());
    }
  }, [auth]);
  return (
    <div className="category-container">
      <Route exact path={routes.seo} component={SeoList} />
      <Route path={`${url}/edit/:seoId`} component={EditSeo} />
    </div>
  );
};
