import {
  Card,
  Checkbox,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Row,
  Spin,
  Image,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { TransactionsProps } from '../../store/transactions/types';
import FallbackImg from '../../assets/images/fallback-img.png';

interface ParamsProps {
  transactionid: string;
}

export const TransactionView: React.FC = () => {
  const { transactions } = useAppSelector((state) => state.transaction);
  const [transaction, setTransaction] = useState<
    TransactionsProps | undefined
  >();
  const [loading, setLoading] = useState(true);
  const params: ParamsProps = useParams();
  const history = useHistory();

  useEffect(() => {
    if (params.transactionid && transactions?.transactions?.length) {
      const item = transactions.transactions.find(
        (i) => i.id === params.transactionid
      );

      if (item === undefined) {
        setLoading(false);
        return;
      }

      setTransaction({
        ...item,
        timeadded: moment(item.timeadded),
        datepurchased: moment(item.datepurchased),
      });
    }
  }, [transactions, params.transactionid]);

  useEffect(() => {
    if (transaction && loading) {
      setLoading(false);
    }
  }, [transaction, loading]);

  return (
    <Row className="store-wrapper">
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={transaction?.id || ''}
        style={{ padding: 0 }}
      />
      <Col
        span={24}
        style={{
          margin: '24px 0px',
          width: '100%',
        }}
      >
        <Card bordered={false}>
          {loading ? (
            <Col className="category-spin" span={24}>
              <Spin />
            </Col>
          ) : !loading && transactions && transaction ? (
            <Form
              name="basic"
              layout="vertical"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              initialValues={transaction}
              autoComplete="off"
              size="middle"
            >
              <Form.Item label="Amount" name="amount">
                <InputNumber disabled addonAfter="%" />
              </Form.Item>

              <Form.Item label="Cashback Amount" name={['cashback', 'amount']}>
                <InputNumber disabled addonAfter="€" />
              </Form.Item>

              <Form.Item valuePropName="checked" name={'eligible'}>
                <Checkbox disabled>is Eligible?</Checkbox>
              </Form.Item>

              <Form.Item
                label="Cashback Percentage"
                name={['cashback', 'percentage']}
              >
                <InputNumber disabled addonAfter="%" />
              </Form.Item>

              <Form.Item label="User" name={['user', 'name']}>
                <Input disabled />
              </Form.Item>

              <Form.Item label="Store" name={['store', 'name']}>
                <Input disabled />
              </Form.Item>

              <Form.Item label="Receipt" name={'receipt'}>
                <Input disabled />
              </Form.Item>

              <Form.Item name="file" label="Receipt Image">
                <div style={{ padding: 10 }}>
                  {transaction?.image?.link ? (
                    <Image
                      width={70}
                      height={70}
                      src={transaction.image?.link || ''}
                      fallback={FallbackImg}
                    />
                  ) : (
                    'Receipt image not found'
                  )}
                </div>
              </Form.Item>

              <Form.Item label="Date" name="timeadded">
                <DatePicker disabled />
              </Form.Item>

              <Form.Item label="Date Purchased" name="datepurchased">
                <DatePicker disabled />
              </Form.Item>
            </Form>
          ) : (
            <Empty />
          )}
        </Card>
      </Col>
    </Row>
  );
};
