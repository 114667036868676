import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Spin,
  Typography,
} from 'antd';
import isEqual from 'lodash/isEqual';
import { onUpdateContent } from '../../../store/content/contentSlice';
import { ContentProps } from '../../../store/content/types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { MyContext } from '..';

export const Homepage: React.FC = () => {
  const { content, contentLoading, contentHasError } = useAppSelector(
    (state) => state.content
  );
  const { auth } = useAppSelector((state) => state.auth);
  // const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const dispatch = useAppDispatch();

  const formRef = React.createRef<FormInstance>();
  const submitPressed = useRef(false);
  const {
    homepageContextValue: { loading },
  } = useContext(MyContext);

  const onFinish = (values: ContentProps) => {
    submitPressed.current = true;
    dispatch(onUpdateContent({ ...values }));
  };

  const onFinishFailed = (errorInfo: unknown) => {
    // console.log('Failed:', errorInfo);
  };

  const onClickCancel = () => {
    formRef.current?.resetFields();
    setDisabled(true);
  };

  const onFormValuesChanges = (
    changedValues: unknown,
    allValues: ContentProps
  ) => {
    const copyContent = {
      homepageTitle: content?.homepageTitle,
      homepageDescription: content?.homepageDescription,
      homepageVideo: content?.homepageVideo,
      storesTitle: content?.storesTitle,
      storesDescription: content?.storesDescription,
      storesSearchLabel: content?.storesSearchLabel,
    };

    const areValuesEqual = isEqual(copyContent, allValues);
    setDisabled(areValuesEqual);
  };

  // useEffect(() => {
  //   if (auth) {
  //     dispatch(onGetContents());
  //     setLoading(true);
  //   }
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1500);
  // }, [auth]);

  useEffect(() => {
    if (contentHasError && !contentLoading && submitPressed.current) {
      submitPressed.current = false;
    } else if (!contentLoading && submitPressed.current) {
      submitPressed.current = false;
      setDisabled(true);
    }
  }, [submitPressed, contentLoading]);

  return (
    <Row>
      <Col
        span={24}
        style={{
          margin: '24px 0px',
          width: '100%',
        }}
      >
        <Card bordered={false}>
          {loading ? (
            <Col className="category-spin" span={24}>
              <Spin />
            </Col>
          ) : (
            <Form
              ref={formRef}
              name="basic"
              layout="vertical"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={onFormValuesChanges}
              initialValues={content || {}}
              autoComplete="off"
              size="middle"
            >
              <Typography.Title level={4}>Homepage</Typography.Title>
              <Form.Item label="Homepage Title" name="homepageTitle">
                <Input />
              </Form.Item>

              <Form.Item
                label="Homepage Description"
                name="homepageDescription"
              >
                <Input.TextArea style={{ height: '100px' }} />
              </Form.Item>

              <Form.Item
                label="Homepage Promotional Video Link"
                name="homepageVideo"
              >
                <Input />
              </Form.Item>

              <Typography.Title level={4}>Stores</Typography.Title>
              <Form.Item label="Stores title" name="storesTitle">
                <Input />
              </Form.Item>

              <Form.Item label="Stores Description" name="storesDescription">
                <Input.TextArea style={{ height: '100px' }} />
              </Form.Item>

              <Form.Item label="Stores search label" name="storesSearchLabel">
                <Input />
              </Form.Item>

              <Form.Item wrapperCol={{ span: 12 }}>
                <div style={{ display: 'flex', width: '100%' }}>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    // style={{ marginLeft: 110 }}
                    disabled={disabled}
                    loading={contentLoading && submitPressed.current}
                  >
                    Submit
                  </Button>
                  <Button
                    block
                    type="default"
                    style={{ marginLeft: 110 }}
                    disabled={disabled}
                    onClick={onClickCancel}
                  >
                    Discard Changes
                  </Button>
                </div>
              </Form.Item>
            </Form>
          )}
        </Card>
      </Col>
    </Row>
  );
};
