import React, { useEffect, useState } from 'react';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Col, Row, Space, Table } from 'antd';
import { PlusOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { BlogProps } from '../../store/blogs/types';
import { routes } from '../../constants/routes';
import { colors } from '../../constants/theme';
import { onGetBlogs } from '../../store/blogs/blogSlice';
import { AddBlogs } from './components/addBlogs';
import EditBlogs from './components/edit';
import { DeleteFaqsModal } from './components/deleteModal';
import BlogView from './components/view';
import moment from 'moment';
import { onGetSeo } from '../../store/seo/seoSlice';

export const BlogList: React.FC = () => {
  const { blogs, blogsLoading } = useAppSelector((state) => state.blogs);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedBlogs, setSelectedBlogs] = useState({
    id: '',
    title: '',
  });
  const history = useHistory();

  const handleAddBlogs = () => {
    history.push(routes.addBlogs);
  };

  const handleDeleteButton = (key: any) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDeleteModal(true);
    setSelectedBlogs({ id: key.id, title: key.title });
  };

  const handleEditButton = (key: any) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`${routes.editBlogs}/${key.id}`);
  };

  const handleRowItemClick = (item: BlogProps) => ({
    onClick: () => history.push(`${routes.blogs}/view/${item.id}`),
  });
  return (
    <Row>
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <Col span={24} style={{ float: 'right' }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddBlogs}
          >
            ADD BLOGS
          </Button>
        </Col>
        <Col span={24}>
          <Table
            rowKey={'id'}
            loading={blogsLoading}
            dataSource={blogs}
            pagination={{
              pageSize: 10,
              total: blogs ? blogs.length : 0,
              hideOnSinglePage: true,
            }}
            scroll={{ x: 'max-content' }}
            onRow={handleRowItemClick}
          >
            <Table.Column
              title="ID"
              dataIndex="id"
              // width={'5%'}
              align="left"
              sorter={(a: BlogProps, b: BlogProps) =>
                a.id.toLowerCase().localeCompare(b.id.toLowerCase())
              }
            />
            <Table.Column
              title="Title"
              dataIndex="title"
              className="blog-column"
              align="left"
              sorter={(a: BlogProps, b: BlogProps) =>
                a.title.toLowerCase().localeCompare(b.title.toLowerCase())
              }
              render={(text, record: any) => {
                return <div className="description-style">{record.title}</div>;
              }}
            />
            <Table.Column
              title="Description"
              dataIndex="description"
              align="left"
              sorter={(a: BlogProps, b: BlogProps) =>
                a.description
                  .toLowerCase()
                  .localeCompare(b.description.toLowerCase())
              }
              render={(text, record: any) => {
                const description = record.description.replace(
                  /(<([^>]+)>)/gi,
                  ''
                );
                return <div className="description-style">{description}</div>;
              }}
            />
            <Table.Column
              title="Date"
              dataIndex="date"
              align="left"
              width={'20%'}
              render={(text, record: any) => {
                return <div>{moment(record.date).format('DD/MM/YYYY')}</div>;
              }}
            />
            <Table.Column
              title="Action"
              key="action"
              width={'20%'}
              align="center"
              render={(text, record: any) => {
                return (
                  <div>
                    <Button
                      type="text"
                      style={{ padding: '0px 10px' }}
                      onClick={handleEditButton(record)}
                    >
                      <EditTwoTone twoToneColor={colors.black} />
                    </Button>
                    <Button
                      type="text"
                      style={{ padding: '0px 10px' }}
                      onClick={handleDeleteButton(record)}
                    >
                      <DeleteTwoTone twoToneColor={colors.red} />
                    </Button>
                  </div>
                );
              }}
            />
          </Table>
        </Col>
      </Space>
      <DeleteFaqsModal
        blog={selectedBlogs}
        visible={showDeleteModal}
        setVisible={setShowDeleteModal}
      />
    </Row>
  );
};

export const Blogs: React.FC = () => {
  const { auth } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { url } = useRouteMatch();

  useEffect(() => {
    if (auth) {
      dispatch(onGetBlogs());
      dispatch(onGetSeo());
    }
  }, [dispatch, auth]);
  return (
    <div className="category-container">
      <Route exact path={routes.blogs} component={BlogList} />
      <Route exact path={`${url}/add`} component={AddBlogs} />
      <Route path={`${url}/edit/:blogsId`} component={EditBlogs} />
      <Route path={`${url}/view/:blogsId`} component={BlogView} />
    </div>
  );
};
