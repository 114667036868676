import React, { useEffect, useRef } from 'react';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  PageHeader,
  Row,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { AdminProps } from '../../../store/user/types';
import { onAddAdmin } from '../../../store/user/userSlice';

export const AddAdmin: React.FC = () => {
  const { usersLoading, usersHasError } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const formRef = React.createRef<FormInstance>();
  const history = useHistory();
  const submitPressed = useRef(false);

  const onFinish = (values: AdminProps) => {
    submitPressed.current = true;
    dispatch(onAddAdmin(values));
  };

  useEffect(() => {
    if (usersHasError && !usersLoading && submitPressed.current) {
      submitPressed.current = false;
    } else if (!usersLoading && submitPressed.current) {
      formRef.current && formRef.current.resetFields();
      submitPressed.current = false;
    }
  }, [usersHasError, usersLoading, formRef, submitPressed]);
  return (
    <Row className="store-wrapper">
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={'Back'}
        style={{ padding: 0 }}
      />
      <Col
        span={24}
        style={{
          margin: '24px 0px',
          width: '100%',
        }}
      >
        <Card bordered={false}>
          <Form
            ref={formRef}
            name="basic"
            layout="vertical"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
            autoComplete="off"
            size="middle"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: 'Please input an email' },
                { type: 'email', message: 'Please input a valid' },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="Email" />
            </Form.Item>

            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: 'Please input a password!' },
                { min: 6 },
              ]}
            >
              <Input prefix={<LockOutlined />} placeholder="Password" />
            </Form.Item>

            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please input name' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Date of Birth"
              name="dob"
              rules={[{ required: true, message: 'Please select a date' }]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: true, message: 'Please input an address' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Nationality"
              name="nationality"
              rules={[{ required: true, message: 'Please input nationality' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 6 }}>
              <Button
                block
                type="primary"
                className="add-store-submit-btn"
                htmlType="submit"
                loading={usersLoading && submitPressed.current}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
