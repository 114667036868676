
import React, { useEffect, useRef } from "react";
import { Button, Card, Col, Form, Input, notification, PageHeader, Row, Upload } from "antd"
import { useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { onAddCategories } from "../../../store/category/categorySlice";
import { routes } from "../../../constants/routes";

interface categoryState {
  name: string;
  range: string;
  file: any;
}

export const AddCategory: React.FC = () => {
  const { categoryHasError, categoryErrorMessage, categoryLoading} = useAppSelector(state => state.category);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const formRef: any = React.createRef();
  const submitPressed = useRef(false);

  const onFinish = (values: categoryState) => {
    submitPressed.current = true;
    dispatch(onAddCategories(values));
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    if (categoryHasError && !categoryLoading && submitPressed.current) {
      notification['error']({
        message: 'Error adding Category',
        description: `${categoryErrorMessage}`,
      });
      submitPressed.current = false;
    } else if(!categoryLoading && submitPressed.current) {
      notification['success']({
        message: 'Category added successfully',
        description: '',
      });
      formRef.current.resetFields();
      submitPressed.current = false;
    }
  }, [formRef, categoryErrorMessage, categoryHasError, categoryLoading]);
  return (
    <div >
      <PageHeader
        className="site-page-header"
        onBack={() => history.replace(routes.categories)}
        title="Add Category"
        style={{ padding: 0 }}
      />
      <Row>
        <Col span={24} style={{
          margin: '24px 0px',
          width: '100%'
        }}>
          <Card bordered={false}>
            <Form
              ref={formRef}
              name="basic"
              layout="vertical"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              initialValues={{ categoryName: '', range: '', file: null }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              size='middle'
            >
              <Form.Item
                label="Category Name"
                name="name"
                initialValue={''}
                rules={[{ required: true, message: 'Please input a category name' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Category Range"
                name="range"
                rules={[{ required: true, message: 'Please input a category range' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="file"
                label="Icon"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: true, message: 'Please upload an image' }]}
              >
                <Upload name="logo" listType="picture" maxCount={1}>
                  <Button>Upload Image</Button>
                </Upload>
              </Form.Item>

              <Form.Item wrapperCol={{ span: 6 }}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  loading={categoryLoading && submitPressed.current}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
       </Col>
      </Row>
    </div>
  )
}