import React from 'react';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import { routes } from '../constants/routes';
import { useAppSelector } from '../store/hooks';

const RouteAuthenticated = ({ children }: RouteProps) => {
  const { idToken, user } = useAppSelector((state) => state.auth);
  if (!idToken && !user) {
    return <Redirect to={routes.login} />;
  }

  return <Route>{children}</Route>;
};

export default RouteAuthenticated;

const RouteUnauthenticated = ({ component: Component, path }: RouteProps) => {
  const { idToken, user } = useAppSelector((state) => state.auth);

  return idToken && user ? (
    <Redirect to={routes.dashboard} />
  ) : (
    <Route component={Component} path={path} />
  );
};

export { RouteAuthenticated, RouteUnauthenticated };
