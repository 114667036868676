import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  PageHeader,
  Row,
  Select,
  Typography,
  Upload,
  TimePicker,
  DatePicker,
} from 'antd';
import {
  LockOutlined,
  UserOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import omit from 'lodash/omit';
import pullAll from 'lodash/pullAll';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { routes } from '../../../constants/routes';
import { MaltaStates } from '../../../constants/location';
import { onAddStore } from '../../../store/store/storeSlice';
import { CashbackFormItemProps, FormItemAddBtnProps, formState } from './types';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

const weekday = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const FormItemAddButton = ({ label, add }: FormItemAddBtnProps) => (
  <Form.Item wrapperCol={{ span: 9 }}>
    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
      {label}
    </Button>
  </Form.Item>
);

export const CashbackFormItem = ({
  restField,
  name,
  disabled = false,
}: CashbackFormItemProps) => {
  return (
    <Form.Item
      {...restField}
      name={[name, 'cashback']}
      wrapperCol={{ span: 24 }}
      initialValue={0}
      rules={[
        {
          required: true,
          message: 'Missing cashback value',
        },
      ]}
    >
      <InputNumber
        disabled={disabled}
        addonAfter="%"
        min={1}
        placeholder="Cashback"
      />
    </Form.Item>
  );
};

export const AddStore: React.FC = () => {
  const { storeLoading, storeHasError } = useAppSelector(
    (state) => state.store
  );
  const { categories } = useAppSelector((state) => state.category);
  const [weekdaysSelected, setWeekdaysSelected] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const formRef = React.createRef<FormInstance>();
  const history = useHistory();
  const submitPressed = useRef(false);

  const onFinish = (values: formState) => {
    //Apend Time Rule
    // if (values.timeRules) {
    //   values.rules['time'] = values.timeRules.map((i) => ({
    //     cashback: i.cashback,
    //     from: moment(i.range[0]).format('HH:mm'),
    //     to: moment(i.range[1]).format('HH:mm'),
    //   }));
    // }

    // if (values.dateRules) {
    //   const dateValues = values.dateRules.map((i) => ({
    //     cashback: i.cashback,
    //     from: moment(i.range[0]).toDate(),
    //     to: moment(i.range[1]).toDate(),
    //   }));
    //   values.rules.dates = {
    //     range: dateValues,
    //   };
    // }

    // if (values.weekRules) {
    //   values.rules.dates = {
    //     ...values.rules.dates,
    //     week: values.weekRules,
    //   };
    // }

    // // Append Price Rule
    // if (values.priceRules) {
    //   values.rules['price'] = values.priceRules;
    // }

    // const finalValues = omit(values, [
    //   'timeRules',
    //   'dateRules',
    //   'weekRules',
    //   'priceRules',
    // ]);

    submitPressed.current = true;
    dispatch(onAddStore(values));
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    // console.log('Failed:', errorInfo);
  };

  const handleWeekdaySelection = (selectedItems: string) => {
    setWeekdaysSelected(weekdaysSelected.concat(selectedItems));
  };

  const handleWeekdayDeselection = (deselectedItem: string) => {
    const selectedCopy = [...weekdaysSelected];
    const index = selectedCopy.indexOf(deselectedItem);
    if (index > -1) {
      selectedCopy.splice(index, 1);
      setWeekdaysSelected(selectedCopy);
    }
  };

  const handleWeekFieldRemove =
    (name: string, remove: (index: number | number[]) => void) => () => {
      const fields: formState = formRef.current?.getFieldsValue();

      const selectedCopy = [...weekdaysSelected];
      const fieldValue = fields.weekRules && fields?.weekRules[Number(name)];
      const removedItems = pullAll(selectedCopy, fieldValue?.day);

      setWeekdaysSelected(removedItems);
      remove(Number(name));
    };

  const weekdaySelect = useCallback(() => {
    const filteredOptions = weekday.filter(
      (o) => !weekdaysSelected.includes(o)
    );

    return filteredOptions.map((item) => (
      <Select.Option key={item} value={item}>
        {item}
      </Select.Option>
    ));
  }, [weekdaysSelected]);

  const maltaStates = () =>
    MaltaStates.map((item) => (
      <Select.Option key={item.name} value={item.name}>
        {item.name}
      </Select.Option>
    ));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    if (storeHasError && !storeLoading && submitPressed.current) {
      submitPressed.current = false;
    } else if (!storeLoading && submitPressed.current) {
      formRef.current && formRef.current.resetFields();
      submitPressed.current = false;
    }
  }, [storeHasError, storeLoading, formRef, submitPressed]);
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.replace(routes.stores)}
        title="Add Store"
        style={{ padding: 0 }}
      />
      <Row className="add-store-wrapper">
        <Col
          span={24}
          style={{
            margin: '24px 0px',
            width: '100%',
          }}
        >
          <Card bordered={false}>
            <Form
              ref={formRef}
              name="basic"
              labelCol={{ flex: '110px' }}
              labelAlign="left"
              labelWrap
              wrapperCol={{ span: 12 }}
              autoComplete="off"
              size="middle"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              scrollToFirstError
              initialValues={{ isFeatured: false }}
            >
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: 'Please input an email' },
                  { type: 'email', message: 'Please input a valid' },
                ]}
              >
                <Input prefix={<UserOutlined />} placeholder="Email" />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { required: true, message: 'Please input your password!' },
                  { min: 6 },
                ]}
              >
                <Input prefix={<LockOutlined />} placeholder="Password" />
              </Form.Item>

              <Form.Item
                name="file"
                label="Icon"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: true, message: 'Please upload an image' }]}
              >
                <Upload name="logo" listType="picture" maxCount={1}>
                  <Button>Upload Image</Button>
                </Upload>
              </Form.Item>

              <Form.Item
                label="Name"
                name="name"
                rules={[
                  { required: true, message: 'Please input a store name' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                rules={[
                  { required: true, message: 'Please input store description' },
                ]}
              >
                <Input.TextArea rows={2} />
              </Form.Item>

              <Form.Item
                label="Category"
                name="category"
                rules={[
                  { required: true, message: 'Please select store category' },
                ]}
              >
                <Select mode="multiple">
                  {categories.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Locaton"
                name="location"
                rules={[
                  { required: true, message: 'Please select store location' },
                ]}
              >
                <Select showSearch>{maltaStates()}</Select>
              </Form.Item>

              <Form.Item label="Website" name="website">
                <Input />
              </Form.Item>

              <Form.Item
                label="Featured"
                name="isFeatured"
                valuePropName="checked"
              >
                <Checkbox>Is featured Store?</Checkbox>
              </Form.Item>

              <Form.Item
                label="Cashback"
                name={['rules', 'cashback']}
                initialValue={0}
                normalize={(value) => (value === null ? 0 : value)}
              >
                <InputNumber addonAfter="%" min={0} max={100} />
              </Form.Item>

              {/* PRICE CASHBACK RULES */}
              {/* <Typography.Title level={5}>Minimum Price</Typography.Title> */}
              {/* <Form.List name="priceRules">
                {(fields, { add, remove }) => (
                  <div className="store-price-form-item">
                    {fields.map(({ key, name, ...restField }) => (
                      <Row
                        key={key}
                        gutter={8}
                        className="price-form-item-wrapper"
                        style={{}}
                      >
                        <Col span={5}>
                          <Form.Item
                            {...restField}
                            name={[name, 'min']}
                            initialValue={0}
                            rules={[
                              {
                                required: true,
                                message: 'Missing minimum price',
                              },
                            ]}
                            wrapperCol={{ span: 24 }}
                          >
                            <InputNumber
                              addonAfter="€"
                              min={1}
                              placeholder="Min Price"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <CashbackFormItem name={name} restField={restField} />
                        </Col>
                        <Col span={2} className="store-remove-icon">
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Col>
                      </Row>
                    ))}
                    <FormItemAddButton label="Add Price Field" add={add} />
                  </div>
                )}
              </Form.List> */}

              {/* TIME CASHBACK RULES */}
              {/* <Typography.Title level={5}>Time</Typography.Title>
              <Form.List name="timeRules">
                {(fields, { add, remove }) => (
                  <div className="store-price-form-item">
                    {fields.map(({ key, name, ...restField }) => (
                      <Row
                        key={key}
                        gutter={8}
                        className="price-form-item-wrapper"
                        style={{}}
                      >
                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            name={[name, 'range']}
                            rules={[
                              { required: true, message: 'Missing time range' },
                            ]}
                            wrapperCol={{ span: 24 }}
                            // normalize={e => ({ from: moment(e[0]).format('HH:mm') })}
                          >
                            <TimePicker.RangePicker
                              minuteStep={10}
                              style={{ width: '100%' }}
                              format={'HH:mm'}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <CashbackFormItem name={name} restField={restField} />
                        </Col>
                        <Col span={2} className="store-remove-icon">
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Col>
                      </Row>
                    ))}
                    <FormItemAddButton label="Add Time Field" add={add} />
                  </div>
                )}
              </Form.List> */}

              {/* DATE CASHBACK RULES */}
              {/* <Typography.Title level={5}>Date</Typography.Title>
              <Form.List name="dateRules">
                {(fields, { add, remove }) => (
                  <div className="store-price-form-item">
                    {fields.map(({ key, name, ...restField }) => (
                      <Row
                        key={key}
                        gutter={8}
                        className="price-form-item-wrapper"
                        style={{}}
                      >
                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            name={[name, 'range']}
                            rules={[
                              { required: true, message: 'Missing date range' },
                            ]}
                            wrapperCol={{ span: 24 }}
                          >
                            <DatePicker.RangePicker style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <CashbackFormItem name={name} restField={restField} />
                        </Col>
                        <Col span={2} className="store-remove-icon">
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Col>
                      </Row>
                    ))}
                    <FormItemAddButton label="Add Date Field" add={add} />
                  </div>
                )}
              </Form.List> */}

              {/* WEEK CASHBACK RULES */}
              {/* <Typography.Title level={5}>Week</Typography.Title>
              <Form.List name="weekRules">
                {(fields, { add, remove }) => (
                  <div className="store-price-form-item">
                    {fields.map(({ key, name, ...restField }) => (
                      <Row
                        key={key}
                        gutter={8}
                        className="price-form-item-wrapper"
                        style={{}}
                      >
                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            name={[name, 'day']}
                            rules={[
                              {
                                required: true,
                                message: 'Please select day/s',
                              },
                            ]}
                            wrapperCol={{ span: 24 }}
                          >
                            <Select
                              mode="multiple"
                              allowClear
                              style={{ width: '100%' }}
                              placeholder="Please select"
                              onSelect={handleWeekdaySelection}
                              onDeselect={handleWeekdayDeselection}
                            >
                              {weekdaySelect()}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <CashbackFormItem name={name} restField={restField} />
                        </Col>
                        <Col span={2} className="store-remove-icon">
                          <MinusCircleOutlined
                            onClick={handleWeekFieldRemove(`${name}`, remove)}
                          />
                        </Col>
                      </Row>
                    ))}
                    {weekdaysSelected.length < 7 && (
                      <FormItemAddButton label="Add Week Field" add={add} />
                    )}
                  </div>
                )}
              </Form.List> */}

              <Form.Item wrapperCol={{ span: 6 }}>
                <Button
                  block
                  type="primary"
                  className="add-store-submit-btn"
                  htmlType="submit"
                  loading={storeLoading && submitPressed.current}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
