import React, { ReactElement } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import {
  Switch,
  BrowserRouter as Router,
  useLocation,
  Route,
} from 'react-router-dom';
import { Location } from 'history';
import { Auth } from './auth';
import { Dashboard } from './dashboard';
import { RouteAuthenticated, RouteUnauthenticated } from './ProtectedRoutes';
import { Category } from './category';
import { DashboardLayout } from '../globalComponents/layout';
import { routes } from '../constants/routes';
import { Store } from './store';
import { Faqs } from './faqs';
import { Blogs } from './blogs';
import { User } from './users';
import { Transactions } from './transactions';
import { ContactUs } from './contact';
import { OurStoryContent } from './ourstory';
import { Admin } from './admins';
import { ContentMain } from './content';
import { Seo } from './seo';

export const App: React.FC = (): ReactElement => {
  const location: Location = useLocation();

  return (
    <div className="main-bg">
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <TransitionGroup className="main-wrapper">
          <Router>
            <Switch>
              <RouteUnauthenticated exact path="/login" component={Auth} />
              <RouteAuthenticated>
                <DashboardLayout>
                  <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route path={routes.categories} component={Category} />
                    <Route path={routes.stores} component={Store} />
                    <Route path={routes.faqs} component={Faqs} />
                    <Route path={routes.blogs} component={Blogs} />
                    <Route path={routes.users} component={User} />
                    <Route
                      path={routes.transactions}
                      component={Transactions}
                    />
                    <Route path={routes.contact} component={ContactUs} />
                    <Route path={routes.content} component={ContentMain} />
                    <Route path={routes.ourStory} component={OurStoryContent} />
                    <Route path={routes.seo} component={Seo} />
                    <Route path={routes.admins} component={Admin} />
                  </Switch>
                </DashboardLayout>
              </RouteAuthenticated>
            </Switch>
          </Router>
        </TransitionGroup>
      </CSSTransition>
    </div>
  );
};
