import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  PageHeader,
  Row,
  Upload,
  notification,
  DatePicker
} from 'antd';
import { useHistory } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { routes } from '../../../constants/routes';
import { blogState } from '../../../store/blogs/types';
import { onAddBlogs } from '../../../store/blogs/blogSlice';

export const AddBlogs: React.FC = () => {
  const { blogsLoading, blogsHasError, blogsErrorMessage } = useAppSelector(
    (state) => state.blogs
  );
  const [editorState, setEditorState] = useState<any>();
  const [description, setDescription] = useState('');
  const dispatch = useAppDispatch();
  const formRef: any = React.createRef();
  const history = useHistory();
  const submitPressed = useRef(false);

  const onFinish = (values: blogState) => {
    submitPressed.current = true;
    const body = {
      ...values,
      date: new Date(values.date),
      description: description,
    };
    dispatch(onAddBlogs(body));
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };

  const onEditorStateChange = (e: any) => {
    setEditorState(e);
  };

  const onEditorChange = (e: any) => {
    const html = draftToHtml(e);
    setDescription(html);
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    if (blogsHasError && !blogsLoading && submitPressed.current) {
      notification['error']({
        message: 'Error adding Blogs',
        description: `${blogsErrorMessage}`,
      });
      submitPressed.current = false;
    } else if (!blogsLoading && submitPressed.current) {
      notification['success']({
        message: 'Blogs added successfully',
        description: '',
      });
      formRef.current.resetFields();
      submitPressed.current = false;
    }
  }, [formRef, blogsErrorMessage, blogsHasError, blogsLoading]);
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.replace(routes.blogs)}
        title="Add Blogs"
        style={{ padding: 0 }}
      />
      <Row>
        <Col
          span={24}
          style={{
            margin: '24px 0px',
            width: '100%',
          }}
        >
          <Card bordered={false}>
            <Form
              ref={formRef}
              name="basic"
              layout="vertical"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              initialValues={{ title: '', description: '', file: null }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              size="middle"
            >
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  { required: true, message: 'Please input a blogs title' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  { required: true, message: 'Please input blogs description' },
                ]}
              >
                {/* <Input.TextArea rows={2} /> */}
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={onEditorStateChange}
                  onContentStateChange={onEditorChange}
                />
              </Form.Item>
              <Form.Item
                label="Date"
                name="date"
                rules={[
                  { required: true, message: 'Please input a blogs date' },
                ]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                name="file"
                label="Icon"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: true, message: 'Please upload an image' }]}
              >
                <Upload name="logo" listType="picture" maxCount={1}>
                  <Button>Upload Image</Button>
                </Upload>
              </Form.Item>
              <Form.Item wrapperCol={{ span: 6 }}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: 110 }}
                  loading={blogsLoading && submitPressed.current}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
