import React, { useEffect, useRef, useState } from 'react';
import {
  PageHeader,
  Row,
  Col,
  Card,
  Spin,
  Empty,
  Form,
  FormInstance,
  Input,
  Button,
  DatePicker,
  Upload,
  Tabs,
  Typography,
  Space,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { BlogProps } from '../../../store/blogs/types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Editor } from 'react-draft-wysiwyg';
import isEqual from 'lodash/isEqual';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment';
import { onEditBlogs } from '../../../store/blogs/blogSlice';
import { EditSeo } from '../../seo/components/EditSeo';
import { AnyRecord } from 'dns';

interface BlogsParamsProps {
  blogsId: string;
}

interface blogState {
  title: string;
  description: string;
  date: any;
  file: any;
}

const EditBlogs: React.FC = () => {
  const { blogs, blogsLoading, blogsHasError } = useAppSelector(
    (state) => state.blogs
  );
  const [blog, setBlogs] = useState<BlogProps | undefined>();
  const [editorState, setEditorState] = useState<any>();
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const history = useHistory();
  const formRef = React.createRef<FormInstance>();
  const submitPressed = useRef(false);
  const params: BlogsParamsProps = useParams();
  const dispatch = useAppDispatch();

  const onFinish = (values: blogState) => {
    submitPressed.current = true;
    const formValues = new FormData();
    if (values.title !== blog?.title) {
      formValues.append('title', values.title);
    }

    if (values.date !== blog?.date) {
      formValues.append('date', values.date);
    }

    if (values.description !== blog?.description) {
      formValues.append('description', description);
    }

    if (!isEqual(values.file, blog?.image)) {
      formValues.append('image', values.file[0]?.originFileObj);
    }
    dispatch(onEditBlogs({ form: formValues, id: params.blogsId }));
  };
  const onFinishFailed = () => {};
  const onFormValuesChanges = (changedValues: any, allValues: blogState) => {
    const { title, description, date } = allValues;
    if (title === '' || description == '' || date === null) {
      setDisabled(true);
      return;
    }
    if (title === blog?.title && description === blog.description) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
  };

  const onEditorStateChange = (e: any) => {
    setEditorState(e);
  };

  const onEditorChange = (e: any) => {
    const html = draftToHtml(e);
    setDescription(html);
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const getBlogs = () => {
    let description: any = '';
    if (params.blogsId && blogs.length) {
      const item = blogs.find((i) => i.id === params.blogsId);

      if (!item) {
        setLoading(false);
        return;
      }
      description = item?.description;
      setBlogs(item);
      const blocksFromHTML = convertFromHTML(description);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const contentValue = EditorState.createWithContent(contentState);
      setEditorState(contentValue);
      setLoading(false);
    }
    // setTimeout(() => {
    //   setLoading(false);
    // }, 500);
  };

  useEffect(() => {
    if (blogsHasError && !blogsLoading && submitPressed.current) {
      submitPressed.current = false;
    } else if (!blogsLoading && submitPressed.current) {
      submitPressed.current = false;
      setDisabled(true);
    }
  }, [submitPressed, blogsLoading]);
  useEffect(getBlogs, [blogs, params.blogsId]);
  return (
    <Row>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={blog?.title || ''}
        style={{ padding: 0 }}
      />
      <Col
        span={24}
        style={{
          margin: '24px 0px',
          width: '100%',
        }}
      >
        <Card bordered={false}>
          {loading ? (
            <Col className="category-spin" span={24}>
              <Spin />
            </Col>
          ) : !loading && blog ? (
            <Form
              ref={formRef}
              name="basic"
              layout="vertical"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={onFormValuesChanges}
              initialValues={{
                title: blog?.title,
                description: blog?.description,
                date: moment(blog.date),
                file: blog?.image,
              }}
              autoComplete="off"
              size="middle"
            >
              <Form.Item
                label="Blogs title"
                name="title"
                rules={[
                  { required: true, message: 'Please input a blogs title' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Blogs description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Please input a blogs description',
                  },
                ]}
              >
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={onEditorStateChange}
                  onContentStateChange={onEditorChange}
                />
              </Form.Item>
              <Form.Item
                label="Date"
                name="date"
                rules={[
                  { required: true, message: 'Please input a blogs date' },
                ]}
              >
                <DatePicker />
              </Form.Item>

              <Form.Item
                name="file"
                label="Icon"
                // valuePropName="fileList"
                getValueFromEvent={normFile}
                // rules={[{ required: true, message: 'Please upload an image' }]}
              >
                <Upload
                  name="logo"
                  listType="picture"
                  maxCount={1}
                  defaultFileList={[
                    {
                      uid: '-1',
                      name: blog.image && blog.image.filename,
                      url: blog.image && blog.image.link,
                    },
                  ]}
                >
                  <Space direction="vertical">
                    <Typography.Text type="secondary" copyable>
                      {blog?.image?.link}
                    </Typography.Text>
                    <Button>Upload Image</Button>
                  </Space>
                </Upload>
              </Form.Item>
              <Form.Item wrapperCol={{ span: 6 }}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  disabled={disabled}
                  loading={blogsLoading && submitPressed.current}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <Empty />
          )}
        </Card>
      </Col>
    </Row>
  );
};

export const BlogsTab: React.FC = () => {
  return (
    <Tabs defaultActiveKey="1">
      <Tabs.TabPane tab="Our Story" key="1">
        <EditBlogs />
      </Tabs.TabPane>
      <Tabs.TabPane tab="SEO" key="2">
        <EditSeo />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default BlogsTab;
