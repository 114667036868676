export const routes = {
  // Roots
  login: '/login',

  dashboard: '/',
  categories: '/categories',
  addCategories: '/categories/add',
  editCategory: '/categories/edit',
  stores: '/stores',
  addStores: '/stores/add',
  viewStores: '/stores/view',
  editStore: '/stores/edit',
  faqs: '/faqs',
  addFaqs: '/faqs/add',
  editFaqs: '/faqs/edit',
  blogs: '/blogs',
  addBlogs: '/blogs/add',
  editBlogs: '/blogs/edit',

  users: '/users',
  viewUsers: '/users/view',

  admins: '/admins',
  addAdmin: '/admins/add',
  viewAdmin: '/admins/view',
  editAdmin: '/admins/edit',

  transactions: '/transactions',
  viewTransactions: '/transactions/view',
  contact: '/contact-us',
  ourStory: '/our-story',
  addStoryHighlights: '/our-story/AddHighlights',
  viewStoryHighlights: '/our-story/highlight',
  editStoryHighlights: '/our-story/highlight/edit',

  addStoryTeam: '/our-story/AddTeam',
  viewStoryTeam: '/our-story/team',
  editStoryTeam: '/our-story/team/edit',
  content: '/content',
  contentEdit: '/content/edit',

  seo: '/seo',
  editSeo: '/seo/edit',
};
