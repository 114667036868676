import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Spin,
  Typography,
  Upload,
  Collapse,
  Image,
} from 'antd';
import { EditTwoTone } from '@ant-design/icons';
import { useAppSelector } from '../../../store/hooks';
import { colors } from '../../../constants/theme';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../constants/routes';
import { HIW_KEY } from '../../../store/content/types';
import { MyContext } from '..';

interface HowItWorksProps {
  title?: string;
  description?: string;
  content?: string;
  icon?: string;
  key: HIW_KEY;
}

export const HowItWorks: React.FC = () => {
  const {
    homepageContextValue: { loading },
  } = useContext(MyContext);
  const { content } = useAppSelector((state) => state.content);
  const { auth } = useAppSelector((state) => state.auth);
  const history = useHistory();

  const handleEditButton =
    (index: HIW_KEY) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      history.push({
        pathname: routes.contentEdit,
        state: { key: index },
      });
    };

  const genExtra = (key: HIW_KEY) => (
    <EditTwoTone twoToneColor={colors.black} onClick={handleEditButton(key)} />
  );

  const renderFormItems = () => {
    const howitWorksItems: HowItWorksProps[] = [
      {
        key: 'HIW_signup',
        ...content?.HIW_signup,
      },
      {
        key: 'HIW_QR',
        ...content?.HIW_QR,
      },
      {
        key: 'HIW_money',
        ...content?.HIW_money,
      },
    ];

    if (content) {
      return howitWorksItems?.map((item, i) => (
        <Collapse.Panel header={item.title} key={i} extra={genExtra(item.key)}>
          <div style={{ paddingLeft: 24 }}>
            <Typography.Title level={5}>Description</Typography.Title>
            <p>{item.description}</p>
          </div>

          <div style={{ paddingLeft: 24 }}>
            <Typography.Title level={5}>Content</Typography.Title>
            <ol>
              {item?.content?.split('\n').map((itm, i) => (
                <li key={i}>{itm}</li>
              ))}
            </ol>
          </div>

          <div style={{ paddingLeft: 24 }}>
            <Typography.Title level={5}>Icon</Typography.Title>
            <Image alt={item.title} width={50} src={item.icon} />
          </div>
        </Collapse.Panel>
      ));
    }
  };

  return (
    <div className="category-container">
      <Row>
        <Col
          span={24}
          style={{
            margin: '24px 0px',
            width: '100%',
          }}
        >
          <Card bordered={false}>
            {loading ? (
              <Col className="category-spin" span={24}>
                <Spin />
              </Col>
            ) : (
              <Collapse accordion>{renderFormItems()}</Collapse>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};
