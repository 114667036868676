/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LoadingState,
  ErrorState,
  StoryState,
  updateStoryState,
  AddTeamState,
} from './types';

const initialState = {
  ourstory: null,
} as StoryState;

export const storySlice = createSlice({
  name: 'story',
  initialState,
  reducers: {
    onGetStory: () => {},
    onUpdateStory: (state, action: PayloadAction<updateStoryState>) => {},
    onAddStoryTeam: (state, action: PayloadAction<AddTeamState>) => { },
    onEditStoryTeam: (state, action: PayloadAction<AddTeamState>) => { },
    setStory: (state, action: PayloadAction<StoryState>) => {
      state.ourstory = action.payload.ourstory;
    },
    setStoryLoading: (state, action: PayloadAction<LoadingState>) => {
      state.storyLoading = action.payload.storyLoading;
      state.storyMessage = action.payload.storyMessage;
    },
    setStoryError: (state, action: PayloadAction<ErrorState>) => {
      state.storyHasError = action.payload.storyHasError;
      state.storyErrorMessage = action.payload.storyErrorMessage;
    },
  },
});

export const {
  onGetStory,
  setStory,
  setStoryLoading,
  setStoryError,
  onUpdateStory,
  onAddStoryTeam,
  onEditStoryTeam,
} = storySlice.actions;

export default storySlice.reducer;
