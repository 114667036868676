/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Col, Row, Space, Table, Tooltip } from 'antd';
import {
  PlusOutlined,
  EditTwoTone,
  DeleteTwoTone,
  EyeTwoTone,
  EyeInvisibleTwoTone,
} from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { StoreProps } from '../../store/store/types';
import { onGetStores } from '../../store/store/storeSlice';
import { routes } from '../../constants/routes';
import { colors } from '../../constants/theme';
import { AddStore } from './components/addStore';
import { DeleteStoreModal } from './components/deleteStore';
import { StoreView } from './components/viewStore';
import { EditStore } from './components/editStore';
import { StoreVisibilityModal } from './components/StoreVisibilityModal';

interface SelectedStoreProps {
  id: string;
  name: string;
  isStoreVisible?: boolean;
}

const initState = {
  id: '',
  name: '',
};

export const StoreList: React.FC = () => {
  const { stores, storeLoading } = useAppSelector((state) => state.store);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showVisibilityModal, setShowVisibilityModal] = useState(false);
  const [selectedStore, setSelectedStore] = useState<SelectedStoreProps>(initState);
  const history = useHistory();

  const handleAddCategory = () => {
    history.push(routes.addStores);
  };

  const handleDeleteButton = (key: any) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDeleteModal(true);
    setSelectedStore({ id: key.storeid, name: key.name });
  };

  const handleEditButton = (key: any) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`${routes.editStore}/${key.storeid}`);
  };

  const handleStoreVisibility = (key: StoreProps) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowVisibilityModal(true);
    setSelectedStore({
      id: key.storeid,
      name: key.name,
      isStoreVisible: key.isvisible,
    });
  };

  const handleRowItemClick = (item: StoreProps) => ({
    onClick: () => history.push(`${routes.viewStores}/${item.storeid}`),
  });

  useEffect(() => {
    if (!showDeleteModal && !showVisibilityModal) {
      setSelectedStore(initState);
    }
  }, [showDeleteModal, showVisibilityModal]);
  return (
    <Row>
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <Col span={24} style={{ float: 'right' }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddCategory}
          >
            ADD STORE
          </Button>
        </Col>
        <Col span={24}>
          <Table
            rowKey={'storeid'}
            loading={storeLoading}
            dataSource={stores}
            pagination={{
              pageSize: 10,
              total: stores ? stores.length : 0,
              hideOnSinglePage: true,
            }}
            scroll={{ x: 'max-content' }}
            onRow={handleRowItemClick}
          >
            <Table.Column
              title="ID"
              dataIndex="storeid"
              width={'20%'}
              align="left"
              sorter={(a: StoreProps, b: StoreProps) =>
                a.storeid.toLowerCase().localeCompare(b.storeid.toLowerCase())
              }
            />
            <Table.Column
              title="Name"
              dataIndex="name"
              align="left"
              sorter={(a: StoreProps, b: StoreProps) =>
                a.name.toLowerCase().localeCompare(b.name.toLowerCase())
              }
            />
            <Table.Column
              title="Location"
              dataIndex="location"
              align="left"
              sorter={(a: StoreProps, b: StoreProps) =>
                a.location.toLowerCase().localeCompare(b.location.toLowerCase())
              }
            />
            <Table.Column
              title="Cashback"
              dataIndex="rules"
              align="left"
              sorter={(a: StoreProps, b: StoreProps) =>
                a.rules.cashback - b.rules.cashback
              }
              render={(value) => <div>{value.cashback}%</div>}
            />
            <Table.Column
              title="Action"
              key="action"
              width={'15%'}
              align="center"
              render={(text: StoreProps, record: any) => {
                return (
                  <div>
                    <Button
                      type="text"
                      style={{ padding: '0px 10px' }}
                      onClick={handleStoreVisibility(record)}
                    >
                      {text.isvisible === false ? (
                        <Tooltip placement="top" title="This store is hidden">
                          <EyeInvisibleTwoTone twoToneColor={colors.red} />
                        </Tooltip>
                      ) : (
                        <Tooltip placement="top" title="This store is visible">
                          <EyeTwoTone twoToneColor={'#000000BD'} />
                        </Tooltip>
                      )}
                    </Button>
                    <Button
                      type="text"
                      style={{ padding: '0px 10px' }}
                      onClick={handleEditButton(record)}
                    >
                      <EditTwoTone twoToneColor={colors.black} />
                    </Button>
                    {/* UNCOMMENT TO ENABLE DELETE BUTTON */}
                    <Button
                      type="text"
                      style={{ padding: '0px 10px' }}
                      onClick={handleDeleteButton(record)}
                    >
                      <DeleteTwoTone twoToneColor={colors.red} />
                    </Button>
                  </div>
                );
              }}
            />
          </Table>
        </Col>
      </Space>
      <DeleteStoreModal
        store={selectedStore}
        visible={showDeleteModal}
        setVisible={setShowDeleteModal}
      />
      <StoreVisibilityModal
        store={selectedStore}
        visible={showVisibilityModal}
        setVisible={setShowVisibilityModal}
      />
    </Row>
  );
};

export const Store: React.FC = () => {
  const { auth } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { url } = useRouteMatch();

  useEffect(() => {
    if (auth) {
      dispatch(onGetStores());
    }
  }, [dispatch, auth]);
  return (
    <div className="store-container">
      <Route exact path={routes.stores} component={StoreList} />
      <Route exact path={`${url}/add`} component={AddStore} />
      <Route path={`${url}/view/:storeid`} component={StoreView} />
      <Route path={`${url}/edit/:storeid`} component={EditStore} />
    </div>
  );
};
