import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { get, patch } from '../../constants/api';
import { getToken } from '../auth/saga';
import {
  onUpdateContact,
  setContact,
  setContactError,
  setContactLoading,
} from '../contact/contactSlice';
import { notification } from 'antd';
import { onGetContacts } from './contactSlice';
import { updateContactState } from './types';

export function* getContacts(): SagaIterator<void> {
  try {
    yield put(
      setContactLoading({
        contactLoading: true,
        contactMessage: '',
      })
    );
    const token = yield call(getToken);
    const { data, status }: AxiosResponse = yield call(
      get,
      `contact-us`,
      undefined,
      token
    );

    if (status === 200) {
      yield put(setContact({ contact: data.data }));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setContactError({
          contactHasError: true,
          contactErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setContactLoading({
        contactLoading: false,
        contactMessage: '',
      })
    );

    yield put(
      setContactError({
        contactHasError: false,
        contactErrorMessage: ``,
      })
    );
  }
}

export function* updateContact({
  payload,
}: PayloadAction<updateContactState>): SagaIterator<void> {
  try {
    yield put(
      setContactLoading({
        contactLoading: true,
        contactMessage: '',
      })
    );
    const token = yield call(getToken);
    const { status }: AxiosResponse = yield call(
      patch,
      `contact-us`,
      payload.form,
      token
    );
    if (status === 200) {
      yield put(onGetContacts());
      notification['success']({
        message: 'Contacts updated successfully',
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setContactError({
          contactHasError: true,
          contactErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setContactLoading({
        contactLoading: false,
        contactMessage: '',
      })
    );

    yield put(
      setContactError({
        contactHasError: false,
        contactErrorMessage: ``,
      })
    );
  }
}

export function* contactSaga(): SagaIterator<void> {
  yield all([takeLatest(onGetContacts, getContacts)]);
  yield all([takeLatest(onUpdateContact, updateContact)]);
}
