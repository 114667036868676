/* eslint-disable prettier/prettier */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState, ErrorState, LoadingState, loginState } from './types';

const initialState = {
  idToken: null,
  auth: null,
  loginLoading: false,
  loginMessage: '',
  loginHasError: false,
  loginErrorMessage: ''
} as AuthState;

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    onLogin: (state, action: PayloadAction<loginState>) => { },
    onLogout: (state, action: PayloadAction<any>) => { },
    setAuth: (state, action: PayloadAction<AuthState>) => {
      state.idToken = action.payload.idToken
      state.auth = action.payload.auth
      state.user = action.payload.user
    },
    setLoginLoading: (state, action: PayloadAction<LoadingState>) => {
      state.loginLoading = action.payload.loginLoading
      state.loginMessage = action.payload.loginMessage
    },
    setLoginError: (state, action: PayloadAction<ErrorState>) => {
      state.loginHasError = action.payload.loginHasError
      state.loginErrorMessage = action.payload.loginErrorMessage
    }
  }
});

export const { setAuth, onLogin, onLogout, setLoginLoading, setLoginError } = authSlice.actions;
export default authSlice.reducer;