import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';

import {
  onAddFaqs,
  onDeleteFaqs,
  onEditFaqs,
  onGetFaqs,
  setFaqs,
  setFaqsError,
  setFaqsLoading,
} from './faqsSlice';

import { get, post, patch, deleted } from '../../constants/api';
import { getToken } from '../auth/saga';
import { AddFaqsProps, DeleteProps, EditFaqsState } from './types';
import { PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';

function* getFaqs(): SagaIterator<void> {
  try {
    yield put(
      setFaqsLoading({
        faqsLoading: true,
        faqsMessage: '',
      })
    );

    const token = yield call(getToken);
    const { data, status }: AxiosResponse = yield call(get, `faqs`, token);

    if (status === 200) {
      yield put(setFaqs({ faqs: data.data }));
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setFaqsError({
          faqsHasError: true,
          faqsErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setFaqsLoading({
        faqsLoading: false,
        faqsMessage: '',
      })
    );

    yield put(
      setFaqsError({
        faqsHasError: false,
        faqsErrorMessage: ``,
      })
    );
  }
}

export function* addFaqs({
  payload,
}: PayloadAction<AddFaqsProps>): SagaIterator<void> {
  try {
    yield put(
      setFaqsLoading({
        faqsLoading: true,
        faqsMessage: '',
      })
    );
    const token = yield call(getToken);
    const addFaqs: AxiosResponse = yield call(post, `faqs`, payload, token);
    if (addFaqs.status === 200) {
      yield put(onGetFaqs());
      notification['success']({
        message: 'Faqs added successfully',
        description: '',
      });
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setFaqsError({
          faqsHasError: true,
          faqsErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setFaqsLoading({
        faqsLoading: false,
        faqsMessage: '',
      })
    );

    yield put(
      setFaqsError({
        faqsHasError: false,
        faqsErrorMessage: ``,
      })
    );
  }
}

export function* editFaqs({
  payload,
}: PayloadAction<EditFaqsState>): SagaIterator<void> {
  try {
    yield put(
      setFaqsLoading({
        faqsLoading: true,
        faqsMessage: '',
      })
    );

    const token = yield call(getToken);
    const { status }: AxiosResponse = yield call(
      patch,
      `faqs/${payload.id}`,
      payload.form,
      token
      // true
    );

    if (status === 200) {
      yield put(onGetFaqs());
      notification['success']({
        message: 'Faqs updated successfully',
        description: '',
      });
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setFaqsError({
          faqsHasError: true,
          faqsErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setFaqsLoading({
        faqsLoading: false,
        faqsMessage: '',
      })
    );

    yield put(
      setFaqsError({
        faqsHasError: false,
        faqsErrorMessage: ``,
      })
    );
  }
}

export function* deleteFaqs({
  payload,
}: PayloadAction<DeleteProps>): SagaIterator<void> {
  try {
    yield put(
      setFaqsLoading({
        faqsLoading: true,
        faqsMessage: '',
      })
    );
    const token = yield call(getToken);
    const { status }: AxiosResponse = yield call(
      deleted,
      `faqs/${payload.id}`,
      undefined,
      token
    );

    if (status === 200) {
      yield put(
        setFaqsLoading({
          faqsLoading: false,
          faqsMessage: 'Faqs deleted successfully',
        })
      );
      yield put(onGetFaqs());
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setFaqsError({
          faqsHasError: true,
          faqsErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setFaqsLoading({
        faqsLoading: false,
        faqsMessage: '',
      })
    );

    yield put(
      setFaqsError({
        faqsHasError: false,
        faqsErrorMessage: ``,
      })
    );
  }
}

export function* faqsSaga(): SagaIterator<void> {
  yield all([takeLatest(onGetFaqs, getFaqs)]);
  yield all([takeLatest(onAddFaqs, addFaqs)]);
  yield all([takeLatest(onEditFaqs, editFaqs)]);
  yield all([takeLatest(onDeleteFaqs, deleteFaqs)]);
}
