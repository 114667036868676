import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Modal, Button, Typography, notification } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { onDeleteCategory } from '../../../store/category/categorySlice';
import { onDeleteFaqs } from '../../../store/faqs/faqsSlice';

interface ModalProps {
  faq: {
    title: string;
    id: string;
  };
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

export const DeleteFaqsModal: React.FC<ModalProps> = ({
  faq,
  visible,
  setVisible,
}) => {
  const { faqsHasError, faqsErrorMessage, faqsLoading } = useAppSelector(
    (state) => state.faqs
  );
  const dispatch = useAppDispatch();
  const deletePressed = useRef(false);

  const handleOk = () => {};

  const handleCancel = () => {
    setVisible(false);
  };

  const handleFaqsDeletion = () => {
    dispatch(onDeleteFaqs({ id: faq.id }));
    deletePressed.current = true;
  };

  useEffect(() => {
    if (faqsHasError && !faqsLoading && deletePressed.current) {
      notification['error']({
        message: 'Error deleting faqs',
        description: `${faqsErrorMessage}`,
      });
      setVisible(false);
    } else if (!faqsLoading && deletePressed.current) {
      notification['success']({
        message: 'Faqs deleted',
        description: '',
      });
      deletePressed.current = false;
      setVisible(false);
    }
  }, [faqsErrorMessage, faqsHasError, faqsLoading, setVisible]);
  return (
    <Modal
      visible={visible}
      centered
      title="Delete Faqs"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          loading={faqsLoading}
          danger
          onClick={handleFaqsDeletion}
        >
          {faqsLoading ? 'Deleting...' : 'Delete'}
        </Button>,
      ]}
    >
      Are you sure you want to delete Faqs
      <Typography.Text strong> {faq.title}</Typography.Text>?
    </Modal>
  );
};
