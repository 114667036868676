import React, { useEffect, useRef } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  PageHeader,
  Row,
  Select,
  Upload,
} from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { routes } from '../../../constants/routes';
import { MaltaStates } from '../../../constants/location';
import { onAddStore } from '../../../store/store/storeSlice';
import { AddFaqsProps } from '../../../store/faqs/types';
import { onAddFaqs } from '../../../store/faqs/faqsSlice';

export const AddFaqs: React.FC = () => {
  const { faqsLoading, faqsHasError } = useAppSelector((state) => state.faqs);
  const dispatch = useAppDispatch();
  const formRef = React.createRef<FormInstance>();
  const history = useHistory();
  const submitPressed = useRef(false);

  const onFinish = (values: AddFaqsProps) => {
    submitPressed.current = true;
    dispatch(onAddFaqs(values));
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    if (faqsHasError && !faqsLoading && submitPressed.current) {
      submitPressed.current = false;
    } else if (!faqsLoading && submitPressed.current) {
      formRef.current && formRef.current.resetFields();
      submitPressed.current = false;
    }
  }, [faqsHasError, faqsLoading, formRef, submitPressed]);
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.replace(routes.faqs)}
        title="Add Faqs"
        style={{ padding: 0 }}
      />
      <Row>
        <Col
          span={24}
          style={{
            margin: '24px 0px',
            width: '100%',
          }}
        >
          <Card bordered={false}>
            <Form
              ref={formRef}
              name="basic"
              labelCol={{ flex: '110px' }}
              labelAlign="left"
              labelWrap
              wrapperCol={{ span: 12 }}
              autoComplete="off"
              size="middle"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  { required: true, message: 'Please input a faqs title' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                rules={[
                  { required: true, message: 'Please input faqs description' },
                ]}
              >
                <Input.TextArea rows={5} />
              </Form.Item>

              <Form.Item wrapperCol={{ span: 6 }}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: 110 }}
                  loading={faqsLoading && submitPressed.current}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
