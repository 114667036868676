export const MaltaStates = [
  {
    name: 'Attard',
  },
  {
    name: 'Balzan',
  },
  {
    name: 'Il-Birgu',
  },
  {
    name: 'Birkirkara',
  },
  {
    name: 'Birżebbuġa',
  },
  {
    name: 'Bormla',
  },
  {
    name: 'Dingli',
  },
  {
    name: 'Il-Fgura',
  },
  {
    name: 'Floriana',
  },
  {
    name: 'Il-Fontana',
  },
  {
    name: 'Għajnsielem',
  },
  {
    name: 'L-Għarb',
  },
  {
    name: 'Ħal Għargħur',
  },
  {
    name: 'L-Għasri',
  },
  {
    name: 'Ħal Għaxaq',
  },
  {
    name: 'Il-Gudja',
  },
  {
    name: 'Il-Gżira',
  },
  {
    name: 'Il-Ħamrun',
  },
  {
    name: 'L-Iklin',
  },
  {
    name: 'L-Imdina',
  },
  {
    name: 'L-Imġarr',
  },
  {
    name: 'L-Imqabba',
  },
  {
    name: 'L-Imsida',
  },
  {
    name: 'Mtarfa',
  },
  {
    name: 'Senglea',
  },
  {
    name: 'Il-Kalkara',
  },
  {
    name: 'Ta’ Kerċem',
  },
  {
    name: 'Kirkop',
  },
  {
    name: 'Lija',
  },
  {
    name: 'Luqa',
  },
  {
    name: 'Il-Marsa',
  },
  {
    name: 'Marsaskala',
  },
  {
    name: 'Marsaxlokk',
  },
  {
    name: 'Il-Mellieħa',
  },
  {
    name: 'Il-Mosta',
  },
  {
    name: 'Il-Munxar',
  },
  {
    name: 'In-Nadur',
  },
  {
    name: 'In-Naxxar',
  },
  {
    name: 'Paola',
  },
  {
    name: 'Pembroke',
  },
  {
    name: 'Tal-Pietà',
  },
  {
    name: 'Il-Qala',
  },
  {
    name: 'Qormi',
  },
  {
    name: 'Il-Qrendi',
  },
  {
    name: 'Ir-Rabat',
  },
  {
    name: 'Victoria',
  },
  {
    name: 'Safi',
  },
  {
    name: 'Saint John',
  },
  {
    name: 'Saint Julian',
  },
  {
    name: 'Saint Lawrence',
  },
  {
    name: 'Saint Lucia',
  },
  {
    name: 'Saint Paul’s Bay',
  },
  {
    name: 'Saint Venera',
  },
  {
    name: 'Sannat',
  },
  {
    name: 'Is-Siġġiewi',
  },
  {
    name: 'Tas-Sliema',
  },
  {
    name: 'Is-Swieqi',
  },
  {
    name: 'Tarxien',
  },
  {
    name: 'Ta’ Xbiex',
  },
  {
    name: 'Ix-Xagħra',
  },
  {
    name: 'Ix-Xewkija',
  },
  {
    name: 'Ix-Xgħajra',
  },
  {
    name: 'Ħaż-Żabbar',
  },
  {
    name: 'Ħaż-Żebbuġ',
  },
  {
    name: 'Iż-Żebbuġ',
  },
  {
    name: 'Iż-Żejtun',
  },
  {
    name: 'Iż-Żurrieq',
  },
  {
    name: 'Valletta',
  },
];
