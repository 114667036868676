import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { notification } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { get, patch, post } from '../../constants/api';
import { getToken } from '../auth/saga';
import {
  onAddStoryTeam,
  onEditStoryTeam,
  onUpdateStory,
  setStory,
  setStoryError,
  setStoryLoading,
} from './storySlice';
import { onGetStory } from './storySlice';
import { updateStoryState, AddTeamState } from './types';

export function* getStory(): SagaIterator<void> {
  try {
    yield put(
      setStoryLoading({
        storyLoading: true,
        storyMessage: '',
      })
    );
    const token = yield call(getToken);
    const { data, status }: AxiosResponse = yield call(
      get,
      `our-story`,
      undefined,
      token
    );

    if (status === 200) {
      yield put(setStory({ ourstory: data.data }));
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setStoryError({
          storyHasError: true,
          storyErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setStoryLoading({
        storyLoading: false,
        storyMessage: '',
      })
    );

    yield put(
      setStoryError({
        storyHasError: false,
        storyErrorMessage: ``,
      })
    );
  }
}

export function* updateStory({
  payload,
}: PayloadAction<updateStoryState>): SagaIterator<void> {
  try {
    yield put(
      setStoryLoading({
        storyLoading: true,
        storyMessage: '',
      })
    );
    delete payload.form.id;
    const token = yield call(getToken);
    const { status }: AxiosResponse = yield call(
      patch,
      `our-story`,
      payload.form,
      token
      // true
    );
    if (status === 200) {
      yield put(onGetStory());
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setStoryError({
          storyHasError: true,
          storyErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setStoryLoading({
        storyLoading: false,
        storyMessage: '',
      })
    );

    yield put(
      setStoryError({
        storyHasError: false,
        storyErrorMessage: ``,
      })
    );
  }
}

export function* addStoryTeam({
  payload,
}: PayloadAction<AddTeamState>): SagaIterator<void> {
  try {
    yield put(
      setStoryLoading({
        storyLoading: true,
        storyMessage: '',
      })
    );
    const form = new FormData();
    form.append('name', payload.name);
    form.append('description', payload.description);
    form.append('quote', payload.quote);
    form.append('image', payload.image[0].originFileObj);

    if (payload.overlay) {
      form.append('overlay', payload.overlay[0].originFileObj);
    }

    const token = yield call(getToken);
    const { status }: AxiosResponse = yield call(
      post,
      `our-story/team`,
      form,
      token,
      true
    );
    if (status === 200) {
      yield put(onGetStory());
      notification['success']({
        message: 'New Team Added successfully',
        description: '',
      });
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setStoryError({
          storyHasError: true,
          storyErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setStoryLoading({
        storyLoading: false,
        storyMessage: '',
      })
    );

    yield put(
      setStoryError({
        storyHasError: false,
        storyErrorMessage: ``,
      })
    );
  }
}

export function* editStoryTeam({
  payload,
}: PayloadAction<AddTeamState>): SagaIterator<void> {
  try {
    yield put(
      setStoryLoading({
        storyLoading: true,
        storyMessage: '',
      })
    );

    const form = new FormData();

    form.append('key', `${payload.key}`);
    form.append('name', payload.name);
    form.append('description', payload.description);
    form.append('quote', payload.quote);

    if (Array.isArray(payload.image)) {
      form.append('image', payload.image[0].originFileObj);
    }

    if (payload.overlay) {
      if (Array.isArray(payload.overlay)) {
        form.append('overlay', payload.overlay[0].originFileObj);
      }
    } else {
      form.append('overlay', payload.overlay);
    }

    const token = yield call(getToken);
    const { status }: AxiosResponse = yield call(
      patch,
      `our-story/team`,
      form,
      token,
      true
    );
    if (status === 200) {
      yield put(onGetStory());
      notification['success']({
        message: 'Team updated successfully',
        description: '',
      });
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setStoryError({
          storyHasError: true,
          storyErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setStoryLoading({
        storyLoading: false,
        storyMessage: '',
      })
    );

    yield put(
      setStoryError({
        storyHasError: false,
        storyErrorMessage: ``,
      })
    );
  }
}

export function* storySaga(): SagaIterator<void> {
  yield all([takeLatest(onGetStory, getStory)]);
  yield all([takeLatest(onUpdateStory, updateStory)]);
  yield all([takeLatest(onAddStoryTeam, addStoryTeam)]);
  yield all([takeLatest(onEditStoryTeam, editStoryTeam)]);
}
