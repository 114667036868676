import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Modal, Button, Typography, notification } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { onDeleteCategory } from '../../../store/category/categorySlice';

interface ModalProps {
  category: {
    name: string;
    id: string;
  };
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

export const DeleteCategoryModal: React.FC<ModalProps> = ({
  category,
  visible,
  setVisible,
}) => {
  const { categoryHasError, categoryErrorMessage, categoryLoading } =
    useAppSelector((state) => state.category);
  const dispatch = useAppDispatch();
  const deletePressed = useRef(false);

  const handleOk = () => {};

  const handleCancel = () => {
    setVisible(false);
  };

  const handleCategoryDeletion = () => {
    dispatch(onDeleteCategory({ id: category.id }));
    deletePressed.current = true;
  };

  useEffect(() => {
    if (categoryHasError && !categoryLoading && deletePressed.current) {
      notification['error']({
        message: 'Error deleting category',
        description: `${categoryErrorMessage}`,
      });
      setVisible(false);
    } else if (!categoryLoading && deletePressed.current) {
      notification['success']({
        message: 'Category deleted',
        description: '',
      });
      deletePressed.current = false;
      setVisible(false);
    }
  }, [categoryErrorMessage, categoryHasError, categoryLoading, setVisible]);
  return (
    <Modal
      visible={visible}
      centered
      title="Delete Category"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          loading={categoryLoading}
          danger
          onClick={handleCategoryDeletion}
        >
          {categoryLoading ? 'Deleting...' : 'Delete'}
        </Button>,
      ]}
    >
      Are you sure you want to delete category
      <Typography.Text strong>{category.name}</Typography.Text>?
    </Modal>
  );
};
