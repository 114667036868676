import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { menuItems } from '../../globalComponents/layout/Slider';
import { Link } from 'react-router-dom';

export const Dashboard: React.FC = () => {
  return (
    <Row gutter={[16, 24]} className="dashboard-container">
      {menuItems.map((menu, i) => (
        <Col key={i} className="gutter-row" sm={12} md={12} lg={6} span={24}>
          <Link to={menu.path}>
            <Card hoverable className="menu-card-items">
              <menu.icon />
              <Typography.Title level={5}>{menu.title}</Typography.Title>
            </Card>
          </Link>
        </Col>
      ))}
    </Row>
  );
};
