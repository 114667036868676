import React, { useEffect, useState } from 'react';
import {
  Route,
  RouteComponentProps,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { Table, Row, Col, Space, Button } from 'antd';
import { EditTwoTone, DeleteTwoTone, PlusOutlined } from '@ant-design/icons';
import { colors } from '../../constants/theme';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { onGetCategories } from '../../store/category/categorySlice';
import { DeleteCategoryModal } from './components/deleteModal';
import { routes } from '../../constants/routes';
import { AddCategory } from './components/addCategory';
import CategoryView from './components/view';
import { CategoryProps } from '../../store/category/types';
import EditCategory from './components/edit';

const { Column } = Table;

export const CategoryList: React.FC<RouteComponentProps> = (props) => {
  const { categories, categoryLoading } = useAppSelector(
    (state) => state.category
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    id: '',
    name: '',
  });
  const history = useHistory();

  const handleAddCategory = () => {
    history.push(routes.addCategories);
  };

  const handleDeleteButton = (key: any) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDeleteModal(true);
    setSelectedCategory({ id: key.id, name: key.name });
  };

  const handleEditButton = (key: any) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`${routes.editCategory}/${key.id}`);
  };

  const handleRowItemClick = (item: CategoryProps) => ({
    onClick: () => history.push(`${routes.categories}/view/${item.id}`),
  });

  useEffect(() => {
    if (!showDeleteModal) {
      setSelectedCategory({ id: '', name: '' });
    }
  }, [showDeleteModal]);
  return (
    <Row>
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <Col span={24} style={{ float: 'right' }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddCategory}
          >
            ADD CATEGORY
          </Button>
        </Col>
        <Col span={24}>
          <Table
            rowKey={'id'}
            loading={categoryLoading}
            dataSource={categories}
            pagination={{
              pageSize: 10,
              total: categories ? categories.length : 0,
              hideOnSinglePage: true,
            }}
            scroll={{ x: 'max-content' }}
            onRow={handleRowItemClick}
          >
            <Column
              title="ID"
              dataIndex="id"
              width={'20%'}
              align="left"
              sorter={(a: CategoryProps, b: CategoryProps) =>
                a.id.toLowerCase().localeCompare(b.id.toLowerCase())
              }
            />
            <Column
              title="Name"
              dataIndex="name"
              key="name"
              sorter={(a: CategoryProps, b: CategoryProps) =>
                a.name.toLowerCase().localeCompare(b.name.toLowerCase())
              }
              width={'30%'}
              align="left"
            />
            <Column
              title="Range"
              dataIndex="range"
              key="range"
              sorter={(a: CategoryProps, b: CategoryProps) =>
                a.range.toLowerCase().localeCompare(b.range.toLowerCase())
              }
              align="left"
            />
            <Column
              title="Action"
              key="action"
              width={'15%'}
              align="center"
              render={(text, record: any) => {
                return (
                  <div>
                    <Button
                      type="text"
                      style={{ padding: '0px 10px' }}
                      onClick={handleEditButton(record)}
                    >
                      <EditTwoTone twoToneColor={colors.black} />
                    </Button>
                    <Button
                      type="text"
                      style={{ padding: '0px 10px' }}
                      onClick={handleDeleteButton(record)}
                    >
                      <DeleteTwoTone twoToneColor={colors.red} />
                    </Button>
                  </div>
                );
              }}
            />
          </Table>
        </Col>
      </Space>
      <DeleteCategoryModal
        category={selectedCategory}
        visible={showDeleteModal}
        setVisible={setShowDeleteModal}
      />
    </Row>
  );
};

export const Category: React.FC = () => {
  const { auth } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { url } = useRouteMatch();

  useEffect(() => {
    if (auth) {
      dispatch(onGetCategories());
    }
  }, [dispatch, auth]);

  return (
    <div className="category-container">
      <Route exact path={routes.categories} component={CategoryList} />
      <Route exact path={`${url}/add`} component={AddCategory} />
      <Route path={`${url}/view/:categoryId`} component={CategoryView} />
      <Route path={`${url}/edit/:categoryId`} component={EditCategory} />
    </div>
  );
};
