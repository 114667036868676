/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LoadingState,
  ErrorState,
  ContactState,
  updateContactState,
} from './types';

const initialState = {
  contact: null,
} as ContactState;

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    onGetContacts: () => {},
    onUpdateContact: (state, action: PayloadAction<updateContactState>) => {},
    setContact: (state, action: PayloadAction<ContactState>) => {
      state.contact = action.payload.contact;
    },
    setContactLoading: (state, action: PayloadAction<LoadingState>) => {
      state.contactLoading = action.payload.contactLoading;
      state.contactMessage = action.payload.contactMessage;
    },
    setContactError: (state, action: PayloadAction<ErrorState>) => {
      state.contactHasError = action.payload.contactHasError;
      state.contactErrorMessage = action.payload.contactErrorMessage;
    },
  },
});

export const {
  onGetContacts,
  setContact,
  setContactLoading,
  setContactError,
  onUpdateContact,
} = contactSlice.actions;

export default contactSlice.reducer;
