import React, { useEffect, useRef, useState } from 'react';
import {
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  PageHeader,
  Row,
  Empty,
  Spin,
  Upload,
  Button,
  notification,
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { routes } from '../../../constants/routes';
import { HighlightsState, TeamState } from '../../../store/ourstory/types';
import { Location } from 'history';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import { onUpdateStory } from '../../../store/ourstory/storySlice';

interface LocationStateParams {
  key: number;
}

export const EditHighlight: React.FC = () => {
  const { ourstory, storyLoading, storyHasError, storyErrorMessage } =
    useAppSelector((state) => state.story);
  const [highlight, setHighlight] = useState<HighlightsState | undefined>();
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [key, setKey] = useState<number | undefined>();
  const formRef = React.createRef<FormInstance>();
  const history = useHistory();
  const location: Location<LocationStateParams> = useLocation();
  const submitPressed = useRef(false);
  const dispatch = useAppDispatch();

  const onFinish = (values: HighlightsState) => {
    submitPressed.current = true;
    const updatedHiglights = ourstory?.highlights.map((item, i) => {
      if (i === key) {
        return values;
      }
      return item;
    });

    const body = {
      highlights: updatedHiglights,
    };
    dispatch(onUpdateStory({ form: body }));
  };

  const getHighlightDetail = () => {
    const locationState = location.state;

    if (!has(locationState, 'key')) {
      history.replace(routes.ourStory);
    }
    if (locationState && has(locationState, 'key') && ourstory !== null) {
      const item = ourstory?.highlights.find(
        (i, ind) => ind === locationState.key
      );
      if (item === undefined) {
        setLoading(false);
        return;
      }
      setKey(locationState.key);
      setHighlight({
        ...item,
        note: item.note || '',
      });
    }
  };

  const onFormValuesChanges = (
    changedValues: unknown,
    allValues: HighlightsState
  ) => {
    const note = highlight?.note || '';
    const copyHighlight = { ...highlight, note };
    const areValuesEqual = isEqual(copyHighlight, allValues);
    setDisabled(areValuesEqual);
  };

  const handleCancelFormBtn = () => {
    setDisabled(true);
    formRef.current?.resetFields();
  };

  useEffect(getHighlightDetail, [location.state, ourstory]);

  useEffect(() => {
    if (highlight && loading) {
      setLoading(false);
    }
  }, [highlight, loading]);

  useEffect(() => {
    if (storyHasError && !storyLoading && submitPressed.current) {
      submitPressed.current = false;
      notification['error']({
        message: 'Error updating story',
        description: `${storyErrorMessage}`,
      });
    } else if (!storyLoading && submitPressed.current) {
      notification['success']({
        message: 'Our story higlights updated successfully',
        description: '',
      });
      submitPressed.current = false;
      setDisabled(true);
    }
  }, [storyHasError, storyLoading, formRef, submitPressed]);
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.replace(routes.ourStory)}
        title={highlight?.title}
        style={{ padding: 0 }}
      />
      <Row>
        <Col
          span={24}
          style={{
            margin: '24px 0px',
            width: '100%',
          }}
        >
          <Card bordered={false}>
            {loading ? (
              <Col className="category-spin" span={24}>
                <Spin />
              </Col>
            ) : !loading && ourstory && highlight ? (
              <Form
                ref={formRef}
                name="basic"
                layout="vertical"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
                onFinish={onFinish}
                onValuesChange={onFormValuesChanges}
                initialValues={highlight}
                autoComplete="off"
                size="middle"
              >
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please input a title' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    { required: true, message: 'Please input description' },
                  ]}
                >
                  <Input.TextArea rows={5} />
                </Form.Item>
                <Form.Item label="Note" name="note">
                  <Input />
                </Form.Item>

                <Form.Item wrapperCol={{ span: 6 }}>
                  <Row gutter={16} className="store-action-buttons">
                    <Col span={12}>
                      <Button
                        block
                        type="primary"
                        htmlType="submit"
                        disabled={disabled}
                        loading={storyLoading && submitPressed.current}
                      >
                        Submit
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button danger onClick={handleCancelFormBtn}>
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            ) : (
              <Empty />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};
