import React from 'react';
import { Layout, Menu } from 'antd';
import {
  UnorderedListOutlined,
  ShopOutlined,
  SwapOutlined,
  UserOutlined,
  FormOutlined,
  QuestionCircleOutlined,
  InfoCircleOutlined,
  CommentOutlined,
  SettingOutlined,
  DashboardOutlined,
  HomeOutlined,
  LineChartOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { routes } from '../../constants/routes';

const { Sider } = Layout;

interface SlideMenuProps {
  collapsed: boolean;
  setCollapsed: any;
}

export const menuItems = [
  {
    title: 'Category',
    icon: UnorderedListOutlined,
    path: routes.categories,
  },
  {
    title: 'Stores',
    icon: ShopOutlined,
    path: routes.stores,
  },
  {
    title: 'Transactions',
    icon: SwapOutlined,
    path: routes.transactions,
  },
  {
    title: 'Blogs',
    icon: FormOutlined,
    path: '/blogs',
  },
  {
    title: 'FAQs',
    icon: QuestionCircleOutlined,
    path: '/faqs',
  },
  {
    title: 'Our Story',
    icon: InfoCircleOutlined,
    path: '/our-story',
  },
  {
    title: 'Contact Us',
    icon: CommentOutlined,
    path: '/contact-us',
  },
  {
    title: 'Users',
    icon: UserOutlined,
    path: routes.users,
  },
  {
    title: 'Content',
    icon: HomeOutlined,
    path: routes.content,
  },
  {
    title: 'Seo',
    icon: LineChartOutlined,
    path: routes.seo,
  },
  {
    title: 'Admins',
    icon: SettingOutlined,
    path: routes.admins,
  },
];

export const SlideMenu: React.FC<SlideMenuProps> = ({
  collapsed,
  setCollapsed,
}) => {
  const location = useLocation();

  const checkCurrentPath = () => {
    const key = location.pathname.split('/');
    return [`/${key[1]}`];
  };

  const menu = menuItems.map((item, index) => (
    <Menu.Item key={item.path} icon={<item.icon />}>
      <Link to={item.path}>{item.title}</Link>
    </Menu.Item>
  ));

  return (
    <Sider
      theme="light"
      trigger={null}
      collapsible
      collapsed={collapsed}
      breakpoint="lg"
      // collapsedWidth="0"
      onBreakpoint={(broken) => {
        // console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        setCollapsed(collapsed);
      }}
    >
      <Menu theme="light" mode="inline" selectedKeys={checkCurrentPath()}>
        <Menu.Item key={'/'} icon={<DashboardOutlined />}>
          <Link to={'/'}>Dashboard</Link>
        </Menu.Item>
        {menu}
      </Menu>
    </Sider>
  );
};
