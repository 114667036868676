import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  FormInstance,
  Input,
  PageHeader,
  Row,
  Spin,
  Typography,
  Upload,
} from 'antd';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { all } from 'redux-saga/effects';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { onUpdateSeoContent } from '../../../store/seo/seoSlice';
import { SeoProps } from '../../../store/seo/types';

interface UserParamsProps {
  seoId: string;
  blogsId?: string;
}

interface FormProps {
  description: string;
  icon?: any;
  image: string;
  keywords: string;
  path: string;
  title: string;
  id?: string;
}

export const EditSeo: React.FC = () => {
  const { seo, seoLoading, seoErrorMessage } = useAppSelector(
    (state) => state.seo
  );
  const [seoItem, setSeo] = useState<SeoProps | undefined>();
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const dispatch = useAppDispatch();
  const formRef = React.createRef<FormInstance>();
  const params: UserParamsProps = useParams();
  const history = useHistory();
  const submitPressed = useRef(false);
  const location = useLocation();

  const onFinish = (values: SeoProps) => {
    submitPressed.current = true;
    dispatch(onUpdateSeoContent({ ...values, id: seoItem?.id || '' }));
  };

  const onFormValuesChanges = (
    changedValues: unknown,
    allValues: FormProps
  ) => {
    if (allValues?.icon?.length) {
      setDisabled(false);
      return;
    } else {
      const areValuesEqual = isEqual(
        omit(allValues, 'icon'),
        omit(seoItem, 'id', 'url')
      );
      setDisabled(areValuesEqual);
    }
    // const areValuesEqual = isEqual(hiwItem, allValues);
    // setDisabled(areValuesEqual);
  };

  const handleCancelFormBtn = () => {
    setDisabled(true);
    formRef.current?.resetFields();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    if (seo?.length) {
      const item = seo?.find((i) => {
        if (location.pathname === '/our-story') {
          return i.path === '/story';
        }

        if (params.seoId) {
          return i.id === params.seoId;
        }

        if (params.blogsId) {
          return i.blogId === params.blogsId;
        }
        return null;
      });

      if (item === undefined) {
        setLoading(false);
        return;
      }
      setSeo(item);
    }
  }, [seo, params.seoId]);

  useEffect(() => {
    if (seo && loading) {
      setLoading(false);
    }
  }, [seo, loading]);
  return (
    <div>
      {!location.pathname.includes('/our-story') && (
        <PageHeader
          className="site-page-header"
          onBack={() => history.goBack()}
          title={seoItem?.title}
          style={{ padding: 0 }}
        />
      )}
      <Card bordered={false}>
        {loading ? (
          <Col className="category-spin" span={24}>
            <Spin />
          </Col>
        ) : !loading && seo && seoItem ? (
          <Form
            ref={formRef}
            name="basic"
            layout="vertical"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            onValuesChange={onFormValuesChanges}
            initialValues={seoItem}
            autoComplete="off"
            size="middle"
          >
            <Form.Item label="Path" name="path">
              <Input disabled />
            </Form.Item>

            <Form.Item label="Title" name="title">
              <Input />
            </Form.Item>

            <Form.Item label="Description" name="description">
              <Input.TextArea rows={4} maxLength={160} showCount />
            </Form.Item>

            <Form.Item label="Keywords" name="keywords">
              <Input.TextArea rows={4} maxLength={160} showCount />
            </Form.Item>

            <Typography.Text strong>
              Note: You can change the url or upload an image.
            </Typography.Text>
            <Form.Item label="Image" name="image">
              <Input />
            </Form.Item>

            <Form.Item name="icon" getValueFromEvent={normFile}>
              <Upload name="icon" listType="picture" maxCount={1}>
                <Button>Upload Image</Button>
              </Upload>
            </Form.Item>

            <Form.Item wrapperCol={{ span: 12 }}>
              <Row gutter={16} className="store-action-buttons">
                <Col span={12}>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    disabled={disabled}
                    loading={seoLoading && submitPressed.current}
                  >
                    Submit
                  </Button>
                </Col>
                <Col span={12}>
                  <Button danger onClick={handleCancelFormBtn}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        ) : (
          <Empty />
        )}
      </Card>
    </div>
  );
};
