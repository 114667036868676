import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ContentProps,
  ContentState,
  ErrorState,
  HowItWorksProps,
  LoadingState,
} from './types';

const initialState = {
  content: null,
  contentLoading: false,
  contentMessage: '',
  contentHasError: false,
  contentErrorMessage: '',
} as ContentState;

export const contactSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    onGetContents: () => {},
    onUpdateContent: (state, action: PayloadAction<ContentProps>) => {},
    onUpdateHowItWorks: (state, action: PayloadAction<HowItWorksProps>) => {},
    setContent: (state, action: PayloadAction<ContentState>) => {
      state.content = action.payload.content;
    },
    setContentLoading: (state, action: PayloadAction<LoadingState>) => {
      state.contentLoading = action.payload.contentLoading;
      state.contentMessage = action.payload.contentMessage;
    },
    setContentError: (state, action: PayloadAction<ErrorState>) => {
      state.contentHasError = action.payload.contentHasError;
      state.contentErrorMessage = action.payload.contentErrorMessage;
    },
  },
});

export const {
  onGetContents,
  onUpdateContent,
  setContent,
  setContentLoading,
  setContentError,
  onUpdateHowItWorks,
} = contactSlice.actions;

export default contactSlice.reducer;
