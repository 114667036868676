import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  PageHeader,
  Row,
  notification,
} from 'antd';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { routes } from '../../../constants/routes';
import { HighlightsState, StoryProps } from '../../../store/ourstory/types';
import { onGetStory, onUpdateStory } from '../../../store/ourstory/storySlice';

export const AddHighlights: React.FC = () => {
  const { ourstory, storyLoading, storyHasError, storyErrorMessage } =
    useAppSelector((state) => state.story);
  const { auth } = useAppSelector((state) => state.auth);
  const [highlight, setHighlight] = useState<Array<HighlightsState>>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const formRef = React.createRef<FormInstance>();
  const history = useHistory();
  const submitPressed = useRef(false);

  const onFinish = (values: HighlightsState) => {
    submitPressed.current = true;
    highlight.push(values);
    const body = {
      ...ourstory,
      highlights: highlight,
    };
    dispatch(onUpdateStory({ form: body }));
  };

  const getHighlight = () => {
    const highlight: Array<HighlightsState> = [];
    ourstory?.highlights.map((val) => {
      highlight.push(val);
    });
    setHighlight(highlight);
  };

  const onFinishFailed = (errorInfo: unknown) => {
    // console.log('Failed:', errorInfo);
  };

  useEffect(getHighlight, [ourstory]);
  useEffect(() => {
    if (auth) {
      dispatch(onGetStory());
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [dispatch, auth]);
  useEffect(() => {
    if (storyHasError && !storyLoading && submitPressed.current) {
      formRef.current && formRef.current.resetFields();
      notification['error']({
        message: 'Error adding Highlights',
        description: `${storyErrorMessage}`,
      });
      submitPressed.current = false;
    } else if (!storyLoading && submitPressed.current) {
      formRef.current && formRef.current.resetFields();
      notification['success']({
        message: 'Story highlights added successfully',
        description: '',
      });
      submitPressed.current = false;
    }
  }, [storyHasError, storyLoading, formRef, submitPressed]);
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.replace(routes.ourStory)}
        title="Add Highlights"
        style={{ padding: 0 }}
      />
      <Row>
        <Col
          span={24}
          style={{
            margin: '24px 0px',
            width: '100%',
          }}
        >
          <Card bordered={false}>
            <Form
              ref={formRef}
              name="basic"
              labelCol={{ flex: '110px' }}
              labelAlign="left"
              labelWrap
              wrapperCol={{ span: 12 }}
              autoComplete="off"
              size="middle"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: 'Please input a title' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                rules={[
                  { required: true, message: 'Please input description' },
                ]}
              >
                <Input.TextArea rows={5} />
              </Form.Item>

              <Form.Item label="Note" name="note">
                <Input />
              </Form.Item>

              <Form.Item wrapperCol={{ span: 6 }}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: 110 }}
                  loading={storyLoading && submitPressed.current}
                >
                  Add
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
