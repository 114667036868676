/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  FormInstance,
  Input,
  PageHeader,
  Row,
  Spin,
  Upload,
} from 'antd';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { CategoryProps } from '../../../store/category/types';
import { onEditCategory } from '../../../store/category/categorySlice';

interface categoryState {
  name: string;
  range: string;
  file: any;
}

interface CategoryParamsProps {
  categoryId: string;
}

const EditCategory: React.FC = () => {
  const {
    categories,
    categoryHasError,
    categoryErrorMessage,
    categoryLoading,
  } = useAppSelector((state) => state.category);
  const [category, setCategory] = useState<CategoryProps | undefined>();
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const params: CategoryParamsProps = useParams();
  const history = useHistory();
  const formRef = React.createRef<FormInstance>();
  const submitPressed = useRef(false);
  const dispatch = useAppDispatch();

  const onFinish = (values: categoryState) => {
    submitPressed.current = true;
    const formValues = new FormData();

    if (values.name !== category?.name) {
      formValues.append('name', values.name);
    }

    if (values.range !== category?.range) {
      formValues.append('range', values.range);
    }

    if (!isEqual(values.file, category?.image)) {
      formValues.append('range', values.file[0]?.originFileObj);
    }

    dispatch(onEditCategory({ form: formValues, id: params.categoryId }));
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };

  const getCategory = () => {
    if (params.categoryId) {
      const item = categories.find((i) => i.id === params.categoryId);
      setCategory(item);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFormValuesChanges = (
    changedValues: any,
    allValues: categoryState
  ) => {
    const { name, range, file } = allValues;
    if (name === '' || range === '' || isEmpty(file)) {
      setDisabled(true);
      return;
    }

    if (
      name === category?.name &&
      range === category.range &&
      isEqual(file, category.image)
    ) {
      setDisabled(true);
      return;
    }

    setDisabled(false);
  };

  useEffect(() => {
    if (categoryHasError && !categoryLoading && submitPressed.current) {
      submitPressed.current = false;
    } else if (!categoryLoading && submitPressed.current) {
      submitPressed.current = false;
      setDisabled(true);
    }
  }, [submitPressed, categoryLoading]);
  useEffect(getCategory, [categories, params.categoryId]);

  return (
    <Row>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={category?.name || ` `}
        style={{ padding: 0 }}
      />
      <Col
        span={24}
        style={{
          margin: '24px 0px',
          width: '100%',
        }}
      >
        <Card bordered={false}>
          {loading ? (
            <Col className="category-spin" span={24}>
              <Spin />
            </Col>
          ) : !loading && category ? (
            <Form
              ref={formRef}
              name="basic"
              layout="vertical"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={onFormValuesChanges}
              initialValues={{
                name: category?.name,
                range: category?.range,
                file: category?.image,
              }}
              autoComplete="off"
              size="middle"
            >
              <Form.Item
                label="Category Name"
                name="name"
                rules={[
                  { required: true, message: 'Please input a category name' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Category Range"
                name="range"
                rules={[
                  { required: true, message: 'Please input a category range' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="file"
                label="Icon"
                // valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: true, message: 'Please upload an image' }]}
              >
                <Upload
                  name="logo"
                  listType="picture"
                  maxCount={1}
                  defaultFileList={[
                    {
                      uid: '-1',
                      name: category.image.filename,
                      url: category.image.link,
                    },
                  ]}
                >
                  <Button>Upload Image</Button>
                </Upload>
              </Form.Item>

              <Form.Item wrapperCol={{ span: 6 }}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  disabled={disabled}
                  loading={categoryLoading && submitPressed.current}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <Empty />
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default EditCategory;
