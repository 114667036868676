import React, { FormEvent, FormEventHandler, useEffect, useState } from 'react';
import { Col, Input, Form, Image, Button, notification } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { onLogin, setLoginError } from '../../store/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import LOGO from '../../assets/images/monie_text_logo.png';

interface Credentials {
  email: string;
  password: string;
}

const CredentialState: Credentials = {
  email: '',
  password: '',
};

export const Auth: React.FC = () => {
  const [errorMess, setErrorMessage] = useState<string | undefined>('');
  const [credential, setCredentials] = useState<Credentials>(CredentialState);
  const { auth, user, loginLoading, loginHasError, loginErrorMessage } =
    useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const authErrorMesage = 'Login Failed';

  const handleFieldChange =
    (field: keyof Credentials): FormEventHandler =>
    (e: FormEvent<HTMLInputElement>): void => {
      setErrorMessage('');
      setCredentials({
        ...credential,
        [field]: e.currentTarget.value,
      });
    };

  const onClickSubmit = () => {
    const { email, password } = credential;
    if (email !== '' && password !== '') {
      dispatch(onLogin(credential));
      return;
    }
    setErrorMessage('Please enter credentials');
  };

  const checkAuth = () => {
    if (loginHasError && !loginLoading) {
      notification['error']({
        message: authErrorMesage,
        description: loginErrorMessage,
      });
      dispatch(
        setLoginError({
          loginHasError: false,
          loginErrorMessage: '',
        })
      );
    } else if (!loginLoading && !loginHasError && auth && user) {
      notification['success']({
        message: 'Logged in successfullly',
        description: '',
      });
      // history.push('/')
    }
  };

  useEffect(checkAuth, [
    dispatch,
    history,
    loginHasError,
    loginLoading,
    loginErrorMessage,
    auth,
  ]);
  return (
    <div className="login-wrapper">
      <div className="login-container">
        <div className="login-header">
          <h1>Welcome</h1>
          <Image src={LOGO} width={150} preview={false} />
        </div>

        <Form onFinish={onClickSubmit}>
          <Col span={24} className="input-field-container">
            <Input
              size="large"
              placeholder="Email"
              className="auth-input-field"
              onChange={handleFieldChange('email')}
            />
          </Col>
          <Col span={24} className="input-field-container">
            <Input.Password
              size="large"
              placeholder="Password"
              className="auth-input-field"
              onChange={handleFieldChange('password')}
            />
          </Col>
          <span className="error-text">{errorMess}</span>
          <div className="p-grid p-justify-end button-container">
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={loginLoading}
              size="large"
              icon={<LoginOutlined />}
            >
              LOGIN
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
