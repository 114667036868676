import React, { useState } from 'react';
import { Layout, Row, Col } from 'antd';
import { HeaderComponent } from './Header';
import { SlideMenu } from './Slider';

const { Content } = Layout;

export const DashboardLayout: React.FC = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout>
      <HeaderComponent collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout className="site-layout">
        <SlideMenu collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content
          className="site-layout-background"
          style={{
            margin: '24px 16px',
            padding: 24
          }}
        >
          <Row>
            <Col span={24}>
             {children}
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  )
}