import ReduxSagaFirebase from 'redux-saga-firebase';
import { MonieFirebase } from '../constants/firebase';
import { authSaga } from './auth/saga';
import { categorySaga } from './category/saga';
import { storeSaga } from './store/saga';
import { faqsSaga } from './faqs/saga';
import { blogsSaga } from './blogs/saga';
import { userSaga } from './user/saga';
import { transactionSaga } from './transactions/saga';
import { contactSaga } from './contact/saga';
import { storySaga } from './ourstory/saga';
import { contentSaga } from './content/saga';
import { seoSaga } from './seo/saga';
// * import sagas here

export const MONIEFirebaseSaga = new ReduxSagaFirebase(MonieFirebase);

// * include imported sagas here
export const sagas = [
  authSaga,
  categorySaga,
  storeSaga,
  faqsSaga,
  blogsSaga,
  userSaga,
  transactionSaga,
  contactSaga,
  storySaga,
  contentSaga,
  seoSaga,
];
