import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  PageHeader,
  Row,
  Select,
  Spin,
} from 'antd';
import { FacebookFilled, InstagramFilled } from '@ant-design/icons';
import isEqual from 'lodash/isEqual';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  onGetContacts,
  onUpdateContact,
} from '../../store/contact/contactSlice';

interface ContactState {
  address: string;
  email: Array<string>;
  phone: Array<string>;
  reachOut: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  youtube: string;
  instagram?: string;
}
export const ContactUs: React.FC = () => {
  const { contact, contactLoading, contactHasError } = useAppSelector(
    (state) => state.contact
  );
  const { auth } = useAppSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const dispatch = useAppDispatch();

  const formRef = React.createRef<FormInstance>();
  const submitPressed = useRef(false);

  const onFinish = (values: ContactState) => {
    submitPressed.current = true;
    const body = {
      address: values.address,
      email: values.email,
      phone: values.phone,
      reachOut: values.reachOut,
      socials: {
        facebook: values.facebook,
        instagram: values.instagram,
      },
    };
    dispatch(onUpdateContact({ form: body }));
  };

  const onFinishFailed = (errorInfo: unknown) => {
    // console.log('Failed:', errorInfo);
  };

  const onClickCancel = () => {
    formRef.current?.resetFields();
    setDisabled(true);
  };

  const onFormValuesChanges = (
    changedValues: unknown,
    allValues: ContactState
  ) => {
    const copyContact = {
      address: contact?.address,
      email: contact?.email,
      phone: contact?.phone,
      reachOut: contact?.reachOut,
      facebook: contact?.socials.facebook,
      instagram: contact?.socials.instagram,
    };

    const areValuesEqual = isEqual(copyContact, allValues);
    setDisabled(areValuesEqual);
  };

  useEffect(() => {
    if (auth) {
      dispatch(onGetContacts());
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [dispatch, auth]);

  useEffect(() => {
    if (contactHasError && !contactLoading && submitPressed.current) {
      submitPressed.current = false;
    } else if (!contactLoading && submitPressed.current) {
      submitPressed.current = false;
      setDisabled(true);
    }
  }, [submitPressed, contactLoading]);
  return (
    <div className="category-container">
      <PageHeader
        className="site-page-header"
        // onBack={() => history.replace(routes.faqs)}
        title="Update Contact Us"
        style={{ padding: 0 }}
      />
      <Row>
        <Col
          span={24}
          style={{
            margin: '24px 0px',
            width: '100%',
          }}
        >
          <Card bordered={false}>
            {loading ? (
              <Col className="category-spin" span={24}>
                <Spin />
              </Col>
            ) : (
              <Form
                ref={formRef}
                name="basic"
                layout="vertical"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onValuesChange={onFormValuesChanges}
                initialValues={{
                  address: contact?.address,
                  reachOut: contact?.reachOut,
                  email: contact?.email,
                  phone: contact?.phone,
                  facebook: contact?.socials.facebook,
                  instagram: contact?.socials.instagram,
                }}
                autoComplete="off"
                size="middle"
              >
                <Form.Item label="Address" name="address">
                  <Input />
                </Form.Item>
                <Form.Item label="React Out" name="reachOut">
                  <Input.TextArea style={{ height: '100px' }} />
                </Form.Item>
                <Form.Item label="Email" name="email">
                  <Select
                    mode="tags"
                    size="middle"
                    placeholder="Please select"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item label="Phone" name="phone">
                  <Select
                    mode="tags"
                    size="middle"
                    placeholder="Please select"
                    style={{ width: '100%' }}
                  />
                </Form.Item>

                <div>Update Social Links:</div>

                <Form.Item label="Facebook" name="facebook">
                  <Input prefix={<FacebookFilled />} />
                </Form.Item>

                <Form.Item label="Instagram" name="instagram">
                  <Input prefix={<InstagramFilled />} />
                </Form.Item>

                <Form.Item wrapperCol={{ span: 12 }}>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      // style={{ marginLeft: 110 }}
                      disabled={disabled}
                      loading={contactLoading && submitPressed.current}
                    >
                      Submit
                    </Button>
                    <Button
                      block
                      type="default"
                      style={{ marginLeft: 110 }}
                      disabled={disabled}
                      onClick={onClickCancel}
                    >
                      Discard Changes
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};
