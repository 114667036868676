import { PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from '..';

import { deleted, get, patch, post } from '../../constants/api';
import { getToken } from '../auth/saga';
import { AdminProps } from './types';
import {
  onDeleteUser,
  onGetUsers,
  onGetAdmins,
  setUsers,
  setUsersError,
  setUsersLoading,
  setAdmins,
  onAddAdmin,
  onEditAdmin,
} from './userSlice';

function* getAllUsers(): SagaIterator<void> {
  try {
    yield put(
      setUsersLoading({
        usersLoading: true,
        usersMessage: '',
      })
    );

    const token = yield call(getToken);
    const { data, status }: AxiosResponse = yield call(
      get,
      `allUsers`,
      undefined,
      token
    );

    if (status === 200) {
      yield put(setUsers({ users: data.data }));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setUsersError({
          usersHasError: true,
          usersErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setUsersLoading({
        usersLoading: false,
        usersMessage: '',
      })
    );

    yield put(
      setUsersError({
        usersHasError: false,
        usersErrorMessage: '',
      })
    );
  }
}

function* deleteUser({
  payload,
}: PayloadAction<{ id: string, isAdmin?: boolean }>): SagaIterator<void> {
  try {
    const storeState = (state: RootState) => state.user;
    const { usersHasError } = yield select(storeState);
    yield put(
      setUsersLoading({
        usersLoading: true,
        usersMessage: '',
      })
    );

    if (usersHasError) {
      yield put(
        setUsersError({
          usersHasError: false,
          usersErrorMessage: '',
        })
      );
    }

    const token = yield call(getToken);
    const { status }: AxiosResponse = yield call(
      deleted,
      `allUsers/${payload.id}`,
      undefined,
      token
    );

    if (status === 200) {
      yield put(
        setUsersLoading({
          usersLoading: false,
          usersMessage: '',
        })
      );
      notification['success']({
        message: `${payload.isAdmin ? 'Admin' : 'User'} has been deleted`,
        description: '',
      });
      if (payload.isAdmin) {
        yield put(onGetAdmins());
      } else {
        yield put(onGetUsers());
      }
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setUsersError({
          usersHasError: true,
          usersErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setUsersLoading({
        usersLoading: false,
        usersMessage: '',
      })
    );
  }
}

function* getAllAdmins(): SagaIterator<void> {
  try {
    yield put(
      setUsersLoading({
        usersLoading: true,
        usersMessage: '',
      })
    );

    const token = yield call(getToken);
    const { data, status }: AxiosResponse = yield call(
      get,
      `allAdmins`,
      undefined,
      token
    );

    if (status === 200) {
      yield put(setAdmins({ admins: data.data }));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setUsersError({
          usersHasError: true,
          usersErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setUsersLoading({
        usersLoading: false,
        usersMessage: '',
      })
    );

    yield put(
      setUsersError({
        usersHasError: false,
        usersErrorMessage: '',
      })
    );
  }
}
function* addAdmin({ payload }: PayloadAction<AdminProps>): SagaIterator<void> {
  try {
    yield put(
      setUsersLoading({
        usersLoading: true,
        usersMessage: '',
      })
    );

    const token = yield call(getToken);
    const credentials = { email: payload.email, password: payload.password };

    const signup: AxiosResponse = yield call(
      post,
      'signup',
      credentials,
      token
    );

    if (signup.status === 200) {
      const signupId: string = signup.data.data.uuid;
      const body = {
        ...payload,
        userid: signupId,
      };
      const addAdminUser: AxiosResponse = yield call(
        post,
        'admin',
        body,
        token
      );
      if (addAdminUser.status === 200) {
        yield put(onGetAdmins());
        notification['success']({
          message: 'Admin added successfully',
          description: '',
        });
      }
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setUsersError({
          usersHasError: true,
          usersErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
      notification['error']({
        message: 'Error adding Admin',
        description: `${error.code || ''} ${error.message}`,
      });
    }
  } finally {
    yield put(
      setUsersLoading({
        usersLoading: false,
        usersMessage: '',
      })
    );

    yield put(
      setUsersError({
        usersHasError: false,
        usersErrorMessage: '',
      })
    );
  }
}

function* editAdmin({
  payload,
}: PayloadAction<AdminProps>): SagaIterator<void> {
  try {
    yield put(
      setUsersLoading({
        usersLoading: true,
        usersMessage: '',
      })
    );

    const token = yield call(getToken);
    const addAdminUser: AxiosResponse = yield call(
      patch,
      `admin/${payload.id}`,
      payload,
      token
    );
    if (addAdminUser.status === 200) {
      yield put(onGetAdmins());
      notification['success']({
        message: 'Admin updated successfully',
        description: '',
      });
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setUsersError({
          usersHasError: true,
          usersErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
      notification['error']({
        message: 'Error updating Admin',
        description: `${error.code || ''} ${error.message}`,
      });
    }
  } finally {
    yield put(
      setUsersLoading({
        usersLoading: false,
        usersMessage: '',
      })
    );

    yield put(
      setUsersError({
        usersHasError: false,
        usersErrorMessage: '',
      })
    );
  }
}

export function* userSaga(): SagaIterator<void> {
  yield all([takeLatest(onGetUsers, getAllUsers)]);
  yield all([takeLatest(onGetAdmins, getAllAdmins)]);
  yield all([takeLatest(onAddAdmin, addAdmin)]);
  yield all([takeLatest(onEditAdmin, editAdmin)]);
  yield all([takeLatest(onDeleteUser, deleteUser)]);
}
