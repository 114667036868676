import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { App } from './component/App';
import { store, history, saga, persistor } from './store';
import { sagas } from './store/root-saga';

import * as serviceWorker from './serviceWorker';
import './assets/index.scss';
import { PersistGate } from 'redux-persist/integration/react';

sagas.forEach(s => saga.run(s));

ReactDOM.render(
  <Provider store={store}>
     <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('app')
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/sw.js')
      .then(
        (registration: ServiceWorkerRegistration): void => {
          console.log('Worker registration successful', registration.scope);
        },
        (e: Error): void => {
          console.log('Worker registration failed', e);
        }
      )
      .catch((e: Error): void => {
        console.log(e);
      });
  });
} else {
  console.log('Service Worker is not supported by browser.');
}

// TODO: enable for PWA
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// import * as serviceWorker from './serviceWorker';
serviceWorker.register();