import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminProps, ErrorState, LoadingState, UsersState } from './types';

const initialState = {
  users: [],
  admins: [],
  usersLoading: false,
  usersMessage: '',
  usersHasError: false,
  usersErrorMessage: '',
} as UsersState;

export const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    onGetUsers: () => { },
    onGetAdmins: () => { },
    onAddAdmin: (state, action: PayloadAction<AdminProps>) => {},
    onEditAdmin: (state, action: PayloadAction<AdminProps>) => { },
    onDeleteUser: (
      state,
      action: PayloadAction<{ id: string; isAdmin?: boolean }>
    ) => {},
    onDeleteAdmin: (state, action: PayloadAction<{ id: string }>) => { },
    setUsers: (state, action: PayloadAction<UsersState>) => {
      state.users = action.payload.users;
    },
    setAdmins: (state, action: PayloadAction<UsersState>) => {
      state.admins = action.payload.admins;
    },
    setUsersLoading: (state, action: PayloadAction<LoadingState>) => {
      state.usersLoading = action.payload.usersLoading;
      state.usersMessage = action.payload.usersMessage;
    },
    setUsersError: (state, action: PayloadAction<ErrorState>) => {
      state.usersHasError = action.payload.usersHasError;
      state.usersErrorMessage = action.payload.usersErrorMessage;
    },
  },
});

export const {
  onGetUsers,
  setUsers,
  setAdmins,
  onGetAdmins,
  onAddAdmin,
  onEditAdmin,
  onDeleteAdmin,
  onDeleteUser,
  setUsersLoading,
  setUsersError,
} = userReducer.actions;
export default userReducer.reducer;
