import React, { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Form,
  PageHeader,
  Row,
  Input,
  Spin,
  Empty,
  Image,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { BlogProps } from '../../../store/blogs/types';
import { useAppSelector } from '../../../store/hooks';
import { CategoryProps } from '../../../store/category/types';
import FallbackImg from '../../../assets/images/fallback-img.png';
import moment from 'moment';

interface BlogParamsProps {
  blogsId: string;
}

const BlogView: React.FC = () => {
  const { blogs } = useAppSelector((state) => state.blogs);
  const [blog, setBlogs] = useState<BlogProps | undefined>();
  const [loading, setLoading] = useState(true);
  const params: BlogParamsProps = useParams();
  const history = useHistory();
  const formRef: any = React.createRef();

  const getBlog = () => {
    if (params.blogsId) {
      const item = blogs.find((i) => i.id === params.blogsId);
      setBlogs(item);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  useEffect(getBlog, [blogs, params.blogsId]);
  const description: any = blog?.description;
  useEffect(() => {
    if (blog && !loading) {
      formRef.current.setFieldsValue({
        title: blog?.title,
        description: description,
        date: moment(blog.date),
        file: blog?.image,
      });
    }
  }, [blog, formRef, loading]);
  return (
    <Row>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={blog?.title || ` `}
        style={{ padding: 0 }}
      />
      <Col
        span={24}
        style={{
          margin: '24px 0px',
          width: '100%',
        }}
      >
        <Card bordered={false}>
          {loading ? (
            <Col className="category-spin" span={24}>
              <Spin />
            </Col>
          ) : !loading && blog ? (
            <Form
              ref={formRef}
              name="basic"
              layout="vertical"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              initialValues={{
                title: blog?.title,
                description: description,
                date: moment(blog.date),
                file: blog?.image,
              }}
              autoComplete="off"
              size="middle"
            >
              <Form.Item label="Blog Title" name="title">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Blog Description" name="description">
                {/* <Input.TextArea disabled style={{ height: '200px' }} /> */}
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </Form.Item>
              <Form.Item label="Blog Date" name="date">
                <Input disabled />
              </Form.Item>

              <Form.Item name="file" label="Icon">
                <div style={{ padding: 10 }}>
                  <Image
                    width={70}
                    height={70}
                    src={blog.image.link}
                    fallback={FallbackImg}
                  />
                </div>
              </Form.Item>
            </Form>
          ) : (
            <Empty />
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default BlogView;
