import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { get } from '../../constants/api';
import { getToken } from '../auth/saga';
import {
  onGetTransactions,
  setTransactionError,
  setTransactionLoading,
  setTransactions,
} from './slice';

function* getAllTransactions({
  payload,
}: PayloadAction<{ date: string | undefined }>): SagaIterator<void> {
  try {
    yield put(
      setTransactionLoading({
        transactionsLoading: true,
        transactionsMessage: '',
      })
    );

    const withDate = payload.date ? { date: payload.date } : undefined;
    const token = yield call(getToken);
    const { data, status }: AxiosResponse = yield call(
      get,
      `allTransactions`,
      withDate,
      token
    );

    if (status === 200) {
      yield put(setTransactions({ transactions: data.data }));
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setTransactionError({
          transactionsHasError: true,
          transactionsErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setTransactionLoading({
        transactionsLoading: false,
        transactionsMessage: '',
      })
    );

    yield put(
      setTransactionError({
        transactionsHasError: false,
        transactionsErrorMessage: '',
      })
    );
  }
}

export function* transactionSaga(): SagaIterator<void> {
  yield all([takeLatest(onGetTransactions, getAllTransactions)]);
}
