/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  categoryState,
  editCategoryState,
  CategoryState,
  deleteProps,
  ErrorState,
  LoadingState,
} from './types';

const initialState = {
  categories: [],
  categoryLoading: false,
  categoryMessage: '',
  categoryHasError: false,
  categoryErrorMessage: '',
} as CategoryState;

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    onGetCategories: () => {},
    onAddCategories: (state, action: PayloadAction<categoryState>) => {},
    onEditCategory: (state, action: PayloadAction<editCategoryState>) => {},
    onDeleteCategory: (state, action: PayloadAction<deleteProps>) => {},
    setCategory: (state, action: PayloadAction<CategoryState>) => {
      state.categories = action.payload.categories;
    },
    setCategoryLoading: (state, action: PayloadAction<LoadingState>) => {
      state.categoryLoading = action.payload.categoryLoading;
      state.categoryMessage = action.payload.categoryMessage;
    },
    setCategoryError: (state, action: PayloadAction<ErrorState>) => {
      state.categoryHasError = action.payload.categoryHasError;
      state.categoryErrorMessage = action.payload.categoryErrorMessage;
    },
  },
});

export const {
  setCategory,
  setCategoryLoading,
  setCategoryError,
  onGetCategories,
  onAddCategories,
  onEditCategory,
  onDeleteCategory,
} = categorySlice.actions;
export default categorySlice.reducer;
