/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LoadingState,
  ErrorState,
  FaqsState,
  AddFaqsProps,
  EditFaqsState,
  DeleteProps,
} from './types';

const initialState = {
  faqs: [],
  faqsLoading: false,
  faqsMessage: '',
  faqsHasError: false,
  faqsErrorMessage: '',
} as FaqsState;

export const faqsSlice = createSlice({
  name: 'faqs',
  initialState,
  reducers: {
    onGetFaqs: () => {},
    onAddFaqs: (state, action: PayloadAction<AddFaqsProps>) => {},
    onEditFaqs: (state, action: PayloadAction<EditFaqsState>) => {},
    onDeleteFaqs: (state, action: PayloadAction<DeleteProps>) => {},
    setFaqs: (state, action: PayloadAction<FaqsState>) => {
      state.faqs = action.payload.faqs;
    },
    setFaqsLoading: (state, action: PayloadAction<LoadingState>) => {
      state.faqsLoading = action.payload.faqsLoading;
      state.faqsMessage = action.payload.faqsMessage;
    },
    setFaqsError: (state, action: PayloadAction<ErrorState>) => {
      state.faqsHasError = action.payload.faqsHasError;
      state.faqsErrorMessage = action.payload.faqsErrorMessage;
    },
  },
});

export const {
  onGetFaqs,
  onAddFaqs,
  setFaqs,
  setFaqsLoading,
  setFaqsError,
  onEditFaqs,
  onDeleteFaqs,
} = faqsSlice.actions;

export default faqsSlice.reducer;
