import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Modal, Button, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  onUpdateStoreVisibility,
  setStoreError,
} from '../../../store/store/storeSlice';

interface ModalProps {
  store: {
    name: string;
    id: string;
    isStoreVisible?: boolean;
  };
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

export const StoreVisibilityModal: React.FC<ModalProps> = ({
  store,
  visible,
  setVisible,
}) => {
  const { storeHasError, storeLoading } = useAppSelector(
    (state) => state.store
  );
  const dispatch = useAppDispatch();
  const buttonPressed = useRef(false);

  const handleOk = () => {};

  const handleCancel = () => {
    setVisible(false);
  };

  const handleCategoryDeletion = () => {
    dispatch(
      onUpdateStoreVisibility({
        id: store.id,
        isvisible: !store.isStoreVisible,
      })
    );
    buttonPressed.current = true;
  };

  useEffect(() => {
    if (storeHasError && !storeLoading && buttonPressed.current) {
      dispatch(
        setStoreError({
          storeHasError: false,
          storeErrorMessage: '',
        })
      );
      buttonPressed.current = false;
    } else if (!storeLoading && buttonPressed.current) {
      buttonPressed.current = false;
      setVisible(false);
    }
  }, [storeHasError, storeLoading, buttonPressed, setVisible]);
  return (
    <Modal
      visible={visible}
      centered
      title="Store Visibility"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          loading={storeLoading}
          danger
          onClick={handleCategoryDeletion}
        >
          {storeLoading ? 'Updating...' : 'Update'}
        </Button>,
      ]}
    >
      Are you sure you want to
      <Typography.Text strong>{` ${
        store.isStoreVisible === false ? 'show' : 'hide'
      } ${store.name} `}</Typography.Text>
      store ?
    </Modal>
  );
};
