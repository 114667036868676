import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LoadingState,
  ErrorState,
  BlogsState,
  blogState,
  editBlogState,
  DeleteProps
} from './types';

const initialState = {
  blogs: [],
  blogsLoading: false,
  blogsMessage: '',
  blogsHasError: false,
  blogsErrorMessage: '',
} as BlogsState;

export const blogsSlice = createSlice({
  name: 'blogs',
  initialState,
  reducers: {
    onGetBlogs: () => {},
    onAddBlogs: (state, action: PayloadAction<blogState>) => {},
    onEditBlogs: (state, action: PayloadAction<editBlogState>) => {},
    onDeleteBlogs: (state, action: PayloadAction<DeleteProps>) => {},
    setBlogs: (state, action: PayloadAction<BlogsState>) => {
      state.blogs = action.payload.blogs;
    },
    setBlogLoading: (state, action: PayloadAction<LoadingState>) => {
      state.blogsLoading = action.payload.blogsLoading;
      state.blogsMessage = action.payload.blogsMessage;
    },
    setBlogError: (state, action: PayloadAction<ErrorState>) => {
      state.blogsHasError = action.payload.blogsHasError;
      state.blogsErrorMessage = action.payload.blogsErrorMessage;
    },
  },
});

export const {
  onGetBlogs,
  setBlogs,
  onAddBlogs,
  onEditBlogs,
  onDeleteBlogs,
  setBlogLoading,
  setBlogError,
} = blogsSlice.actions;

export default blogsSlice.reducer;
