import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorState, LoadingState, TransactionState } from './types';

const initialState = {
  transactions: {},
  transactionsLoading: false,
  transactionsMessage: '',
  transactionsHasError: false,
  transactionsErrorMessage: '',
} as TransactionState;

export const transactionReducer = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    onGetTransactions: (
      state,
      action: PayloadAction<{ date: string | undefined }>
    ) => {},
    setTransactions: (state, action: PayloadAction<TransactionState>) => {
      state.transactions = action.payload.transactions;
    },
    setTransactionLoading: (state, action: PayloadAction<LoadingState>) => {
      state.transactionsLoading = action.payload.transactionsLoading;
      state.transactionsMessage = action.payload.transactionsMessage;
    },
    setTransactionError: (state, action: PayloadAction<ErrorState>) => {
      state.transactionsHasError = action.payload.transactionsHasError;
      state.transactionsErrorMessage = action.payload.transactionsErrorMessage;
    },
  },
});

export const {
  onGetTransactions,
  setTransactions,
  setTransactionLoading,
  setTransactionError,
} = transactionReducer.actions;
export default transactionReducer.reducer;
