import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  PageHeader,
  Row,
  Spin,
  Typography,
  Upload,
  FormInstance,
} from 'antd';
import { Location } from 'history';
import { useHistory, useLocation } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import has from 'lodash/has';
import { routes } from '../../../constants/routes';
import { HIW_KEY, HowItWorksProps } from '../../../store/content/types';
import { onUpdateHowItWorks } from '../../../store/content/contentSlice';

interface LocationStateParams {
  key: HIW_KEY;
}

export const EditHowItWorks: React.FC = () => {
  const { content, contentLoading, contentHasError } = useAppSelector(
    (state) => state.content
  );
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [key, setKey] = useState<HIW_KEY>();
  const location: Location<LocationStateParams> = useLocation();
  const [hiwItem, setHiwItem] = useState<HowItWorksProps>();
  const history = useHistory();
  const formRef = React.createRef<FormInstance>();
  const submitPressed = useRef(false);
  const dispatch = useAppDispatch();

  const onFinish = (values: HowItWorksProps) => {
    submitPressed.current = true;
    dispatch(onUpdateHowItWorks({ ...values, key: key }));
  };

  const onFormValuesChanges = (
    changedValues: unknown,
    allValues: HowItWorksProps
  ) => {
    const areValuesEqual = isEqual(hiwItem, allValues);
    setDisabled(areValuesEqual);
  };

  const getHowItWorksDetail = () => {
    const locationState = location.state;

    if (!has(locationState, 'key')) {
      history.replace(routes.content);
    }

    if (locationState && has(locationState, 'key') && content !== null) {
      // const item = ourstory?.team.find((i, ind) => ind === locationState.key);
      const item = content[locationState.key];
      if (item === undefined) {
        setLoading(false);
        return;
      }
      setKey(locationState.key);
      setHiwItem(item);
    }
  };

  const handleCancelFormBtn = () => {
    setDisabled(true);
    formRef.current?.resetFields();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    if (hiwItem && loading) {
      setLoading(false);
    }
  }, [hiwItem, loading]);

  useEffect(getHowItWorksDetail, [location.state, content]);

  useEffect(() => {
    if (contentHasError && !contentLoading && submitPressed.current) {
      submitPressed.current = false;
    } else if (!contentLoading && submitPressed.current) {
      submitPressed.current = false;
      setDisabled(true);
    }
  }, [submitPressed, contentLoading]);
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={hiwItem?.title}
        style={{ padding: 0 }}
      />
      <Row>
        <Col
          span={24}
          style={{
            margin: '24px 0px',
            width: '100%',
          }}
        >
          <Card bordered={false}>
            {loading ? (
              <Col className="category-spin" span={24}>
                <Spin />
              </Col>
            ) : !loading && content && hiwItem ? (
              <Form
                ref={formRef}
                name="basic"
                layout="vertical"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
                onFinish={onFinish}
                onValuesChange={onFormValuesChanges}
                initialValues={hiwItem}
                autoComplete="off"
                size="middle"
              >
                <Form.Item label="Title" name="title">
                  <Input />
                </Form.Item>

                <Form.Item label="Description" name="description">
                  <Input />
                </Form.Item>

                <Form.Item label="Content">
                  <Form.Item name="content" noStyle>
                    <Input.TextArea style={{ height: '100px' }} />
                  </Form.Item>
                  <Typography.Text type="secondary">
                    Note: Press enter to create a new line
                  </Typography.Text>
                </Form.Item>

                <Form.Item
                  name="icon"
                  label="Icon"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    name="icon"
                    listType="picture"
                    maxCount={1}
                    defaultFileList={[
                      {
                        uid: '-1',
                        name: hiwItem.key || '',
                        url: hiwItem.icon,
                      },
                    ]}
                  >
                    <Button>Upload Image</Button>
                  </Upload>
                </Form.Item>

                <Form.Item wrapperCol={{ span: 12 }}>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      // style={{ marginLeft: 110 }}
                      disabled={disabled}
                      loading={contentLoading && submitPressed.current}
                    >
                      Submit
                    </Button>
                    <Button
                      block
                      type="default"
                      style={{ marginLeft: 110 }}
                      disabled={disabled}
                      onClick={handleCancelFormBtn}
                    >
                      Discard Changes
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            ) : (
              <Empty />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};
