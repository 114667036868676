import React, { useEffect, useState } from 'react';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Col, Row, Table } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { routes } from '../../constants/routes';
import { onGetUsers } from '../../store/user/userSlice';
import { UserProps } from '../../store/user/types';
import { colors } from '../../constants/theme';
import { UserView } from './components/viewUser';
import { onGetCategories } from '../../store/category/categorySlice';
import { DeleteUserModal } from './components/deleteUser';
import ExportButton from '../../globalComponents/ExportButton';
import moment from 'moment';

const ExportTableHeader = [
  { label: 'ID', key: 'id' },
  { label: 'Name', key: 'name' },
  { label: 'Email', key: 'email' },
  { label: 'Date of Birth', key: 'dob' },
  { label: 'Address', key: 'address' },
  { label: 'Natonality', key: 'nationality' },
  // { label: 'Promotional Subscribed', key: 'promotionalSubscription' },
  { label: 'Payment', key: 'payment.method' },
  { label: 'QR', key: 'uuid' },
  // { label: 'Date created', key: 'timeadded' },
];

export const UserLists: React.FC = () => {
  const { users, usersLoading } = useAppSelector((state) => state.user);
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    id: '',
    name: '',
  });

  const handleRowItemClick = (item: UserProps) => ({
    onClick: () => history.push(`${routes.viewUsers}/${item.id}`),
  });

  const sortByDate = (a: moment.Moment, b: moment.Moment) => {
    return moment(a).unix() - moment(b).unix();
  };

  const handleDeleteButton = (key: any) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDeleteModal(true);
    setSelectedUser({ id: key.userid, name: key.name });
  };

  useEffect(() => {
    if (!showDeleteModal) {
      setSelectedUser({ id: '', name: '' });
    }
  }, [showDeleteModal]);
  return (
    <Row gutter={[0, 16]}>
      <Col span={24} className="table-export-button">
        <ExportButton
          data={users || []}
          filename={'Monie Users.csv'}
          headers={ExportTableHeader}
        />
      </Col>
      <Col span={24}>
        <Table
          rowKey={'userid'}
          loading={usersLoading}
          dataSource={users}
          pagination={{
            pageSize: 10,
            total: users ? users.length : 0,
            hideOnSinglePage: true,
          }}
          scroll={{ x: 'max-content' }}
          onRow={handleRowItemClick}
        >
          <Table.Column
            title="ID"
            dataIndex="id"
            width={'20%'}
            align="left"
            sorter={(a: UserProps, b: UserProps) =>
              a.id.toLowerCase().localeCompare(b.id.toLowerCase())
            }
          />
          <Table.Column
            title="Name"
            dataIndex="name"
            align="left"
            sorter={(a: UserProps, b: UserProps) =>
              a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            }
          />

          <Table.Column
            title="Email"
            dataIndex="email"
            align="left"
            sorter={(a: UserProps, b: UserProps) =>
              a.email.toLowerCase().localeCompare(b.email.toLowerCase())
            }
          />

          <Table.Column
            title="Address"
            dataIndex="address"
            align="left"
            sorter={(a: UserProps, b: UserProps) =>
              a.address.toLowerCase().localeCompare(b.address.toLowerCase())
            }
          />

          <Table.Column
            title="Nationality"
            dataIndex="nationality"
            align="left"
            sorter={(a: UserProps, b: UserProps) =>
              a.nationality
                .toLowerCase()
                .localeCompare(b.nationality.toLowerCase())
            }
          />
          {/* 
          <Table.Column
            title="Date Created"
            dataIndex={'timeadded'}
            align="left"
            render={(value) => (
              <div>{moment(value).format('MM/DD/YYYY HH:mm')}</div>
            )}
            sorter={(a: UserProps, b: UserProps) =>
              sortByDate(a.timeadded, b.timeadded)
            }
          /> */}

          <Table.Column
            title="Action"
            key="action"
            width={'15%'}
            align="center"
            render={(text, record: any) => {
              return (
                <div>
                  <Button
                    type="text"
                    style={{ padding: '0px 10px' }}
                    onClick={handleDeleteButton(record)}
                  >
                    <DeleteTwoTone twoToneColor={colors.red} />
                  </Button>
                </div>
              );
            }}
          />
        </Table>
      </Col>
      <DeleteUserModal
        user={selectedUser}
        visible={showDeleteModal}
        setVisible={setShowDeleteModal}
      />
    </Row>
  );
};

export const User: React.FC = () => {
  const { auth } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { url } = useRouteMatch();

  useEffect(() => {
    if (auth) {
      dispatch(onGetUsers());
      dispatch(onGetCategories());
    }
  }, [dispatch, auth]);
  return (
    <div className="store-container">
      <Route exact path={routes.users} component={UserLists} />
      <Route path={`${url}/view/:userid`} component={UserView} />
    </div>
  );
};
