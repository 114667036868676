import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Card,
  Col,
  Form,
  Input,
  FormInstance,
  PageHeader,
  Row,
  Spin,
  Empty,
  Select,
  Checkbox,
  InputNumber,
  Typography,
  TimePicker,
  DatePicker,
  Button,
  Upload,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import pullAll from 'lodash/pullAll';
import omit from 'lodash/omit';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { CashbackFormItem, FormItemAddButton } from './addStore';
import { EditFormState } from './types';
import { MaltaStates } from '../../../constants/location';
import { onEditStore } from '../../../store/store/storeSlice';

interface StoreParamsProps {
  storeid: string;
}

const weekday = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const maltaStatesComponent = () =>
  MaltaStates.map((item) => (
    <Select.Option key={item.name} value={item.name}>
      {item.name}
    </Select.Option>
  ));

export const EditStore: React.FC = () => {
  const { stores, storeLoading, storeHasError } = useAppSelector(
    (state) => state.store
  );
  const { categories, categoryLoading } = useAppSelector(
    (state) => state.category
  );
  const [store, setStore] = useState<EditFormState | undefined>();
  const [weekdaysSelected, setWeekdaysSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const params: StoreParamsProps = useParams();
  const history = useHistory();
  const formRef = React.createRef<FormInstance>();
  const submitPressed = useRef(false);
  const dispatch = useAppDispatch();

  const onFinish = (values: EditFormState) => {
    if (values.timeRules) {
      values.rules['time'] = values.timeRules.map((i) => ({
        cashback: i.cashback,
        from: moment(i.range[0]).format('HH:mm'),
        to: moment(i.range[1]).format('HH:mm'),
      }));
    }

    if (values.dateRules) {
      const dateValues = values.dateRules.map((i) => ({
        cashback: i.cashback,
        from: moment(i.range[0]).toDate(),
        to: moment(i.range[1]).toDate(),
      }));
      values.rules.dates = {
        range: dateValues,
      };
    }

    if (values.weekRules) {
      values.rules.dates = {
        ...values.rules.dates,
        week: values.weekRules,
      };
    }

    // Append Price Rule
    if (values.priceRules) {
      values.rules['price'] = values.priceRules;
    }

    const finalValues = omit(values, [
      'timeRules',
      'dateRules',
      'weekRules',
      'priceRules',
    ]);

    submitPressed.current = true;
    dispatch(onEditStore({ ...finalValues, storeid: params.storeid }));
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    // console.log('Failed:', errorInfo);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFormValuesChanges = (
    changedValues: unknown,
    allValues: EditFormState
  ) => {
    const storeCopy = { ...store };
    const valuesToCompare = { ...allValues };

    delete storeCopy.dateRules;
    delete storeCopy.timeRules;
    delete storeCopy.storeid;
    delete valuesToCompare.dateRules;
    delete valuesToCompare.timeRules;

    let compareDateRules = true;
    if (allValues?.dateRules?.length !== store?.dateRules?.length) {
      compareDateRules = false;
    } else {
      // CHECK iF THERE ARE CHANGES IN DATE RULES
      const storeDateRules = store?.dateRules;
      if (
        allValues?.dateRules &&
        allValues?.dateRules.length &&
        storeDateRules
      ) {
        const changeDateRules = allValues?.dateRules;
        for (let i = 0; i < allValues?.dateRules.length; i++) {
          const changedValuesFrom = moment(
            changeDateRules[i].range[0]
          ).format();
          const changedValuesTo = moment(changeDateRules[i].range[1]).format();

          const storeDateValueFrom =
            storeDateRules && moment(storeDateRules[i].range[0]).format();
          const storeDateValueTo =
            storeDateRules && moment(storeDateRules[i].range[1]).format();

          const areDateValuesEqual = isEqual(
            [changedValuesFrom, changedValuesTo, changeDateRules[i].cashback],
            [storeDateValueFrom, storeDateValueTo, storeDateRules[i].cashback]
          );
          if (!areDateValuesEqual) {
            compareDateRules = false;
            break;
          }
        }
      }
    }

    let compareTimeRules = true;
    if (allValues?.timeRules?.length !== store?.timeRules?.length) {
      compareTimeRules = false;
    } else {
      // CHECK iF THERE ARE CHANGES IN TIME RULES
      const storeTimeRules = store?.timeRules;
      const changeTimeRules = allValues?.timeRules;
      if (
        allValues?.timeRules &&
        allValues?.timeRules.length &&
        storeTimeRules &&
        changeTimeRules
      ) {
        for (let i = 0; i < changeTimeRules.length; i++) {
          const timeFormat = 'HH:mm';
          const changedTimeValuesFrom = moment(
            changeTimeRules[i].range[0]
          ).format(timeFormat);
          const changedTimeValuesTo = moment(
            changeTimeRules[i].range[1]
          ).format(timeFormat);

          const storeTimeValueFrom = moment(storeTimeRules[i].range[0]).format(
            timeFormat
          );
          const storeTimeValueTo = moment(storeTimeRules[i].range[1]).format(
            timeFormat
          );

          const areTimeValuesEqual = isEqual(
            [
              changedTimeValuesFrom,
              changedTimeValuesTo,
              changeTimeRules[i].cashback,
            ],
            [storeTimeValueFrom, storeTimeValueTo, storeTimeRules[i].cashback]
          );

          if (!areTimeValuesEqual) {
            compareTimeRules = false;
            break;
          }
        }
      }
    }

    const areValuesEqual = isEqual(valuesToCompare, storeCopy);
    setDisabled(areValuesEqual && compareDateRules && compareTimeRules);
  };

  const weekdaySelect = useCallback(() => {
    const filteredOptions = weekday.filter(
      (o) => !weekdaysSelected.includes(o.toLowerCase())
    );

    return filteredOptions.map((item) => (
      <Select.Option key={item} value={item.toLowerCase()}>
        {item.toLowerCase()}
      </Select.Option>
    ));
  }, [weekdaysSelected]);

  const handleWeekdaySelection = (selectedItems: string) => {
    setWeekdaysSelected(weekdaysSelected.concat(selectedItems));
  };

  const handleWeekdayDeselection = (deselectedItem: string) => {
    const selectedCopy = [...weekdaysSelected];
    const index = selectedCopy.indexOf(deselectedItem);
    if (index > -1) {
      selectedCopy.splice(index, 1);
      setWeekdaysSelected(selectedCopy);
    }
  };

  const handleWeekFieldRemove =
    (name: string, remove: (index: number | number[]) => void) => () => {
      const fields: EditFormState = formRef.current?.getFieldsValue();

      const selectedCopy = [...weekdaysSelected];
      const fieldValue = fields.weekRules && fields?.weekRules[Number(name)];
      const removedItems = pullAll(selectedCopy, fieldValue?.day);

      setWeekdaysSelected(removedItems);
      remove(Number(name));
    };

  const handleCancelFormBtn = () => {
    const item = stores.find((i) => i.storeid === params.storeid);
    const storeSelectedWeek: string[] = [];
    item?.rules.dates?.week?.map((d) => {
      storeSelectedWeek.push(...d.day);
    });
    setWeekdaysSelected(storeSelectedWeek);
    setDisabled(true);
    formRef.current?.resetFields();
  };

  const getStoreitem = () => {
    if (params.storeid && stores.length) {
      const item = stores.find((i) => i.storeid === params.storeid);

      if (item === undefined) {
        setLoading(false);
        return;
      }

      const timeRule = item?.rules.time
        ? item.rules.time.map((t) => ({
            range: [moment(t.from, 'HH:mm'), moment(t.to, 'HH:mm')],
            cashback: 10,
          }))
        : [];

      const dateRules = item?.rules?.dates?.range
        ? item?.rules.dates?.range.map((d) => ({
            range: [moment(d.from), moment(d.to)],
            cashback: 10,
          }))
        : [];

      const storeSelectedWeek: string[] = [];
      item?.rules.dates?.week?.map((d) => {
        storeSelectedWeek.push(...d.day);
      });

      setWeekdaysSelected(storeSelectedWeek);

      setStore({
        storeid: params.storeid,
        name: item?.name,
        description: item?.description,
        file: item?.image,
        category: item?.category.map((i) => i.id),
        location: item?.location,
        website: item?.website || '',
        isFeatured: item?.isFeatured,
        rules: {
          // ...item?.rules,
          cashback: item?.rules.cashback,
        },
        priceRules: item?.rules.price || [],
        timeRules: timeRule,
        dateRules: dateRules,
        weekRules: item?.rules.dates?.week ? item?.rules.dates.week : [],
      });
    }
  };

  useEffect(getStoreitem, [stores, storeLoading, params.storeid]);

  useEffect(() => {
    if (store && loading) {
      setLoading(false);
    }
  }, [store, loading]);

  useEffect(() => {
    if (storeHasError && !storeLoading && submitPressed.current) {
      submitPressed.current = false;
    } else if (!storeLoading && submitPressed.current) {
      submitPressed.current = false;
      setDisabled(true);
    }
  }, [storeHasError, storeLoading, formRef, submitPressed]);

  return (
    <Row className="store-wrapper">
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={store?.name || 'Back'}
        style={{ padding: 0 }}
      />
      <Col span={24} className="edit-store-container">
        <Card bordered={false}>
          {loading ? (
            <Col className="category-spin" span={24}>
              <Spin />
            </Col>
          ) : !loading && stores && store ? (
            <Form
              ref={formRef}
              name="basic"
              layout="vertical"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={onFormValuesChanges}
              initialValues={store}
              autoComplete="off"
              size="middle"
            >
              <Form.Item
                name="file"
                label="Icon"
                // valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: true, message: 'Please upload an image' }]}
              >
                <Upload
                  name="logo"
                  listType="picture"
                  maxCount={1}
                  defaultFileList={[
                    {
                      uid: '-1',
                      name: store?.file?.filename || '',
                      url: store.file?.link,
                    },
                  ]}
                >
                  <Button>Upload Image</Button>
                </Upload>
              </Form.Item>

              <Form.Item
                label="Store Name"
                name="name"
                rules={[
                  { required: true, message: 'Please input a store name' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                rules={[
                  { required: true, message: 'Please input store description' },
                ]}
              >
                <Input.TextArea rows={2} />
              </Form.Item>

              <Form.Item
                label="Category"
                name="category"
                rules={[
                  { required: true, message: 'Please select store category' },
                ]}
              >
                <Select mode="multiple">
                  {categories.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Locaton"
                name="location"
                rules={[
                  { required: true, message: 'Please select store location' },
                ]}
              >
                <Select>{maltaStatesComponent()}</Select>
              </Form.Item>

              <Form.Item label="Website" name="website">
                <Input />
              </Form.Item>

              <Form.Item
                label="Featured"
                name="isFeatured"
                valuePropName="checked"
              >
                <Checkbox>Is featured Store?</Checkbox>
              </Form.Item>

              <Form.Item
                label="Cashback"
                name={['rules', 'cashback']}
                normalize={(value) => (value === null ? 0 : value)}
              >
                <InputNumber addonAfter="%" min={0} max={100} />
              </Form.Item>

              {store.priceRules && (
                <div className="store-cashback-rules">
                  <Typography.Text strong>Minimum Price</Typography.Text>
                  <Form.List name="priceRules">
                    {(fields, { add, remove }) => (
                      <div className="store-price-form-item">
                        {fields.map(({ key, name, ...restField }) => (
                          <Row
                            key={key}
                            gutter={8}
                            className="price-form-item-wrapper"
                            style={{}}
                          >
                            <Col span={5}>
                              <Form.Item
                                {...restField}
                                name={[name, 'min']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Missing minimum price',
                                  },
                                ]}
                                wrapperCol={{ span: 24 }}
                              >
                                <InputNumber
                                  addonAfter="€"
                                  min={1}
                                  placeholder="Min Price"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <CashbackFormItem
                                name={name}
                                restField={restField}
                              />
                            </Col>
                            <Col span={2} className="store-remove-icon">
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </Col>
                          </Row>
                        ))}
                        <FormItemAddButton label="Add Price Field" add={add} />
                      </div>
                    )}
                  </Form.List>
                </div>
              )}

              {store.timeRules && (
                <div className="store-cashback-rules">
                  <Typography.Text strong>Time Range</Typography.Text>
                  <Form.List name="timeRules">
                    {(fields, { add, remove }) => (
                      <div className="store-price-form-item">
                        {fields.map(({ key, name, ...restField }) => (
                          <Row
                            key={key}
                            gutter={8}
                            className="price-form-item-wrapper"
                            style={{}}
                          >
                            <Col span={5}>
                              <Form.Item
                                {...restField}
                                name={[name, 'range']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Missing time range',
                                  },
                                ]}
                                wrapperCol={{ span: 24 }}
                              >
                                <TimePicker.RangePicker
                                  style={{ width: '100%' }}
                                  format={'HH:mm'}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <CashbackFormItem
                                name={name}
                                restField={restField}
                              />
                            </Col>
                            <Col span={2} className="store-remove-icon">
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </Col>
                          </Row>
                        ))}
                        <FormItemAddButton label="Add Time Field" add={add} />
                      </div>
                    )}
                  </Form.List>
                </div>
              )}

              {store.dateRules && (
                <div className="store-cashback-rules">
                  <Typography.Text strong>Date</Typography.Text>
                  <Form.List name="dateRules">
                    {(fields, { add, remove }) => (
                      <div className="store-price-form-item">
                        {fields.map(({ key, name, ...restField }) => (
                          <Row
                            key={key}
                            gutter={8}
                            className="price-form-item-wrapper"
                          >
                            <Col span={8}>
                              <Form.Item
                                {...restField}
                                name={[name, 'range']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Missing date range',
                                  },
                                ]}
                                wrapperCol={{ span: 24 }}
                              >
                                <DatePicker.RangePicker
                                  style={{ width: '100%' }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <CashbackFormItem
                                name={name}
                                restField={restField}
                              />
                            </Col>
                            <Col span={2} className="store-remove-icon">
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </Col>
                          </Row>
                        ))}
                        <FormItemAddButton label="Add Date Field" add={add} />
                      </div>
                    )}
                  </Form.List>
                </div>
              )}

              {store.weekRules && (
                <div className="store-cashback-rules">
                  <Typography.Text strong>Week</Typography.Text>
                  <Form.List name="weekRules">
                    {(fields, { add, remove }) => (
                      <div className="store-price-form-item">
                        {fields.map(({ key, name, ...restField }) => (
                          <Row
                            key={key}
                            gutter={8}
                            className="price-form-item-wrapper"
                            style={{}}
                          >
                            <Col span={8}>
                              <Form.Item
                                {...restField}
                                name={[name, 'day']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please select day/s',
                                  },
                                ]}
                                wrapperCol={{ span: 24 }}
                              >
                                <Select
                                  mode="multiple"
                                  allowClear
                                  style={{ width: '100%' }}
                                  placeholder="Please select"
                                  onSelect={handleWeekdaySelection}
                                  onDeselect={handleWeekdayDeselection}
                                >
                                  {weekdaySelect()}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <CashbackFormItem
                                name={name}
                                restField={restField}
                              />
                            </Col>
                            <Col span={2} className="store-remove-icon">
                              <MinusCircleOutlined
                                onClick={handleWeekFieldRemove(
                                  `${name}`,
                                  remove
                                )}
                              />
                            </Col>
                          </Row>
                        ))}
                        {weekdaysSelected.length < 7 && (
                          <FormItemAddButton label="Add Week Field" add={add} />
                        )}
                      </div>
                    )}
                  </Form.List>
                </div>
              )}

              <Form.Item wrapperCol={{ span: 12 }}>
                <Row gutter={16} className="store-action-buttons">
                  <Col span={12}>
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      disabled={disabled}
                      loading={storeLoading && submitPressed.current}
                    >
                      Submit
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button danger onClick={handleCancelFormBtn}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          ) : (
            <Empty />
          )}
        </Card>
      </Col>
    </Row>
  );
};
