import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from './auth/authSlice';
import categoryReducer from './category/categorySlice';
import storeReducer from './store/storeSlice';
import faqsReducer from './faqs/faqsSlice';
import blogsReducer from './blogs/blogSlice';
import contactReducer from './contact/contactSlice';
import storyReducer from './ourstory/storySlice';
import { History } from 'history';
import userReducer from './user/userSlice';
import transactionReducer from './transactions/slice';
import contentReducer from './content/contentSlice';
import seoReducer from './seo/seoSlice';

export const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    category: categoryReducer,
    store: storeReducer,
    faqs: faqsReducer,
    blogs: blogsReducer,
    user: userReducer,
    transaction: transactionReducer,
    contact: contactReducer,
    story: storyReducer,
    content: contentReducer,
    seo: seoReducer,
  });

export type RootReducer = ReturnType<typeof createRootReducer>;
