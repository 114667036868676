import React, { useContext, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { HowItWorks } from './components/HowItWorks';
import { Route, useRouteMatch } from 'react-router-dom';
import { routes } from '../../constants/routes';
import { EditHowItWorks } from './components/EditHowItWorks';
import { Homepage } from './components/Homepage';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { onGetContents } from '../../store/content/contentSlice';

interface HompepageContext {
  homepageContextValue: {
    currentTab: string;
    loading: boolean;
  };
  onHomepageContextChange: (item: any) => void;
}

const defaultValue = {
  homepageContextValue: {
    currentTab: '1',
    loading: true,
  },
  onHomepageContextChange: () => {},
};

export const MyContext = React.createContext<HompepageContext>(defaultValue);

export const ContentTab: React.FC = () => {
  const { homepageContextValue, onHomepageContextChange } =
    useContext(MyContext);

  const onChange = (key: string) => {
    onHomepageContextChange(key);
  };

  return (
    <Tabs
      defaultActiveKey="1"
      onChange={onChange}
      activeKey={homepageContextValue.currentTab}
    >
      <Tabs.TabPane tab="Homepage & Stores" key="1">
        <Homepage />
      </Tabs.TabPane>
      <Tabs.TabPane tab="How It Works" key="2">
        <HowItWorks />
      </Tabs.TabPane>
    </Tabs>
  );
};

export const ContentMain = () => {
  const { idToken } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [homepageContextValue, setHomepageValue] = useState({
    loading: true,
    currentTab: '1',
  });
  const { url } = useRouteMatch();

  const onHomepageContextChange = (key: string) => {
    setHomepageValue({ ...homepageContextValue, currentTab: key });
  };

  useEffect(() => {
    if (idToken) {
      dispatch(onGetContents());
    }
    setTimeout(() => {
      setHomepageValue({ ...homepageContextValue, loading: false });
    }, 1500);
  }, [idToken]);

  return (
    <MyContext.Provider
      value={{ homepageContextValue, onHomepageContextChange }}
    >
      <div className="category-container">
        <Route exact path={routes.content} component={ContentTab} />
        <Route path={`${url}/edit`} component={EditHowItWorks} />
      </div>
    </MyContext.Provider>
  );
};
