/* eslint-disable @typescript-eslint/no-explicit-any */
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import notification from 'antd/lib/notification';
import {
  onAddStore,
  onDeleteStore,
  onEditStore,
  onGetStores,
  onUpdateStoreVisibility,
  setStore,
  setStoreError,
  setStoreLoading,
} from './storeSlice';
import { RootState } from '..';
import { deleted, get, patch, post } from '../../constants/api';
import { getToken } from '../auth/saga';
import { addStoreState } from './types';
import { onGetCategories } from '../category/categorySlice';
import {
  EditFormState,
  StoreVisibility,
} from '../../component/store/components/types';

function* getStores(): SagaIterator<void> {
  try {
    yield put(
      setStoreLoading({
        storeLoading: true,
        storeMessage: '',
      })
    );

    const [token, repos] = yield all([call(getToken), put(onGetCategories())]);
    const { data, status }: AxiosResponse = yield call(
      get,
      `allStores`,
      undefined,
      token
    );

    if (status === 200) {
      yield put(setStore({ stores: data.data }));
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setStoreError({
          storeHasError: true,
          storeErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setStoreLoading({
        storeLoading: false,
        storeMessage: '',
      })
    );

    yield put(
      setStoreError({
        storeHasError: false,
        storeErrorMessage: ``,
      })
    );
  }
}

function* addStore({
  payload,
}: PayloadAction<addStoreState>): SagaIterator<void> {
  try {
    yield put(
      setStoreLoading({
        storeLoading: true,
        storeMessage: '',
      })
    );
    const credentials = { email: payload.email, password: payload.password };
    const token = yield call(getToken);
    const signup: AxiosResponse = yield call(
      post,
      'signup',
      credentials,
      token
    );

    if (signup.status === 200) {
      const signupId: string = signup.data.data.uuid;

      const form = new FormData();
      form.append('id', signupId);
      form.append('file', payload.file[0].originFileObj);
      const upload: AxiosResponse = yield call(post, 'upload', form, token);

      if (upload.status === 200) {
        const body = {
          ...payload,
          storeid: signupId,
          image: upload.data.data.image,
        };
        const addStore: AxiosResponse = yield call(post, 'stores', body, token);
        if (addStore.status === 200) {
          yield put(onGetStores());
          notification['success']({
            message: 'Store added successfully',
            description: '',
          });
        }
      }
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setStoreError({
          storeHasError: true,
          storeErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
      notification['error']({
        message: 'Error adding Store',
        description: `${error.code || ''} ${error.message}`,
      });
    }
  } finally {
    yield put(
      setStoreLoading({
        storeLoading: false,
        storeMessage: '',
      })
    );

    yield put(
      setStoreError({
        storeHasError: false,
        storeErrorMessage: ``,
      })
    );
  }
}

function* editStore({
  payload,
}: PayloadAction<EditFormState>): SagaIterator<void> {
  try {
    yield put(
      setStoreLoading({
        storeLoading: true,
        storeMessage: '',
      })
    );
    const storeid = payload.storeid;
    const token = yield call(getToken);
    let image = payload.file;

    if (Array.isArray(payload.file)) {
      const form = new FormData();
      form.append('id', storeid);
      form.append('file', payload.file[0].originFileObj);

      const upload: AxiosResponse = yield call(post, 'upload', form, token);
      if (upload.status === 200) {
        image = upload.data.data.image;
        delete payload.file;
        const { status }: AxiosResponse = yield call(
          patch,
          `stores/${payload.storeid}`,
          { ...payload, image },
          token
        );

        if (status === 200) {
          yield put(
            setStoreLoading({
              storeLoading: false,
              storeMessage: 'Store updated successfully',
            })
          );
          notification['success']({
            message: 'Store has been updated',
            description: '',
          });
          yield put(onGetStores());
        }
      }
    } else {
      delete payload.file;
      const { status }: AxiosResponse = yield call(
        patch,
        `stores/${payload.storeid}`,
        { ...payload, image },
        token
      );

      if (status === 200) {
        yield put(
          setStoreLoading({
            storeLoading: false,
            storeMessage: 'Store updated successfully',
          })
        );
        notification['success']({
          message: 'Store has been updated',
          description: '',
        });
        yield put(onGetStores());
      }
    }

  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setStoreError({
          storeHasError: true,
          storeErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
      notification['error']({
        message: 'Error updating Store',
        description: `${error.code || ''} ${error.message}`,
      });
    }
  } finally {
    yield put(
      setStoreLoading({
        storeLoading: false,
        storeMessage: '',
      })
    );

    yield put(
      setStoreError({
        storeHasError: false,
        storeErrorMessage: ``,
      })
    );
  }
}

function* setStoreVisibility({
  payload,
}: PayloadAction<StoreVisibility>): SagaIterator<void> {
  try {
    yield put(
      setStoreLoading({
        storeLoading: true,
        storeMessage: '',
      })
    );

    const token = yield call(getToken);
    const { status }: AxiosResponse = yield call(
      patch,
      `stores/${payload.id}`,
      payload,
      token
    );

    if (status === 200) {
      yield put(
        setStoreLoading({
          storeLoading: false,
          storeMessage: 'Store updated successfully',
        })
      );
      notification['success']({
        message: 'Store has been updated',
        description: '',
      });
      yield put(onGetStores());
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setStoreError({
          storeHasError: true,
          storeErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
      notification['error']({
        message: 'Error updating Store',
        description: `${error.code || ''} ${error.message}`,
      });
    }
  } finally {
    yield put(
      setStoreLoading({
        storeLoading: false,
        storeMessage: '',
      })
    );
  }
}

function* deleteStore({
  payload,
}: PayloadAction<{ id: string }>): SagaIterator<void> {
  try {
    const storeState = (state: RootState) => state.category;
    const { storeHasError } = yield select(storeState);
    yield put(
      setStoreLoading({
        storeLoading: true,
        storeMessage: '',
      })
    );

    if (storeHasError) {
      yield put(
        setStoreError({
          storeHasError: false,
          storeErrorMessage: '',
        })
      );
    }

    const token = yield call(getToken);
    const { status }: AxiosResponse = yield call(
      deleted,
      `stores/${payload.id}`,
      undefined,
      token
    );

    if (status === 200) {
      yield put(
        setStoreLoading({
          storeLoading: false,
          storeMessage: 'Store deleted successfully',
        })
      );
      notification['success']({
        message: 'Store has been deleted',
        description: '',
      });
      yield put(onGetStores());
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      notification['error']({
        message: 'Error deleting store',
        description: `${error.code || ''} ${error.message}`,
      });
      yield put(
        setStoreError({
          storeHasError: true,
          storeErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }

    yield put(
      setStoreLoading({
        storeLoading: false,
        storeMessage: '',
      })
    );
  }
}

export function* storeSaga(): SagaIterator<void> {
  yield all([takeLatest(onGetStores, getStores)]);
  yield all([takeLatest(onAddStore, addStore)]);
  yield all([takeLatest(onEditStore, editStore)]);
  yield all([takeLatest(onDeleteStore, deleteStore)]);
  yield all([takeLatest(onUpdateStoreVisibility, setStoreVisibility)]);
}
