import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  Row,
  Space,
  Statistic,
  Table,
} from 'antd';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { RangeValue } from 'rc-picker/lib/interface';
import moment from 'moment';

import { routes } from '../../constants/routes';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { onGetTransactions } from '../../store/transactions/slice';
import { TransactionsProps } from '../../store/transactions/types';
import { TransactionView } from './viewTransactions';
import { colors } from '../../constants/theme';
import ExportButton from '../../globalComponents/ExportButton';

const dateFormat = 'MMMM-YYYY';

const ExportTableHeader = [
  { label: 'ID', key: 'id' },
  { label: 'Amount', key: 'amount' },
  { label: 'Cashback', key: 'cashback.amount' },
  { label: 'Cashback Percentage', key: 'cashback.percentage' },
  // { label: 'Billable Percentage', key: 'cashback.percentage' },
  { label: 'User', key: 'user.name' },
  { label: 'Store', key: 'store.name' },
  { label: 'Date', key: 'cashback.timeadded' },
];

interface FilterProps {
  userSearch: string;
  storeSearch: string;
  dateRange: RangeValue<moment.Moment> | null;
}

const initialState: FilterProps = {
  userSearch: '',
  storeSearch: '',
  dateRange: null,
};

export const TransactionList: React.FC = () => {
  const { transactions, transactionsLoading } = useAppSelector(
    (state) => state.transaction
  );
  const [listTransactions, setTransactionList] = useState(
    transactions?.transactions
  );
  const [filters, setFilters] = useState(initialState);
  const [selectedMonth, setSelectedMonth] = useState(
    moment().format('MMMM-YYYY')
  );
  const history = useHistory();
  const dispatch = useAppDispatch();
  const userRef = useRef();

  const sortByDate = (a: moment.Moment, b: moment.Moment) => {
    return moment(a).unix() - moment(b).unix();
  };

  const handleRowItemClick = (item: TransactionsProps) => ({
    onClick: () => history.push(`${routes.viewTransactions}/${item.id}`),
  });

  const clearFilters = () => {
    setFilters(initialState);
    setTransactionList(transactions?.transactions);
  };

  const isDateBetweenRange = (date: moment.Moment) => {
    if (filters.dateRange === null) {
      return true;
    }
    return moment(date).isBetween(
      filters.dateRange[0],
      filters.dateRange[1],
      'day',
      '[]'
    );
  };

  const handleSearch = () => {
    const results = transactions?.transactions?.filter((list) => {
      return (
        list.user.name
          .toLowerCase()
          .includes(filters.userSearch.toLowerCase()) &&
        list.store.name
          .toLowerCase()
          .includes(filters.storeSearch.toLowerCase()) &&
        isDateBetweenRange(list.timeadded)
      );
    });
    setTransactionList(results);
  };

  const handleFieldChange =
    (field: keyof FilterProps) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilters({ ...filters, [field]: e.target.value });
    };

  const handleDateRangeChange = (
    values: RangeValue<moment.Moment>,
    dateString: [string, string]
  ) => {
    setFilters({ ...filters, dateRange: values });
    handleSearch();
  };

  const handeMonthChange = (date: moment.Moment | null, dateString: string) => {
    const formatDate = date ? moment(date).format('MM-YYYY') : undefined;
    const monthSelected = date ? moment(date) : moment();
    setSelectedMonth(monthSelected.format('MMMM-YYYY'));
    dispatch(onGetTransactions({ date: formatDate }));
  };

  useEffect(() => {
    if (transactions?.transactions) {
      setTransactionList(transactions?.transactions);
    }
  }, [transactions?.transactions]);

  useEffect(() => {
    if (!filters.userSearch || !filters.storeSearch) {
      handleSearch();
    }
  }, [filters]);
  return (
    <Row className="transactions-wrapper">
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <Col span={24}>
          <Card className="transactions-card">
            <Row gutter={[0, 8]}>
              <Col xs={24} sm={24} md={6}>
                <Card className="transaction-cahshback-card">
                  <Statistic
                    title="Monie Cashback"
                    value={transactions?.total}
                    precision={2}
                    prefix="€"
                    valueStyle={{ color: colors.primaryColor }}
                  />
                </Card>
              </Col>
              <Col xs={24} sm={24} md={18} className="table-export-button">
                <ExportButton
                  filename={`Monie Transactions ${selectedMonth}.csv`}
                  data={listTransactions || []}
                  headers={ExportTableHeader}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xl={18} lg={24} md={24}>
                <Row gutter={16} className="transaction-filter">
                  <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                    <Input.Search
                      value={filters.userSearch}
                      placeholder="Search User"
                      onSearch={handleSearch}
                      ref={userRef.current}
                      onChange={handleFieldChange('userSearch')}
                    />
                  </Col>
                  <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                    <Input.Search
                      allowClear
                      value={filters.storeSearch}
                      placeholder="Search Store"
                      onSearch={handleSearch}
                      onChange={handleFieldChange('storeSearch')}
                      style={{ width: '100%' }}
                    />
                  </Col>
                  <Col xl={6} lg={12} md={12} sm={24} xs={24}>
                    <DatePicker.RangePicker
                      value={filters.dateRange}
                      onChange={handleDateRangeChange}
                    />
                  </Col>
                  <Col
                    xl={6}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                    onClick={clearFilters}
                  >
                    <Button className="clear-btn">Clear filters</Button>
                  </Col>
                </Row>
              </Col>
              <Col
                xl={6}
                lg={24}
                md={24}
                xs={24}
                className="transaction-month-selector"
              >
                <DatePicker
                  picker="month"
                  format={dateFormat}
                  style={{ width: 240 }}
                  onChange={handeMonthChange}
                  placeholder="Select transaction month"
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Table
            rowKey={'id'}
            loading={transactionsLoading}
            dataSource={listTransactions}
            pagination={{
              pageSize: 7,
              total: listTransactions ? listTransactions.length : 0,
              hideOnSinglePage: true,
            }}
            scroll={{ x: 'max-content' }}
            onRow={handleRowItemClick}
          >
            <Table.Column
              title="ID"
              dataIndex="id"
              width={'10%'}
              align="left"
              sorter={(a: TransactionsProps, b: TransactionsProps) =>
                a.id.toLowerCase().localeCompare(b.id.toLowerCase())
              }
            />
            <Table.Column
              title="Amount"
              dataIndex="amount"
              align="left"
              sorter={(a: TransactionsProps, b: TransactionsProps) =>
                a.amount - b.amount
              }
              render={(value) => <div>{value}€</div>}
            />
            <Table.Column
              title="Cashback"
              dataIndex={['cashback', 'amount']}
              align="left"
              sorter={(a: TransactionsProps, b: TransactionsProps) =>
                a.cashback.amount - b.cashback.amount
              }
              render={(value) => <div>{value}€</div>}
            />
            <Table.Column
              title="Cashback Percentage"
              dataIndex={['cashback', 'percentage']}
              align="left"
              sorter={(a: TransactionsProps, b: TransactionsProps) =>
                a.cashback.percentage - b.cashback.percentage
              }
              render={(value) => <div>{value}%</div>}
            />

            {/* <Table.Column
              title="Billable Cashback"
              dataIndex={['cashback', 'percentage']}
              align="left"
              sorter={(a: TransactionsProps, b: TransactionsProps) =>
                a.cashback.percentage - b.cashback.percentage
              }
              render={(value) => <div>{value}%</div>}
            /> */}

            <Table.Column
              title="User"
              dataIndex={['user', 'name']}
              align="left"
              sorter={(a: TransactionsProps, b: TransactionsProps) =>
                a.user.name
                  .toLowerCase()
                  .localeCompare(b.user.name.toLowerCase())
              }
            />

            <Table.Column
              title="Store"
              dataIndex={['store', 'name']}
              align="left"
              sorter={(a: TransactionsProps, b: TransactionsProps) =>
                a.store.name
                  .toLowerCase()
                  .localeCompare(b.store.name.toLowerCase())
              }
            />

            <Table.Column
              title="Date"
              dataIndex={'timeadded'}
              align="left"
              render={(value) => (
                <div>{moment(value).format('MM/DD/YYYY')}</div>
              )}
              sorter={(a: TransactionsProps, b: TransactionsProps) =>
                sortByDate(a.timeadded, b.timeadded)
              }
            />
          </Table>
        </Col>
      </Space>
    </Row>
  );
};

export const Transactions: React.FC = () => {
  const { auth } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { url } = useRouteMatch();

  useEffect(() => {
    if (auth) {
      dispatch(onGetTransactions({ date: undefined }));
    }
  }, [dispatch, auth]);
  return (
    <div className="transactions-container">
      <Route exact path={routes.transactions} component={TransactionList} />
      <Route path={`${url}/view/:transactionid`} component={TransactionView} />
    </div>
  );
};
