import React, { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Form,
  PageHeader,
  Row,
  Input,
  Spin,
  Empty,
  Image,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useAppSelector } from '../../../store/hooks';
import { CategoryProps } from '../../../store/category/types';
import FallbackImg from '../../../assets/images/fallback-img.png';
import { FaqsProps } from '../../../store/faqs/types';

interface FaqsParamsProps {
  faqsId: string;
}

const FaqsView: React.FC = () => {
  const { faqs } = useAppSelector((state) => state.faqs);
  const [faq, setFaqs] = useState<FaqsProps | undefined>();
  const [loading, setLoading] = useState(true);
  const params: FaqsParamsProps = useParams();
  const history = useHistory();
  const formRef: any = React.createRef();

  const getFaqs = () => {
    if (params.faqsId) {
      const item = faqs.find((i) => i.id === params.faqsId);
      setFaqs(item);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  useEffect(getFaqs, [faqs, params.faqsId]);

  useEffect(() => {
    if (faq && !loading) {
      formRef.current.setFieldsValue({
        title: faq?.title,
        description: faq?.description,
      });
    }
  }, [faq, formRef, loading]);
  return (
    <Row>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={faq?.title || ` `}
        style={{ padding: 0 }}
      />
      <Col
        span={24}
        style={{
          margin: '24px 0px',
          width: '100%',
        }}
      >
        <Card bordered={false}>
          {loading ? (
            <Col className="category-spin" span={24}>
              <Spin />
            </Col>
          ) : !loading && faq ? (
            <Form
              ref={formRef}
              name="basic"
              layout="vertical"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              initialValues={{
                title: faq?.title,
                description: faq?.description,
              }}
              autoComplete="off"
              size="middle"
            >
              <Form.Item label="Faqs Title" name="title">
                <Input disabled />
              </Form.Item>

              <Form.Item label="Faqs Description" name="description">
                <Input.TextArea disabled style={{ height: '100px' }} />
              </Form.Item>
            </Form>
          ) : (
            <Empty />
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default FaqsView;
