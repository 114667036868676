import { PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from '..';
import { get, patch } from '../../constants/api';
import { getToken } from '../auth/saga';
import { setContactLoading } from '../contact/contactSlice';
import {
  onGetContents,
  onUpdateContent,
  onUpdateHowItWorks,
  setContent,
  setContentError,
  setContentLoading,
} from './contentSlice';
import { ContentProps, HowItWorksProps } from './types';

export function* getContents(): SagaIterator<void> {
  const contentState = (state: RootState) => state.content;
  const { contentHasError } = yield select(contentState);
  try {
    yield put(
      setContentLoading({
        contentLoading: true,
        contentMessage: '',
      })
    );
    const token = yield call(getToken);
    const { data, status }: AxiosResponse = yield call(
      get,
      `homepage`,
      undefined,
      token
    );

    if (status === 200) {
      yield put(setContent({ content: data.data }));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setContentError({
          contentHasError: true,
          contentErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setContentLoading({
        contentLoading: false,
        contentMessage: '',
      })
    );
    if (contentHasError) {
      yield put(
        setContentError({
          contentHasError: false,
          contentErrorMessage: '',
        })
      );
    }
  }
}

export function* updateContent({
  payload,
}: PayloadAction<ContentProps>): SagaIterator<void> {
  const contentState = (state: RootState) => state.content;
  const { contentHasError } = yield select(contentState);
  try {
    yield put(
      setContactLoading({
        contactLoading: true,
        contactMessage: '',
      })
    );
    const token = yield call(getToken);
    const { status }: AxiosResponse = yield call(
      patch,
      `homepage`,
      payload,
      token
    );

    if (status === 200) {
      yield put(onGetContents());
      notification['success']({
        message: 'Contents updated successfully',
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      notification['error']({
        message: `${error.code || ''}`,
      });
      yield put(
        setContentError({
          contentHasError: true,
          contentErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setContactLoading({
        contactLoading: false,
        contactMessage: '',
      })
    );

    if (contentHasError) {
      yield put(
        setContentError({
          contentHasError: false,
          contentErrorMessage: '',
        })
      );
    }
  }
}

export function* updateHowItWorks({
  payload,
}: PayloadAction<HowItWorksProps>): SagaIterator<void> {
  const contentState = (state: RootState) => state.content;
  const { contentHasError } = yield select(contentState);
  try {
    yield put(
      setContactLoading({
        contactLoading: true,
        contactMessage: '',
      })
    );

    const form = new FormData();
    form.append('key', payload.key || '');
    form.append('title', payload.title);
    form.append('description', payload.description);
    form.append('content', payload.content);

    if (Array.isArray(payload.icon)) {
      form.append('icon', payload.icon[0].originFileObj);
    }

    const token = yield call(getToken);
    const { status }: AxiosResponse = yield call(
      patch,
      `how-it-works`,
      form,
      token,
      true
    );

    if (status === 200) {
      yield put(onGetContents());
      notification['success']({
        message: 'Contents updated successfully',
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      notification['error']({
        message: `${error.code || ''}`,
      });
      yield put(
        setContentError({
          contentHasError: true,
          contentErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );
    }
  } finally {
    yield put(
      setContactLoading({
        contactLoading: false,
        contactMessage: '',
      })
    );

    if (contentHasError) {
      yield put(
        setContentError({
          contentHasError: false,
          contentErrorMessage: '',
        })
      );
    }
  }
}

export function* contentSaga(): SagaIterator<void> {
  yield all([takeLatest(onGetContents, getContents)]);
  yield all([takeLatest(onUpdateContent, updateContent)]);
  yield all([takeLatest(onUpdateHowItWorks, updateHowItWorks)]);
}
