import React, { useEffect, useState } from 'react';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Col, Row, Space, Table } from 'antd';
import { PlusOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { FaqsProps } from '../../store/faqs/types';
import { routes } from '../../constants/routes';
import { colors } from '../../constants/theme';
import { AddFaqs } from './components/addFaqs';
import { onGetFaqs } from '../../store/faqs/faqsSlice';
import EditFaqs from './components/edit';
import { DeleteFaqsModal } from './components/deleteModal';
import FaqsView from './components/view';

export const FaqsList: React.FC = () => {
  const { faqs, faqsLoading } = useAppSelector((state) => state.faqs);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedFaqs, setSelectedFaqs] = useState({
    id: '',
    title: '',
  });
  const history = useHistory();

  const handleAddFaqs = () => {
    history.push(routes.addFaqs);
  };

  const handleDeleteButton = (key: any) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDeleteModal(true);
    setSelectedFaqs({ id: key.id, title: key.title });
  };

  const handleEditButton = (key: any) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`${routes.editFaqs}/${key.id}`);
  };

  const handleRowItemClick = (item: FaqsProps) => ({
    onClick: () => history.push(`${routes.faqs}/view/${item.id}`),
  });

  return (
    <Row>
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <Col span={24} style={{ float: 'right' }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddFaqs}
          >
            ADD FAQs
          </Button>
        </Col>
        <Col span={24}>
          <Table
            rowKey={'id'}
            loading={faqsLoading}
            dataSource={faqs}
            pagination={{
              pageSize: 10,
              total: faqs ? faqs.length : 0,
              hideOnSinglePage: true,
            }}
            scroll={{ x: 'max-content' }}
            onRow={handleRowItemClick}
          >
            <Table.Column
              title="ID"
              dataIndex="id"
              width={'20%'}
              align="left"
              sorter={(a: FaqsProps, b: FaqsProps) =>
                a.id.toLowerCase().localeCompare(b.id.toLowerCase())
              }
            />
            <Table.Column
              title="Title"
              dataIndex="title"
              align="left"
              sorter={(a: FaqsProps, b: FaqsProps) =>
                a.title.toLowerCase().localeCompare(b.title.toLowerCase())
              }
              render={(text, record: any) => {
                return <div className="title-style">{record.title}</div>;
              }}
            />
            <Table.Column
              title="Description"
              dataIndex="description"
              align="left"
              sorter={(a: FaqsProps, b: FaqsProps) =>
                a.description
                  .toLowerCase()
                  .localeCompare(b.description.toLowerCase())
              }
              render={(text, record: any) => {
                return <div className="title-style">{record.description}</div>;
              }}
            />
            <Table.Column
              title="Action"
              key="action"
              width={'15%'}
              align="center"
              render={(text, record: any) => {
                return (
                  <div>
                    <Button
                      type="text"
                      style={{ padding: '0px 10px' }}
                      onClick={handleEditButton(record)}
                    >
                      <EditTwoTone twoToneColor={colors.black} />
                    </Button>
                    <Button
                      type="text"
                      style={{ padding: '0px 10px' }}
                      onClick={handleDeleteButton(record)}
                    >
                      <DeleteTwoTone twoToneColor={colors.red} />
                    </Button>
                  </div>
                );
              }}
            />
          </Table>
        </Col>
      </Space>
      <DeleteFaqsModal
        faq={selectedFaqs}
        visible={showDeleteModal}
        setVisible={setShowDeleteModal}
      />
    </Row>
  );
};

export const Faqs: React.FC = () => {
  const { auth } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { url } = useRouteMatch();

  useEffect(() => {
    if (auth) {
      dispatch(onGetFaqs());
    }
  }, [dispatch, auth]);
  return (
    <div className="category-container">
      <Route exact path={routes.faqs} component={FaqsList} />
      <Route exact path={`${url}/add`} component={AddFaqs} />
      <Route exact path={`${url}/edit/:faqsId`} component={EditFaqs} />
      <Route exact path={`${url}/view/:faqsId`} component={FaqsView} />
    </div>
  );
};
