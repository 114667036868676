import { SagaIterator } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { loginState } from './types';
import { MONIEFirebaseSaga } from '../root-saga';
import { AxiosError } from 'axios';
import {
  all,
  takeLatest,
  call,
  put,
  fork,
  take,
  select,
} from 'redux-saga/effects';
import {
  onLogin,
  onLogout,
  setAuth,
  setLoginError,
  setLoginLoading,
} from './authSlice';
import { MonieFirebase } from '../../constants/firebase';
import { AuthError, AuthErrorCodes } from 'firebase/auth';
import { RootState } from '..';
import { notification } from 'antd';
import { get } from '../../constants/api';

function getErrorCodeMessage(code: string) {
  let message = '';
  switch (code) {
    case AuthErrorCodes.INVALID_EMAIL:
      message = 'Invalid Email';
      break;
    case 'auth/user-not-found':
      message = 'User not found';
      break;
    case 'auth/wrong-password':
      message = 'User not found';
      break;
    case '401':
      message = 'You are not authorized';
      break;
    default:
      message = 'Something went wrong';
  }
  return message;
}

export function* getToken() {
  const token: string | undefined = yield MonieFirebase.auth()
    .currentUser?.getIdToken()
    .then((token) => token);
  return token;
}

function* login({ payload }: PayloadAction<loginState>): SagaIterator<void> {
  const authState = (state: RootState) => state.auth;
  const { loginHasError } = yield select(authState);

  try {
    yield put(
      setLoginLoading({
        loginLoading: true,
        loginMessage: '',
      })
    );

    yield call(
      MONIEFirebaseSaga.auth.signInWithEmailAndPassword,
      payload.email,
      payload.password
    );
  } catch (e: any) {
    const error: AuthError = e;
    if (error) {
      yield put(
        setLoginError({
          loginHasError: true,
          loginErrorMessage: getErrorCodeMessage(error.code),
        })
      );
      yield put(
        setLoginLoading({
          loginLoading: false,
          loginMessage: '',
        })
      );
      yield call(MONIEFirebaseSaga.auth.signOut);
      yield put(setAuth({ idToken: null, auth: null, user: null }));
    }
  }
}

function* onCheckAuthenticatedUser(): SagaIterator<void> {
  const channel = yield call(MONIEFirebaseSaga.auth.channel);
  while (true) {
    const { user } = yield take(channel);
    if (user) {
      try {
        const token = yield user
          .getIdToken()
          .then(async (token: any) => await token);
        const admin = yield call(get, 'auth', undefined, token);
        yield put(
          setAuth({ idToken: token, auth: user, user: admin.data.data })
        );
        yield put(
          setLoginLoading({
            loginLoading: false,
            loginMessage: '',
          })
        );
      } catch (e: any) {
        const error: AxiosError = e;
        const authError: AuthError = e;
        if (error.response?.status === 401) {
          notification['error']({
            message: 'Admin Access',
            description: 'You are not authorized',
          });
        } else {
          yield put(
            setLoginError({
              loginHasError: true,
              loginErrorMessage: getErrorCodeMessage(authError.code),
            })
          );
        }
        yield put(
          setLoginLoading({
            loginLoading: false,
            loginMessage: '',
          })
        );
        yield call(MONIEFirebaseSaga.auth.signOut);
        yield put(setAuth({ idToken: null, auth: null, user: null }));
      } finally {
        yield put(
          setLoginError({
            loginHasError: false,
            loginErrorMessage: '',
          })
        );
      }
    } else {
      yield call(MONIEFirebaseSaga.auth.signOut);
      yield put(setAuth({ idToken: null, auth: null, user: null }));
    }
  }
}

function* authLogout({ history }: any): SagaIterator<void> {
  try {
    const payload = { idToken: null, auth: null, user: null };
    yield call(MONIEFirebaseSaga.auth.signOut);
    yield put(setAuth(payload));

    history.push('/login');

    notification['info']({
      message: 'You have been logged out',
      description: '',
    });
  } catch (error) {}
}

export function* authSaga(): SagaIterator<void> {
  yield all([
    fork(onCheckAuthenticatedUser),
    takeLatest(onLogin, login),
    takeLatest(onLogout, authLogout),
  ]);
}
