import React, { useEffect, useState } from 'react';
import {
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  PageHeader,
  Row,
  Spin,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { useAppSelector } from '../../../store/hooks';
import { AdminProps } from '../../../store/user/types';

interface UserParamsProps {
  id: string;
}

export const AdminView: React.FC = () => {
  const { admins } = useAppSelector((state) => state.user);
  const [admin, setAdmin] = useState<AdminProps | undefined>();
  const [loading, setLoading] = useState(true);
  const params: UserParamsProps = useParams();

  const history = useHistory();

  useEffect(() => {
    if (params.id && admins?.length) {
      const item = admins?.find((i) => i.id === params.id);

      if (item === undefined) {
        setLoading(false);
        return;
      }

      setAdmin({ ...item, dob: moment(item.dob) });
    }
  }, [admins, params.id]);

  useEffect(() => {
    if (admin && loading) {
      setLoading(false);
    }
  }, [admin, loading]);
  return (
    <Row className="store-wrapper">
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={admin?.name || ''}
        style={{ padding: 0 }}
      />
      <Col
        span={24}
        style={{
          margin: '24px 0px',
          width: '100%',
        }}
      >
        <Card bordered={false}>
          {loading ? (
            <Col className="category-spin" span={24}>
              <Spin />
            </Col>
          ) : !loading && admins && admin ? (
            <Form
              name="basic"
              layout="vertical"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              initialValues={admin}
              autoComplete="off"
              size="middle"
            >
              <Form.Item label="Email" name="email">
                <Input disabled />
              </Form.Item>

              <Form.Item label="Name" name="name">
                <Input disabled />
              </Form.Item>

              <Form.Item label="Date of Birth" name="dob">
                <DatePicker disabled />
              </Form.Item>

              <Form.Item label="Address" name="address">
                <Input disabled />
              </Form.Item>

              <Form.Item label="Nationality" name="nationality">
                <Input disabled />
              </Form.Item>
            </Form>
          ) : (
            <Empty />
          )}
        </Card>
      </Col>
    </Row>
  );
};
