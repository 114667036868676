import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Modal, Button, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { onDeleteUser, setUsersError } from '../../../store/user/userSlice';

interface ModalProps {
  user: {
    name: string;
    id: string;
  };
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  isAdmin?: boolean;
}

export const DeleteUserModal: React.FC<ModalProps> = ({
  user,
  visible,
  setVisible,
  isAdmin = false,
}) => {
  const { usersLoading, usersHasError } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const deletePressed = useRef(false);

  const handleOk = () => {};

  const handleCancel = () => {
    setVisible(false);
  };

  const handleUserDeletion = () => {
    dispatch(onDeleteUser({ id: user.id, isAdmin }));
    deletePressed.current = true;
  };

  useEffect(() => {
    if (usersHasError && !usersLoading && deletePressed.current) {
      dispatch(
        setUsersError({
          usersHasError: false,
          usersErrorMessage: '',
        })
      );
      deletePressed.current = false;
    } else if (!usersLoading && deletePressed.current) {
      deletePressed.current = false;
      setVisible(false);
    }
  }, [usersHasError, usersLoading, deletePressed, setVisible]);
  return (
    <Modal
      visible={visible}
      centered
      title={`Delete ${isAdmin ? 'Admin' : 'User'}`}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          loading={usersLoading}
          danger
          onClick={handleUserDeletion}
        >
          {usersLoading ? 'Deleting...' : 'Delete'}
        </Button>,
      ]}
    >
      {`Are you sure you want to delete ${isAdmin ? 'admin' : 'user'}`}
      <Typography.Text strong>{` ${user.name}?`}</Typography.Text>
    </Modal>
  );
};
