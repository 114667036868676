import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Modal, Button, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { onDeleteCategory } from '../../../store/category/categorySlice';
import { onDeleteStore, setStoreError } from '../../../store/store/storeSlice';

interface ModalProps {
  store: {
    name: string;
    id: string;
  };
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

export const DeleteStoreModal: React.FC<ModalProps> = ({
  store,
  visible,
  setVisible,
}) => {
  const { storeHasError, storeLoading } =
    useAppSelector((state) => state.store);
  const dispatch = useAppDispatch();
  const deletePressed = useRef(false);

  const handleOk = () => { };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleCategoryDeletion = () => {
    dispatch(onDeleteStore({ id: store.id }));
    deletePressed.current = true;
  };

  useEffect(() => {
    if (storeHasError && !storeLoading && deletePressed.current) {
      dispatch(setStoreError({
        storeHasError: false,
        storeErrorMessage: '',
      }));
      deletePressed.current = false;
    } else if (!storeLoading && deletePressed.current) {
      deletePressed.current = false;
      setVisible(false);
    }
  }, [storeHasError, storeLoading, deletePressed, setVisible]);
  return (
    <Modal
      visible={visible}
      centered
      title="Delete Store"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          loading={storeLoading}
          danger
          onClick={handleCategoryDeletion}
        >
          {storeLoading ? 'Deleting...' : 'Delete'}
        </Button>,
      ]}
    >
      Are you sure you want to delete
      <Typography.Text strong>{` ${store.name} `}</Typography.Text>
      store ?
    </Modal>
  );
};
