import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorState, LoadingState, SeoProps, SeoState } from './types';

const initialState = {
  seo: [],
  seoLoading: false,
  seoMessage: '',
  seoHasError: false,
  seoErrorMessage: '',
} as SeoState;

export const seoSlice = createSlice({
  name: 'seo',
  initialState,
  reducers: {
    onGetSeo: () => {},
    onUpdateSeoContent: (state, action: PayloadAction<SeoProps>) => {},
    setSeo: (state, action: PayloadAction<SeoState>) => {
      state.seo = action.payload.seo;
    },
    setSeoLoading: (state, action: PayloadAction<LoadingState>) => {
      state.seoLoading = action.payload.seoLoading;
      state.seoMessage = action.payload.seoMessage;
    },
    setSeoError: (state, action: PayloadAction<ErrorState>) => {
      state.seoHasError = action.payload.seoHasError;
      state.seoErrorMessage = action.payload.seoErrorMessage;
    },
  },
});

export const {
  onGetSeo,
  setSeo,
  setSeoLoading,
  setSeoError,
  onUpdateSeoContent,
} = seoSlice.actions;

export default seoSlice.reducer;
