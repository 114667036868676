import React, { useEffect, useState } from 'react';
import { Card, Col, Form, PageHeader, Row, Input, Spin, Empty, Image } from 'antd';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';

import { useAppSelector } from '../../../store/hooks';
import { CategoryProps } from '../../../store/category/types';
import FallbackImg from '../../../assets/images/fallback-img.png'

interface CategoryParamsProps {
  categoryId: string
}

const CategoryView: React.FC = () => {
  const { categories } = useAppSelector(state => state.category);
  const [category, setCategory] = useState<CategoryProps |undefined>();
  const [loading, setLoading] = useState(true);
  const params: CategoryParamsProps = useParams();
  const history = useHistory();
  const formRef: any = React.createRef();

  const getCategory = () => {
    if (params.categoryId) {
      const item = categories.find(i => i.id === params.categoryId)
      setCategory(item)
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  useEffect(getCategory, [categories, params.categoryId])

  useEffect(() => {
    if(category && !loading) {
      formRef.current.setFieldsValue({
        categoryName: category?.name,
        range: category?.range,
        file: category?.image
      })
    }
  }, [category, formRef, loading]);
  return  (
    <Row>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={category?.name || ` `}
        style={{ padding: 0 }}
      />
      <Col span={24} style={{
        margin: '24px 0px',
        width: '100%'
      }}>
        <Card bordered={false}>
          {loading ? (
            <Col className='category-spin' span={24}>
            <Spin />
          </Col>) : 
          !loading && category ? (
            <Form
              ref={formRef}
              name="basic"
              layout="vertical"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              initialValues={{
                categoryName: category?.name,
                range: category?.range,
                file: category?.image
              }}
              autoComplete="off"
              size='middle'
            >
              <Form.Item
                label="Category Name"
                name="categoryName"
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                label="Category Range"
                name="range"
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                name="file"
                label="Icon"
              >
                  <div style={{ padding: 10 }}>
                    <Image
                      width={70}
                      height={70}
                      src={category.image.link}
                      fallback={FallbackImg}
                    />
                  </div>
              </Form.Item>
            </Form>
          ) : <Empty />
          }
        </Card>
      </Col>
    </Row>
  )
}

export default CategoryView;