import { PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from '..';
import { get, post, deleted, patch } from '../../constants/api';
import { getToken } from '../auth/saga';
import {
  onAddCategories,
  onDeleteCategory,
  onEditCategory,
  onGetCategories,
  setCategory,
  setCategoryError,
  setCategoryLoading,
} from './categorySlice';
import { categoryState, deleteProps, editCategoryState } from './types';

function* getCategories(): SagaIterator<void> {
  try {
    yield put(
      setCategoryLoading({
        categoryLoading: true,
        categoryMessage: '',
      })
    );

    const { data, status }: AxiosResponse = yield call(
      get,
      `categories`,
      false
    );

    if (status === 200) {
      yield put(setCategory({ categories: data.data }));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(
        setCategoryError({
          categoryHasError: true,
          categoryErrorMessage: error.message,
        })
      );
    }
  } finally {
    yield put(
      setCategoryLoading({
        categoryLoading: false,
        categoryMessage: '',
      })
    );
    // yield put(setCategoryError({
    //   categoryHasError: false,
    //   categoryErrorMessage: '',
    // }))
  }
}

function* addCategories({
  payload,
}: PayloadAction<categoryState>): SagaIterator<void> {
  try {
    yield put(
      setCategoryLoading({
        categoryLoading: true,
        categoryMessage: '',
      })
    );

    const form = new FormData();
    form.append('name', payload.name);
    form.append('range', payload.range);
    form.append('image', payload.file[0].originFileObj);

    const token = yield call(getToken);
    const { status }: AxiosResponse = yield call(
      post,
      `categories`,
      form,
      token,
      true
    );

    if (status === 200) {
      yield put(onGetCategories());
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setCategoryError({
          categoryHasError: true,
          categoryErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );

      yield put(
        setCategoryLoading({
          categoryLoading: false,
          categoryMessage: '',
        })
      );
    }
  } finally {
    yield put(
      setCategoryLoading({
        categoryLoading: false,
        categoryMessage: '',
      })
    );
  }
}

function* editCategory({
  payload,
}: PayloadAction<editCategoryState>): SagaIterator<void> {
  try {
    yield put(
      setCategoryLoading({
        categoryLoading: true,
        categoryMessage: '',
      })
    );

    const token = yield call(getToken);
    const { status }: AxiosResponse = yield call(
      patch,
      `categories/${payload.id}`,
      payload.form,
      token,
      true
    );

    if (status === 200) {
      yield put(onGetCategories());
      notification['success']({
        message: 'Category updated successfully',
        description: '',
      });
    }
  } catch (e: any) {
    const error: AxiosError = e;
    yield put(
      setCategoryError({
        categoryHasError: true,
        categoryErrorMessage: `${error.code || ''} ${error.message}`,
      })
    );
    notification['error']({
      message: 'Error updating Category',
      description: `${error.code || ''} ${error.message}`,
    });
  } finally {
    yield put(
      setCategoryLoading({
        categoryLoading: false,
        categoryMessage: '',
      })
    );
    yield put(
      setCategoryError({
        categoryHasError: false,
        categoryErrorMessage: '',
      })
    );
  }
}

function* deleteCategories({
  payload,
}: PayloadAction<deleteProps>): SagaIterator<void> {
  try {
    const categoryState = (state: RootState) => state.category;
    const { categoryHasError } = yield select(categoryState);

    yield put(
      setCategoryLoading({
        categoryLoading: true,
        categoryMessage: '',
      })
    );

    if (categoryHasError) {
      yield put(
        setCategoryError({
          categoryHasError: false,
          categoryErrorMessage: '',
        })
      );
    }

    const token = yield call(getToken);
    const { status }: AxiosResponse = yield call(
      deleted,
      `categories/${payload.id}`,
      undefined,
      token
    );

    if (status === 200) {
      yield put(
        setCategoryLoading({
          categoryLoading: false,
          categoryMessage: 'Category deleted successfully',
        })
      );
      yield put(onGetCategories());
    }
  } catch (e: any) {
    const error: AxiosError = e;
    if (error) {
      yield put(
        setCategoryError({
          categoryHasError: true,
          categoryErrorMessage: `${error.code || ''} ${error.message}`,
        })
      );

      yield put(
        setCategoryLoading({
          categoryLoading: false,
          categoryMessage: '',
        })
      );
    }
  }
}

export function* categorySaga(): SagaIterator<void> {
  yield all([
    takeLatest(onGetCategories, getCategories),
    takeLatest(onAddCategories, addCategories),
    takeLatest(onEditCategory, editCategory),
    takeLatest(onDeleteCategory, deleteCategories),
  ]);
}
