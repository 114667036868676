import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  PageHeader,
  Row,
  Upload,
  notification,
} from 'antd';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { routes } from '../../../constants/routes';
import { TeamState } from '../../../store/ourstory/types';
import {
  onAddStoryTeam,
  onGetStory,
  onUpdateStory,
} from '../../../store/ourstory/storySlice';

interface TeamProps {
  name: string;
  description: string;
  quote: string;
  image: any;
  overlay: any;
}

export const AddTeam: React.FC = () => {
  const { ourstory, storyLoading, storyHasError, storyErrorMessage } =
    useAppSelector((state) => state.story);
  const { auth } = useAppSelector((state) => state.auth);
  const [team, setTeam] = useState<Array<TeamProps>>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const formRef = React.createRef<FormInstance>();
  const history = useHistory();
  const submitPressed = useRef(false);

  const onFinish = (values: TeamProps) => {
    submitPressed.current = true;
    const bodyTeam = {
      ...values,
    };
    dispatch(onAddStoryTeam(bodyTeam));
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };

  const getTeam = () => {
    const team: Array<TeamProps> = [];
    ourstory?.team.map((val: any) => {
      team.push(val);
    });
    setTeam(team);
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(getTeam, [ourstory]);

  useEffect(() => {
    if (auth) {
      dispatch(onGetStory());
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [dispatch, auth]);
  useEffect(() => {
    if (storyHasError && !storyLoading && submitPressed.current) {
      formRef.current && formRef.current?.resetFields();
      notification['error']({
        message: 'Error adding Team',
        description: `${storyErrorMessage}`,
      });
      submitPressed.current = false;
    } else if (!storyLoading && submitPressed.current) {
      formRef.current && formRef.current.resetFields();
      submitPressed.current = false;
    }
  }, [storyHasError, storyLoading, formRef, submitPressed]);
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.replace(routes.ourStory)}
        title="Add Team"
        style={{ padding: 0 }}
      />
      <Row>
        <Col
          span={24}
          style={{
            margin: '24px 0px',
            width: '100%',
          }}
        >
          <Card bordered={false}>
            <Form
              ref={formRef}
              name="basic"
              labelCol={{ flex: '110px' }}
              labelAlign="left"
              labelWrap
              wrapperCol={{ span: 12 }}
              autoComplete="off"
              size="middle"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input a Name' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                rules={[
                  { required: true, message: 'Please input description' },
                ]}
              >
                <Input.TextArea rows={2} />
              </Form.Item>
              
              <Form.Item
                label="Quote"
                name="quote"
                rules={[
                  { required: true, message: 'Please input description' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="image"
                label="Image"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: true, message: 'Please upload an image' }]}
              >
                <Upload name="logo" listType="picture" maxCount={1}>
                  <Button>Upload Image</Button>
                </Upload>
              </Form.Item>

              <Form.Item
                name="overlay"
                label="Overlay"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload name="logo" listType="picture" maxCount={1}>
                  <Button>Upload Overlay Image</Button>
                </Upload>
              </Form.Item>

              <Form.Item wrapperCol={{ span: 6 }}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: 110 }}
                  loading={storyLoading && submitPressed.current}
                >
                  Add
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
